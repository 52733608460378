import React, { ReactNode, useCallback, useState } from 'react';
import { InputAttributes, NumericFormatProps } from 'react-number-format';
import { TRANSLATIONS } from '../../constants';
import { useAppDispatch, useTranslate } from '../../hooks/common';
import { IconType } from '../common/Icon';
import Flex from '../common/Flex';
import IconButton from '../common/IconButton';
import Line from '../common/Line';
import Link from '../common/Link';
import Modal from '../common/Modal';
import Text from '../common/Text';
import TextInput from '../common/TextInput';
import SinaSaveImage, { imageNames } from '../common/SinaSaveImage';
import { setMepsWarningModalOpen } from '../../store';
import { useCurrency } from '../../hooks';

export interface PriceDataGroupProps {
    customerPrice: string;
    description: string;
    discount: string;
    expenditureForRepairs: string;
    imageId: imageNames;
    listPrice?: string;
    title: string;
    dark?: boolean;
    modalInfoData?: ReactNode;
    warning?: boolean;
    onCustomerPriceChange?: (value: string, name: string) => void;
    onDiscountChange?: (value: string, name: string) => void;
    onExpenditureForRepairsChange?: (value: string) => void;
    onTechnicalDataClick: () => void;
}

const PriceDataGroup = ({ customerPrice, description, discount, expenditureForRepairs, imageId, listPrice, title, dark, modalInfoData, warning, onDiscountChange, onCustomerPriceChange, onExpenditureForRepairsChange, onTechnicalDataClick }: PriceDataGroupProps) => {
    const [infoModalOpen, setInfoModalOpen] = useState(false);
    const handleCloseModalClick = useCallback(() => setInfoModalOpen(false), []);
    const handleOpenModalClick = useCallback(() => setInfoModalOpen(true), []);
    const { translate } = useTranslate();
    const dispatch = useAppDispatch();
    const handleWarningClick = useCallback(() => dispatch(setMepsWarningModalOpen(true)), []);
    const { currencyFormat } = useCurrency();

    const numberFormatOptions: NumericFormatProps<InputAttributes> = {
        thousandSeparator: ',',
        allowNegative: false,
        decimalSeparator: '.',
        decimalScale: 2,
        allowLeadingZeros: false
    };

    return (
        <Flex alignment='start' style={{ paddingTop: 40, height: 300 }}>
            <SinaSaveImage imageName={imageId} dark={dark} style={{ maxWidth: 100, height: 'unset' }} />
            <div style={{ width: '100%', minWidth: 0 }}>
                <div>
                    <Flex justification='space-between'>
                        <Text type={'subtitle-very-small'} dark={dark}>
                            {title}
                        </Text>
                        <Flex gap={8}>
                            {warning && <IconButton statusIconType={'warning'} size={16} layer={2} dark={dark} onClick={handleWarningClick} />}
                            {modalInfoData && <IconButton dark={dark} iconType={IconType.Information} size={16} layer={2} onClick={handleOpenModalClick} />}
                        </Flex>
                    </Flex>
                    <Text type={'subtitle-very-small-light'} dark={dark} style={{ paddingTop: 4, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {description}
                    </Text>
                </div>
                {listPrice ? <div>
                    <div style={{ paddingTop: 16 }}>
                        <TextInput
                            dark={dark}
                            label={translate(TRANSLATIONS.generated['Commercial.ListPrice'])}
                            name={'listPrice'}
                            numberFormatOptions={currencyFormat}
                            readOnly
                            value={listPrice}
                            withFullBorder
                        />
                    </div>
                    <div style={{ paddingTop: 16 }}>
                        <TextInput
                            dark={dark}
                            label={translate(TRANSLATIONS.generated['Commercial.Discount'])}
                            name={'discount'}
                            numberFormatOptions={{ ...numberFormatOptions, suffix: '%' }}
                            onChange={onDiscountChange}
                            value={discount}
                        />
                    </div>
                    <Line dark={dark} style={{ marginTop: 16, marginBottom: 16 }} />
                    <div>
                        <TextInput
                            dark={dark}
                            label={translate(TRANSLATIONS.generated['Commercial.CustomerPrice'])}
                            name={'customerPrice'}
                            numberFormatOptions={currencyFormat}
                            onChange={onCustomerPriceChange}
                            value={customerPrice}
                        />
                    </div>
                    <div>
                        <Link onClick={onTechnicalDataClick} icon={IconType.ArrowUp} iconPosition='left' text={translate(TRANSLATIONS.generated['Motor.GetTechnicalData'])} dark={dark} iconRotation45 style={{ paddingTop: 24 }} />
                    </div>
                </div>
                    : <div>
                        <TextInput
                            dark={dark}
                            label={translate(TRANSLATIONS.generated['System.Price'])}
                            name={'expenditureForRepairs'}
                            numberFormatOptions={currencyFormat}
                            onChange={onExpenditureForRepairsChange}
                            value={expenditureForRepairs.toString()}
                        />
                    </div>}
            </div>
            {modalInfoData && (
                <Modal open={infoModalOpen} allowClickAway movable onClose={handleCloseModalClick} dark={dark} key={'info-modal'}>
                    {modalInfoData}
                </Modal>
            )}
        </Flex>
    );
};

export default PriceDataGroup;
