import React, { CSSProperties, ReactElement, ReactNode, useCallback, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/common';
import { DataType, InputType } from '../../enums';
import { ImageDropdownItem, InputItemList, NumberInputItem, SelectInputItem, TextInputItem } from '../../models';
import IconButton from '../common/IconButton';
import { IconType } from '../common/Icon';
import Modal from '../common/Modal';
import SelectInputElement from './InputElements/SelectInputElement';
import NumberInputElement from './InputElements/NumberInputElement';
import TextInputElement from './InputElements/TextInputElement';
import ImageDropdownElement from './InputElements/ImageDropdownElement';
import Flex from '../common/Flex';
import { setMepsWarningModalOpen } from '../../store';

interface SystemElementProps {
    inputs: InputItemList;
    title: string;
    warning?: ReactNode;
    info?: ReactNode;
    description?: string;
    hideLines?: boolean;
    link?: ReactElement;
    style?: CSSProperties;
    needDoubleLine?: boolean;
    getAvailableSpace: () => number;
    handleSystemInputChange: (inputId: string, value: string, dataType?: DataType) => void;
}

const SystemElement = ({ hideLines, description, inputs, info, title, warning, getAvailableSpace, link, style, needDoubleLine, handleSystemInputChange }: SystemElementProps) => {
    const dark = useAppSelector(state => state.layout.darkMode);
    const [infoModalOpen, setInfoModalOpen] = useState(false);
    const handleOnClick = useCallback(() => setInfoModalOpen(true), []);
    const handleOnClose = useCallback(() => setInfoModalOpen(false), []);
    const dispatch = useAppDispatch();
    const handleWarningClick = useCallback(() => dispatch(setMepsWarningModalOpen(true)), []);

    const renderInputs = (inputs: InputItemList) => {
        return inputs?.map((x, i) => {
            switch (x.inputType) {
                case InputType.Select:
                    return <SelectInputElement key={i} dark={dark} handleSystemInputChange={handleSystemInputChange} input={x as SelectInputItem} />;
                case InputType.Number:
                    return <NumberInputElement key={i} dark={dark} handleSystemInputChange={handleSystemInputChange} input={x as NumberInputItem} />;
                case InputType.Text:
                    return <TextInputElement key={i} dark={dark} handleSystemInputChange={handleSystemInputChange} input={x as TextInputItem} />;
                default:
                    break;
            }
        });
    };

    const imageDropdownItem = useMemo(() => inputs.find(x => x.inputType == InputType.ImageDropdown) as ImageDropdownItem, [inputs]);

    return (
        <div className={`system-element ${dark ? 'dark' : ''}`}>
            <div className='system-element-image-dropdown'>
                <ImageDropdownElement dark={dark} getAvailableSpace={getAvailableSpace} handleSystemInputChange={handleSystemInputChange} input={imageDropdownItem} /> 
            </div>
            <div className='system-element-info'>
                <div className='system-element-title'>
                    {title}
                    <Flex gap={8}>
                        {warning && <IconButton statusIconType='warning' size={16} layer={2} dark={dark} onClick={handleWarningClick} />}
                        {info && <IconButton dark={dark} iconType={IconType.Information} size={16} layer={2} onClick={handleOnClick} />}
                    </Flex>
                </div>
                <div className='system-element-description'>
                    {description}
                </div>
            </div>
            {(!hideLines && !needDoubleLine) && <div className='system-element-line'></div>}
            {(!hideLines && needDoubleLine) && <div className='system-element-double-line'><div className='right'></div></div>}
            <div className='system-element-inputs' style={style}>{renderInputs(inputs)}{link}</div>
            {info && <Modal open={infoModalOpen} allowClickAway movable onClose={handleOnClose} dark={dark} >{info}</Modal>}
        </div>
    );
};

export default SystemElement;
