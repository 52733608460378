import React, { useCallback, useMemo } from 'react';
import { numericFormatter } from 'react-number-format';
import { useCurrency, useHook } from '../../../hooks';
import { useAppSelector, useTranslate } from '../../../hooks/common';
import { EnergyPerformanceChartData } from '../../../models';
import EnergyPerformanceChart from '../Charts/EnergyPerformanceChart';
import Checkbox from '../../common/Checkbox';
import TextInput from '../../common/TextInput';
import ComparisonCardTab, { AdditionalAttributeBlock } from './ComparisonCardTab';
import { ApplicationType } from '../../../types';
import { TRANSLATIONS } from '../../../constants';
import ComparisonCardMonthlyCostsInfo from './ModalInfo/ComparisonCardMonthlyCostsInfo';

export interface EnergyPerformanceTabProps {
    alternativeSystemInvestmentCosts: number;
    applicationType: ApplicationType;
}

const EnergyPerformanceTab = ({ alternativeSystemInvestmentCosts, applicationType }: EnergyPerformanceTabProps) => {
    const dark = useAppSelector(x => x.layout.darkMode);
    const { translate } = useTranslate();
    const isPumpSystem = applicationType === 'pump';
    const { energyPerformanceChartData, energyPerformanceChartTabData } = useAppSelector(state => isPumpSystem ? state.pumpSystemChart : state.fanSystemChart);
    const alternativeInvestmentCost = useAppSelector(state => isPumpSystem
        ? state.pumpSystemChart.lifetimeCostsChartTabData?.alternativeInvestmentCost
        : state.fanSystemChart.lifetimeCostsChartTabData?.alternativeInvestmentCost
    );
    const {
        alternativeRunningCostsAfterInnovation,
        handleChangeContractPeriod,
        handleChangeFinancialInterest,
        handleHasContract,
        handleShowDifference,
        chartOptionParameters,
        runningCostsAfterContractTime
    } = useHook(x => x.energyPerformance({ applicationType }));

    const { numberFormatOptions } = useAppSelector(x => x.regionSettings);
    const { currencySymbol } = useCurrency();
    const formatValues = useCallback((value: string) => numericFormatter(value, numberFormatOptions), [numberFormatOptions]);
    const pricePerMonth = `${currencySymbol}/${translate(TRANSLATIONS.generated['System.Units.Month'])}`;

    const totalInterest = useMemo(() =>
        energyPerformanceChartTabData && formatValues((energyPerformanceChartTabData.financingCosts + (alternativeSystemInvestmentCosts ?? 0)).toString()),
        [alternativeSystemInvestmentCosts, energyPerformanceChartTabData, formatValues]);

    const additionalAttributes = useMemo((): AdditionalAttributeBlock[] | undefined => chartOptionParameters.hasContract
        ? [
            {
                attributeBlock: [
                    {
                        label: translate(TRANSLATIONS.generated['System.EnergyCostsAlternativeSystem']),
                        value: formatValues((energyPerformanceChartData?.chartData[energyPerformanceChartData.chartData.length - 1].alternativeEnergyCosts ?? 0).toString()) ?? '',
                        unit: pricePerMonth
                    }],
                attributeBlockLabel: `${translate(TRANSLATIONS.generated['Main.AfterContractTime'])}:`,
                attributeBlockValue: formatValues(runningCostsAfterContractTime),
                attributeBlockUnit: pricePerMonth,
                color: 'alternative'
            },
            {
                attributeBlock: [
                    {
                        label: translate(TRANSLATIONS.generated['Main.InvestmentSum']),
                        value: formatValues(`${(alternativeInvestmentCost ?? 0).toString()}`) ?? '',
                        unit: currencySymbol
                    },
                    {
                        label: translate(TRANSLATIONS.generated['System.FinancingCosts']),
                        value: formatValues(`${energyPerformanceChartTabData?.financingCosts.toString() ?? 0}`) ?? '',
                        unit: currencySymbol
                    }],
                attributeBlockLabel: translate(TRANSLATIONS.generated['System.TotalCostsWithinXYearsYInterest'])
                    .replace('{{years}}', `${chartOptionParameters.contractPeriodString} ${chartOptionParameters.contractPeriod > 1 ? translate(TRANSLATIONS.generated['System.Years']) : translate(TRANSLATIONS.generated['System.Year'])}`)
                    .replace('{{interest}}', chartOptionParameters.financingInterestString)
                    .concat(`: ${totalInterest}${currencySymbol}`)
            }
        ]
        : undefined,
        [
            chartOptionParameters.hasContract,
            chartOptionParameters.contractPeriodString,
            chartOptionParameters.contractPeriod,
            chartOptionParameters.financingInterestString,
            energyPerformanceChartData?.chartData,
            runningCostsAfterContractTime,
            alternativeInvestmentCost,
            currencySymbol,
            pricePerMonth,
            energyPerformanceChartTabData?.financingCosts,
            totalInterest,
            formatValues,
            translate
        ]
    );

    const alternativeSystemAttributes = useCallback((chartData: EnergyPerformanceChartData) => {

        const attributes = chartOptionParameters.hasContract
            ? [
                {
                    label: translate(TRANSLATIONS.generated['Common.ContractRate']),
                    key: 'contractRate',
                    value: formatValues((energyPerformanceChartTabData?.contractRate ?? 0).toString()) ?? '',
                    unit: pricePerMonth
                },
                {
                    label: translate(TRANSLATIONS.generated['System.EnergyCostsAlternativeSystem']),
                    key: 'energyCostsAlternativeSystem',
                    value: formatValues((chartData.chartData[chartData.chartData.length - 1].alternativeEnergyCosts ?? 0).toString()) ?? '',
                    unit: pricePerMonth
                }
            ]
            : [
                {
                    label: translate(TRANSLATIONS.generated['System.EnergyCostsAlternativeSystem']),
                    key: 'energyCostsAlternativeSystemWithoutContract',
                    value: formatValues(chartData.chartData[chartData.chartData.length - 1].runningCosts.toString()) ?? '',
                    unit: pricePerMonth
                }
            ];

        return attributes;
    }, [chartOptionParameters.hasContract, translate, formatValues, energyPerformanceChartTabData?.contractRate, pricePerMonth]);

    return energyPerformanceChartData
        ? <ComparisonCardTab
            applicationType={applicationType}
            chart={<EnergyPerformanceChart data={energyPerformanceChartData} showDifference={chartOptionParameters.showDifference} contract={chartOptionParameters.hasContract} />}
            checkboxes={[
                <Checkbox key='difference' label={translate(TRANSLATIONS.generated['System.Difference'])} dark={dark} onChange={handleShowDifference}
                    checked={chartOptionParameters.showDifference} />,
                <Checkbox key='contract' label={translate(TRANSLATIONS.generated['System.Contracting'])} dark={dark} onChange={handleHasContract}
                    checked={chartOptionParameters.hasContract} />
            ]}
            inputs={[
                <TextInput
                    key='contractPeriod'
                    label={translate(TRANSLATIONS.generated['System.ContractPeriod'])}
                    name='contractPeriod'
                    value={chartOptionParameters.contractPeriodString}
                    onChange={x => handleChangeContractPeriod(x)}
                    dark={dark}
                    disabled={!chartOptionParameters.hasContract}
                    numberFormatOptions={{
                        suffix: chartOptionParameters.contractPeriodString === '1'
                            ? ` ${translate(TRANSLATIONS.generated['System.Year'])}`
                            : ` ${translate(TRANSLATIONS.generated['System.Years'])}`
                    }}
                />,
                <TextInput
                    key='financingInterest'
                    label={translate(TRANSLATIONS.generated['System.Interest'])}
                    name='financingInterest'
                    value={chartOptionParameters.financingInterest.toString()}
                    onChange={x => handleChangeFinancialInterest(x)}
                    dark={dark}
                    disabled={!chartOptionParameters.hasContract}
                    numberFormatOptions={{ suffix: '%', decimalSeparator: '.', decimalScale: 2 }}
                />
            ]}
            alternativeAttributeLabel={`${translate(TRANSLATIONS.generated['Main.AfterInnovation'])}:`}
            alternativeAttributeValue={formatValues(`${alternativeRunningCostsAfterInnovation}`)}
            alternativeAttributeUnit={pricePerMonth}
            alternativeSystemAttributes={alternativeSystemAttributes(energyPerformanceChartData)}
            referenceAttributeLabel={`${translate(TRANSLATIONS.generated['Main.RunningCostsToday'])}:`}
            referenceAttributeValue={formatValues(`${energyPerformanceChartData.chartData[0].runningCosts.toString() ?? ''}`)}
            referenceAttributeUnit={pricePerMonth}
            referenceSystemAttributes={[
                {
                    label: translate(TRANSLATIONS.generated['System.EnergyCostsReferenceSystem']),
                    value: formatValues(energyPerformanceChartData.chartData[0].referenceEnergyCosts.toString()) ?? '',
                    unit: pricePerMonth
                }
            ]}
            additionalAttributeBlocks={additionalAttributes}
            modalContent={<ComparisonCardMonthlyCostsInfo />}
        />
        : <></>;
};

export default EnergyPerformanceTab;
