import { Translations } from '../models';

const getTranslations = <T extends Translations>(translations: T): T => translations;

export const TRANSLATIONS = getTranslations({
    'generated': {
        'Allocation.BellShaped': [
            'Bell-shaped',
            'Glockenkurve'
        ],
        'Allocation.Constant100': [
            'Constant 100%',
            'Konstant 100%'
        ],
        'Allocation.Constant90100': [
            'Constant 90% & 100%',
            'Konstant 90% & 100%'
        ],
        'Allocation.Decreasing': [
            'Decreasing',
            'Absteigend'
        ],
        'Allocation.EqualSpread': [
            'Equal spread',
            'Gleichverteilung'
        ],
        'Allocation.Increasing': [
            'Increasing',
            'Ansteigend'
        ],
        'Branding.Slogan': [
            'Energy Saving and Amortization',
            'Energieeinsparung und Amortisation'
        ],
        'Breadcrumb.MySavedProjects': [
            'MySavedProjects',
            'Meine Gespeicherten Projekte'
        ],
        'Chart.BreakevenPoint': [
            'Break even point',
            'Amortisationszeitpunkt'
        ],
        'Chart.OperationTime': [
            'Operation Time',
            'Laufzeit'
        ],
        'Chart.TotalCosts': [
            'Total costs',
            'Gesamtkosten'
        ],
        'china.co2factor.link': {
            '0': [
                'China',
                ''
            ],
            '1': [
                ' power grid average CO2 emissions in the',
                ''
            ],
            '2': [
                ' year 2020',
                ''
            ],
            '3': [
                ' was ',
                ''
            ],
            '4': [
                '581 g/kWh. ',
                ''
            ],
            '5': [
                'For more information please check this',
                ''
            ],
            '6': [
                'Link',
                ''
            ],
            '7': [
                '.',
                ''
            ]
        },
        'Commercial.CustomerPrice': [
            'Customer Price',
            'Kundenpreis'
        ],
        'Commercial.Discount': [
            'Discount',
            'Rabatt'
        ],
        'Commercial.ListPrice': [
            'List price',
            'Listenpreis'
        ],
        'Common.Accept': [
            'Accept',
            'Annehmen'
        ],
        'Common.Added': [
            'Shared at',
            'Geteilt am'
        ],
        'Common.AddNew': [
            'Add new',
            'Neu hinzufügen'
        ],
        'Common.Air': [
            'Air',
            'Luft'
        ],
        'Common.AllDeselected': [
            'All deselected',
            ''
        ],
        'Common.AllSelected': [
            'All selected',
            ''
        ],
        'Common.Apply': [
            'Apply',
            'Anwenden'
        ],
        'Common.Cancel': [
            'Cancel',
            'Abbrechen'
        ],
        'Common.Casting.Aluminium': [
            'Aluminum',
            'Aluminium'
        ],
        'Common.Casting.CastIron': [
            'Cast Iron',
            'Grauguss'
        ],
        'Common.Co2EmissionSavings': [
            'CO2 emission savings',
            'CO2 Sparpotential'
        ],
        'Common.CO2EmissionSavingsPotential': [
            'CO2 emission savings potential',
            'CO2 Sparpotential'
        ],
        'Common.Co2FactorBasic': [
            'CO2 factor',
            'CO2 Faktor'
        ],
        'Common.CO2Savings': [
            'CO2 savings',
            'CO2 Einsparung'
        ],
        'Common.CommercialView': [
            'Commercial View',
            'Kommerzielle Sicht'
        ],
        'Common.ComparisonName': [
            'Comparison name',
            'Name des Vergleiches'
        ],
        'Common.Contact': [
            'Contact',
            'Kontakt'
        ],
        'Common.Continue': [
            'Continue',
            'Weiter'
        ],
        'Common.ContractRate': [
            'Contract rate',
            'Vertragsrate'
        ],
        'Common.Currency': [
            'Currency',
            'Währung'
        ],
        'Common.Custom': [
            'Custom',
            'ohne Bezeichnung'
        ],
        'Common.Date': [
            'Date',
            'Datum'
        ],
        'Common.Day': [
            'day',
            'Tag'
        ],
        'Common.Days': [
            'days',
            'Tage'
        ],
        'Common.Decline': [
            'Decline',
            'Ablehnen'
        ],
        'Common.Default': [
            'Default',
            'Standard'
        ],
        'Common.DeleteConfirmation': [
            'Are you sure to delete?',
            'Wirklich löschen?'
        ],
        'Common.DeleteSelection': [
            'Delete selection',
            ''
        ],
        'Common.Documents': [
            'Documents',
            ''
        ],
        'Common.Download': [
            'Create documentation',
            'Erzeuge Dokumentation'
        ],
        'Common.EfficiencyClass.InvestmentCost': [
            'Investment costs',
            'Investitionskosten'
        ],
        'Common.EfficiencyClass.OperationalCost': [
            'Operational costs',
            'Betriebskosten'
        ],
        'Common.EfficiencyClass.Other': [
            'editable',
            'editierbar'
        ],
        'Common.EfficiencySign': [
            'η',
            ''
        ],
        'Common.Email': [
            'Email',
            'E-Mail-Adresse'
        ],
        'Common.EnergyCosts': [
            'Energy costs',
            'Energiekosten'
        ],
        'Common.EnergyDemand': [
            'Energy Demand',
            'Voraussichtlicher Energiebedarf'
        ],
        'Common.EnergyPrice': [
            'Energy price',
            'Energiepreis'
        ],
        'Common.EnergySavingPotential': [
            'Energy saving potential',
            'Energiesparpotential'
        ],
        'Common.EnergySavings': [
            'Energy savings',
            'Energiesparpotential'
        ],
        'Common.EnpcInfo': [
            'get more information about EnPC',
            'Weiterführende Informationen zu EnPC'
        ],
        'Common.ExistingDefaultName': [
            'Default',
            'Standard'
        ],
        'Common.ExpectedEnergySavings': [
            'Expected energy savings',
            'Energiesparpotential pro Jahr'
        ],
        'Common.Filter': [
            'Filter',
            ''
        ],
        'Common.For': [
            'for',
            'für'
        ],
        'Common.Inbox': [
            'Inbox',
            'Postfach'
        ],
        'Common.Information': [
            'Information',
            'Information'
        ],
        'Common.LastModifiedDate': [
            'Last modified date',
            'Datum letzter Änderung'
        ],
        'Common.Lifetime': [
            'Years of operation',
            'Betriebsjahre'
        ],
        'Common.Login': [
            'Login',
            'Anmelden'
        ],
        'Common.Logout': [
            'Logout',
            'Abmelden'
        ],
        'Common.Months': [
            'months',
            'Monate'
        ],
        'Common.More': [
            'More',
            'Mehr'
        ],
        'Common.Name': [
            'Name',
            'Name'
        ],
        'Common.No': [
            'No',
            'Nein'
        ],
        'Common.NPerA': [
            'n/a',
            'n/a'
        ],
        'Common.Ok': [
            'Ok',
            'Ok'
        ],
        'Common.OpenComparison': [
            'Open comparison',
            'Vergleich öffnen'
        ],
        'Common.OperatingHours': [
            'Operating hours',
            'Betriebsstunden'
        ],
        'Common.OperatingHoursPerYear': [
            'Operating hours / year',
            'Betriebsstunden / Jahr'
        ],
        'Common.OperationDaysPerYear': [
            'Operation-days / year',
            'Betriebstage / Jahr'
        ],
        'Common.OperationHoursPerDay': [
            'Operation-hours / day',
            'Betriebsstunden / Tag'
        ],
        'Common.OperationProfile': [
            'Operation Profile',
            'Betriebsprofil'
        ],
        'Common.OperationProfileEdit': [
            'Operation profile edit',
            'Betriebsprofil bearbeiten'
        ],
        'Common.PartiallySelected': [
            'Partially selected',
            ''
        ],
        'Common.Power': [
            'Power',
            'Leistung'
        ],
        'Common.Products': [
            'Design',
            'Aufbau'
        ],
        'Common.Profile': [
            'Profile',
            'Profil'
        ],
        'Common.ProfileName': [
            'Profile name',
            'Profilname'
        ],
        'Common.Projects': [
            'Projects',
            ''
        ],
        'Common.RecentProjects': [
            'My recent projects',
            'Meine letzten Projekte'
        ],
        'Common.Register': [
            'Register',
            'Registrieren'
        ],
        'Common.RequiredInvestmentCosts': [
            'Required Investment costs',
            'Erforderliche Investitionskosten'
        ],
        'Common.Reset': [
            'Reset',
            'Werte zurücksetzen'
        ],
        'Common.Resolution.Content': [
            'For best experience, pleace use SinaSave on Tablet or PC.\nIf you use a mobile, please turn to landscape.',
            'Am besten nutzen Sie SinaSave auf einem Tablet oder PC. Wenn Sie ein Handy nutzen, drehen Sie es bitte in Querformat.'
        ],
        'Common.Resolution.Title': [
            'We recommend using a wider screen',
            'Wir empfehlen die Verwendung eines breiteren Bildschirms'
        ],
        'Common.Save': [
            'Save',
            'Speichern'
        ],
        'Common.SaveChanges': [
            'Save changes',
            'Änderungen Speichern'
        ],
        'Common.SaveOverwriteComparisonMessage': {
            '0': [
                'Project name: ',
                'Name des Projektes: '
            ],
            '1': [
                '%projectName%',
                '%projectName%'
            ],
            '2': [
                'Comparison name: ',
                'Name des Vergleiches: '
            ],
            '3': [
                '%comparisonName%',
                '%comparisonName%'
            ]
        },
        'Common.SaveOverwriteComparisonTitle': [
            'Overwrite Comparison',
            'Einen bestehenden Vergleich überschreiben'
        ],
        'Common.SaveOverwriteProjectMessage': {
            '0': [
                'Project name: ',
                'Name des Projektes: '
            ],
            '1': [
                '%projectName%',
                '%projectName%'
            ]
        },
        'Common.SaveOverwriteProjectTitle': [
            'Overwrite Project',
            'Bestehendes Projekt überschreiben'
        ],
        'Common.SearchResult': [
            'Search result',
            ''
        ],
        'Common.SendUsAnEmail': [
            'Send us an e-mail',
            'Senden Sie uns eine Email'
        ],
        'Common.Share': [
            'Share Results',
            'Teile Ergebnisse'
        ],
        'Common.ShowProjects': [
            'Show my projects',
            'Zeige meine Projekte'
        ],
        'Common.SupplyType': [
            'Line supply',
            'Netzversorgung'
        ],
        'Common.TCO': [
            'TCO',
            'TCO'
        ],
        'Common.TechnicalView': [
            'Technical View',
            'Technische Sicht'
        ],
        'Common.Technology.ASM': [
            'Asynchronous',
            'Asynchron'
        ],
        'Common.Technology.SRM': [
            'Synchronous-reluctance',
            'Synchron-Reluktanz'
        ],
        'Common.Time': [
            'Time',
            'Zeit'
        ],
        'Common.Today': [
            'Today',
            'Heute'
        ],
        'Common.UpdateConfirmation': [
            'Are you sure to update?',
            'Möchten Sie wirklich aktualisieren?'
        ],
        'Common.Water': [
            'Water',
            'Wasser'
        ],
        'Common.With': [
            'with',
            'mit'
        ],
        'Common.Without': [
            'without',
            'ohne'
        ],
        'Common.Yes': [
            'Yes',
            'Ja'
        ],
        'Comparison.Reset.ButtonNoText': [
            'Cancel',
            'Abbrechen'
        ],
        'Comparison.Reset.ButtonYesText': [
            'Reset',
            'Zurücksetzen'
        ],
        'Comparison.Reset.Message': {},
        'Comparison.Reset.Title': [
            'Reset Comparison',
            'Vergleich zurücksetzen'
        ],
        'Contact.SinaSaveToolSupport': [
            'SinaSave tool support',
            'Unterstützung für das Tool SinaSave'
        ],
        'Currencies.BRL.Name': [
            'Brazilian Real',
            'Brasilianischer Real'
        ],
        'Currencies.CHF.Name': [
            'Swiss Franc',
            'Schweizer Franken'
        ],
        'Currencies.CNY.Name': [
            'Chinese Yuan Renminbi',
            'Chinesischer Renminbi Yuan'
        ],
        'Currencies.CZK.Name': [
            'Czech koruna',
            'Tschechische Krone'
        ],
        'Currencies.DKK.Name': [
            'Danish krone',
            'Dänische Krone'
        ],
        'Currencies.EUR.Name': [
            'Euro',
            ''
        ],
        'Currencies.GBP.Name': [
            'British Pound',
            'Britisches Pfund'
        ],
        'Currencies.HUF.Name': [
            'Hungarian forint',
            'Forint'
        ],
        'Currencies.INR.Name': [
            'Indian Rupee',
            'Indische Rupie'
        ],
        'Currencies.MXN.Name': [
            'Mexican Peso',
            'Mexikanischer Peso'
        ],
        'Currencies.RUB.Name': [
            'Russian Ruble',
            'Russischer Rubel'
        ],
        'Currencies.SEK.Name': [
            'Swedish krona',
            'Schwedische Krone'
        ],
        'Currencies.USD.Name': [
            'US Dollar',
            'US Dollar'
        ],
        'Currencies.ZAR.Name': [
            'South African Rand',
            'Südafrikanischer Rand'
        ],
        'DirectShare.AlreadyProcessed': [
            'Already processed.',
            'Bereits durchgeführt'
        ],
        'DirectShare.Declined': [
            'Project was successfully declined.',
            'Das Projekt wurde erfolgreich verworfen.'
        ],
        'DirectShare.Description': [
            'Share a copy of your comparison or project by making it available to someone else using this feature. The copy is offered to the recipient to save directly the next time they log in.',
            'Teilen Sie eine Kopie Ihres Vergleiches oder Ihres Projektes, indem Sie diese einer anderen Person über diese Funktion zur Verfügung stellen. Die Kopie wird dem Empfänger beim nächsten Login direkt zum speichern angeboten.'
        ],
        'DirectShare.Explanation': [
            'Choose Accept to add this project to your own project list. Decline deletes the project and removes it from your mailbox. Select Cancel to postpone the acceptance or rejection decision.',
            'Wählen Sie Annehmen, um dieses Projekt zu Ihrer eigenen Projektliste hinzuzufügen. Mit Ablehnen wird das Projekt gelöscht und aus Ihrem Postfach entfernt. Wählen Sie Abbrechen, um die Entscheidung zur Annahme oder Ablehnung zu verschieben.'
        ],
        'DirectShare.NotFound': [
            'Shared project not found!',
            'Das geteilte Project wurde nicht gefunden!'
        ],
        'DirectShare.Title': [
            'Projects shared with you',
            'Mit Ihnen geteilte Projekte'
        ],
        'DirectShare.UnknownError': [
            'Unknown error happened during share handling.',
            'Ein unbekannter Fehler ist aufgetreten!'
        ],
        'Disclaimer.Content': [
            'The displayed results are non-binding values. The actual results depend on the specific conditions of use and may vary considerably. Siemens assumes no warranty or liability whatsoever for the correctness or feasibility of the displayed results.    ',
            'Die dargestellten Ergebnisse sind unverbindliche Werte. Die tatsächlichen Werte hängen von den konkreten Einsatzbedingungen ab und können daher deutlich variieren. Siemens haftet nicht für die Richtigkeit oder Realisierbarkeit der Ergebnisse'
        ],
        'Disclaimer.Title': [
            'Disclaimer',
            'Haftungsausschluss'
        ],
        'EFFMotorInfo.one': {
            '0': [
                'The introduction of the',
                'Mit Einführung der'
            ],
            '1': [
                'IEC 60034-30 ',
                'Norm '
            ],
            '2': [
                'standard',
                'IEC 60034-30'
            ],
            '3': [
                ' has resulted in changes to the nomenclature of the energy efficiency classes (IE1; IE2; IE3; IE4) as well as to the measuring technique used to determine the efficiency.',
                ' hat sich neben der Nomenklatur für Energieeffizienzklassen (IE1; IE2; IE3; IE4) auch die Messmethode zur Wirkungsgradbestimmung geändert.'
            ],
            '4': [
                " With the 'new' ",
                ' Bei der „neuen“ Messmethode '
            ],
            '5': [
                'IEC 60034-2-1',
                'IEC 60034-2-1'
            ],
            '6': [
                ' testing method, stray losses are no longer automatically assigned a value of 0.5%, but are calculated on the basis of actual measurements.',
                ' werden die Zusatzverluste nicht mehr pauschal mit 0,5 % angesetzt, sondern auf Basis von Messungen ermittelt.'
            ],
            '7': [
                'This means that the nominal efficiencies are reduced from EFF1 to IE2 and from EFF2 to IE1 although no technical or physical changes have been made to the motors.',
                'So sinken die nominellen Wirkungsgrade von EFF1 zu IE2 bzw. EFF2 zu IE1, obwohl sich technisch und physisch an den Motoren nichts ändert!'
            ]
        },
        'Errors.Between': [
            'Must be between {{MIN}} and {{MAX}}.',
            'Der Wert muss zwischen {{MIN}} und {{MAX}} liegen.'
        ],
        'Errors.FanStaticTPD': {
            '0': [
                'Static total pressure difference',
                'Der Wert '
            ],
            '1': [
                ' cannot be higher than ',
                'Statische Druckdifferenz'
            ],
            '2': [
                'total pressure difference',
                ' kann nicht größer sein als der Wert '
            ],
            '3': [
                '.',
                'Totaldruckdifferenz'
            ],
            '4': [
                '',
                '.'
            ]
        },
        'Errors.GovernmentSum': [
            'Must be lower than Total Investment.',
            'Der Wert muss geringer sein als die Gesamtinvestition.'
        ],
        'Errors.Integer': [
            'Only integer numbers allowed',
            'Es sind nur ganzzahlige Werte erlaubt'
        ],
        'Errors.InvalidEmail': [
            'Not a valid email address',
            'Das ist keine gültige Email-Adresse.'
        ],
        'Errors.InvalidEmailList': [
            "One or more email address is invalid. You can separate emails with ';', ',' or '|'.",
            '<p>Eine oder mehrere Email-Adressen sind ungültig. </p><p>Email-Adressen können mit ";", "," oder "|" getrennt werden.</p>'
        ],
        'Errors.Min': [
            'Must be at least {{MIN}}',
            'Der Wert muss mindestens  {{MIN}} sein'
        ],
        'Errors.ObsoleteWarning': [
            'Obsolete Products are used!',
            'Es werden veraltete Produkte verwendet!'
        ],
        'Errors.OnlyDigit': [
            'Only numbers allowed',
            'Es sind nur Zahlenwerte erlaubt.'
        ],
        'Errors.OperationHoursPerDayNotInRange': [
            'The operation time per day has to be within 0.1 and 24 hours.',
            'Der Wert für Betriebsstunden / Tag muss zwischen 0.1 und 24h liegen'
        ],
        'Errors.ProjectAlreadyExistsWithName': [
            'A project already exists with this name.',
            'Es besteht bereit ein Projekt mit dem selben Namen!'
        ],
        'Errors.ProjectNotExists': [
            'Project not exists!',
            'Dieses Projekt existiert nicht!'
        ],
        'Errors.ProjectOpenedDuringMassImport': [
            'The creation of all comparisons has not yet finished.',
            'Die Erzeugung der Vergleiche ist noch nicht abgeschlossen.'
        ],
        'Errors.PumpStaticHead': {
            '0': [
                'The ',
                'Der Wert '
            ],
            '1': [
                'static head',
                'statische Förderhöhe'
            ],
            '2': [
                ' cannot be higher than ',
                ' kann nicht größer sein als der Wert '
            ],
            '3': [
                'pump head',
                'Förderhöhe'
            ],
            '4': [
                '.',
                '.'
            ]
        },
        'Errors.RatedPowerWarning': [
            'The rated power of the two drive systems are different in this case',
            'Rated power of both drive systems are in this case different'
        ],
        'Errors.Required': [
            'Required field',
            'Eingabe erforderlich'
        ],
        'Errors.SpecSpeed': [
            'The value calculated for the specific speed lies outside the permissible range of >= 8 rpm and <=500 rpm. Please change the relevant input parameters.',
            'Der errechnete Wert für die spezifische Drehzahl liegt ausserhalb des zulässigen Bereiches von >= 8 1/min und <=500 1/min. Bitte ändern Sie die dafür relevanten Eingabeparameter.'
        ],
        'Errors.SpeedNotInRange': [
            'The value lies outside a practical range for {{FREQUENCY}}Hz line supplies',
            'Der Wert liegt außerhalb eines sinnvollen Bereiches für {{FREQUENCY}}Hz Netze'
        ],
        'Export.1NewDoc': [
            '1 document is available for download.',
            'Ein Dokument steht zum Download bereit.'
        ],
        'Export.AvailableUntil': [
            'Available until',
            'Verfügbar für'
        ],
        'Export.CancelConfirmation': [
            'Are you sure you want to cancel?',
            'Wollen Sie wirklich abbrechen?'
        ],
        'Export.CancelFail': [
            'Failed to cancel!',
            'Abbrechen fehlgeschlagen!'
        ],
        'Export.CancelSuccess': [
            'Succesfully cancelled.',
            'Abbruch erfolgreich.'
        ],
        'Export.CommercialResults': [
            'Commercial results',
            'Kommerzielle Ergebnisse'
        ],
        'Export.CreationProgress': [
            'Document creation {{percent}}%',
            'Status der Dokumenten Erzeugung {{percent}}%'
        ],
        'Export.DeleteFail': [
            'Failed to delete download!',
            'Download konnte nicht gelöscht werden!'
        ],
        'Export.DeleteMessagePlural': [
            '{{delete_count}} downloads will be deleted.',
            ''
        ],
        'Export.DeleteMessageSingular': [
            '1 download will be deleted.',
            ''
        ],
        'Export.DeletePluralTitle': [
            'Are you sure you want to delete the selected downloads?',
            ''
        ],
        'Export.DeleteSingularTitle': [
            'Are you sure you want to delete the selected download?',
            ''
        ],
        'Export.DeleteSuccess': [
            'Succesfully deleted.',
            'Erfolgreich gelöscht.'
        ],
        'Export.Direct': [
            'Direct',
            'Eine Kopie direkt übermitteln'
        ],
        'Export.DirectShare': [
            'Direct Share',
            'Diesen Vergleich / diese Projekt direkt teilen'
        ],
        'Export.DocWillBeGenerated': [
            'Your document will be generated shortly',
            'Ihr Dokument wird in Kürze erzeugt.'
        ],
        'Export.Docx': [
            'as DOCX-file',
            'als DOCX Datei'
        ],
        'Export.Downloads': [
            'Downloads',
            ''
        ],
        'Export.DownloadsPageExplanation': [
            'Here you can find your generated documents. You can either download or delete them. The "Available until" column shows how much time you have left to download your documents, after that they will be automatically deleted.',
            'Hier finden Sie Ihre erzeugten Dokumente.  Sie können sie entweder herunterladen oder löschen. In der Spalte „Verfügbar für“ sehen Sie, wie lange Ihnen die Dokumente zum herunterladen zur verfügung stehen bevor sie automatisch gelöscht werden.'
        ],
        'Export.FailedToFetchDownloads': [
            'Failed to fetch downloads from server!',
            'Dateien konnten nicht herunter geladen werden.'
        ],
        'Export.FanComparison': [
            'Fan comparison',
            'Lüfter-Antriebsvergleich'
        ],
        'Export.FanCompFromProject': [
            'Fan comparison from project {{projectName}}',
            'Lüfter-Antriebsvergleich aus Projekt {{projectName}}'
        ],
        'Export.FileCanBeViewedInDownloads': [
            'The file can be viewed in downloads.',
            'Die Datei finden Sie unter "Downloads" '
        ],
        'Export.FileDownloadFail': [
            'Failed to download file!',
            'Die Datei konnte nicht herungter geladen werden.'
        ],
        'Export.FileInformation': [
            'File Information',
            'Datei Information'
        ],
        'Export.Finished': [
            'Document creation finished',
            'Dokumenten Erzeugung abgeschlossen'
        ],
        'Export.MotorComparison': [
            'Motor comparison',
            'Motorvergleich'
        ],
        'Export.MotorCompFromProject': [
            'Motor comparison from project {{projectName}}',
            'Motorvergleich aus Projekt {{projectName}}'
        ],
        'Export.MultipleNewDocs': [
            '{{numOfNewDocs}} documents are available for download. Files can be viewed in downloads.',
            '{{numOfNewDocs}} Dokumente stehen zum Download bereit. Die  Datei finden Sie unter "Downloads". '
        ],
        'Export.New': [
            '(NEW)',
            '(NEU)'
        ],
        'Export.NoDownloadsAvailable': [
            'No downloads are available',
            'Es stehen keine Dateien zum Download breit.'
        ],
        'Export.NumberOfPagesInfo': [
            '(approx. {{number}} pages)',
            '(ca. {{number}} Seiten)'
        ],
        'Export.NumberOfPagesInfo.Estimated': [
            '(approx. {{number}} pages)',
            '(ca. {{number}} Seiten)'
        ],
        'Export.NumOfPagesForDownload': [
            '(That download contains approx. {{numOfPages}} pages)',
            '(Diese Datei enthält ca. {{numOfPages}} Seiten)'
        ],
        'Export.Pdf': [
            'as PDF-file',
            'als PDF Datei'
        ],
        'Export.ProjectDataResults': [
            'Summary of the project',
            'Projektzusammenfassung'
        ],
        'Export.ProjectWithComparisons': [
            'Summary of the project, inclusive details of active comparisons ',
            'Projektzusammenfassung inklusive Details der aktiven Einzelvergleiche'
        ],
        'Export.PumpComparison': [
            'Pump comparison',
            'Pumpen-Antriebsvergleich'
        ],
        'Export.PumpCompFromProject': [
            'Pump comparison from project {{projectName}}',
            'Pumpen-Antriebsvergleich aus Projekt {{projectName}}'
        ],
        'Export.Share.CantGenerateEmail': [
            "Your email can't be generated!",
            'Ihre Email konnte nicht erstellt werden!'
        ],
        'Export.Share.Done': [
            'Document share for {{downloadName}} is sent to recipient(s).',
            'Das Dokument {{downloadName}} wurde and den/die Empfänger versendet.'
        ],
        'Export.Share.EmailWillBeGenerated': [
            'Your email will be generated shortly.',
            'Ihre Email wird in Kürze erstellt.'
        ],
        'Export.Share.Fail': [
            "Failed to generate document share for {{downloadName}}! The recipient(s) won't recieve an email.",
            'Die Datei {{downloadName}} konnte nicht Versendet werden. Der/die Empfänger werden keine Email erhalten.'
        ],
        'Export.Share.TooManyShares': [
            'The request cannot be processed because too many shares were requested at the same time. Please try again later. Alternatively, you can share your project directly.',
            'Die Anfrage kann nicht verarbeitet werden, da zu viele Freigaben gleichzeitig angefordert wurden. Bitte probieren Sie es etwas später erneut.\nAlternativ können Sie Ihr Projekt direkt teilen.'
        ],
        'Export.ShareFinished': {
            '0': [
                'You can download the document by clicking on the download button below',
                'Sie können das Dokument herunterladen, indem Sie unten auf den Download-Button klicken'
            ],
            '1': [
                'Name',
                'Name'
            ],
            '2': [
                'Created on',
                'Erstellt am'
            ],
            '3': [
                'Availabe until',
                'Verfügbar für'
            ],
            '4': [
                'Action',
                'Aktion'
            ],
            '5': [
                '{{name}}',
                '{{name}}'
            ],
            '6': [
                '{{createdOn}}',
                '{{createdOn}}'
            ],
            '7': [
                '{{availableUntil}}',
                '{{availableUntil}}'
            ],
            '8': [
                'Download',
                'Download'
            ]
        },
        'Export.Started': [
            'Document creation started',
            'Dokumentenerzeugung begonnen'
        ],
        'Export.Status.Done': [
            'Document generation finished for {{downloadName}}',
            'Dokumentenerzeugung begonnen {{downloadName}}'
        ],
        'Export.Status.Fail': [
            'Document generation failed for {{downloadName}}!',
            'Dokumentenerzeugung für {{downloadName}} fehlgeschlagen!'
        ],
        'Export.StatusCanBeViewedInDownloads': [
            'Status can be viewed in downloads.',
            'Der Status wird auf der Seite "Downloads" angezeigt'
        ],
        'Export.TechnicalResults': [
            'Technical results',
            'Technische Ergebnisse'
        ],
        'Export.TooManyDownloads': [
            'The maximum number of downloads is 100. You can delete the not used ones from the Downloads page.',
            'Die maximale Anzahl an Downloads beträgt 100. Sie können nicht verwendete Download auf der Seite „Downloads“ löschen.'
        ],
        'Export.UnknownError': [
            'Unknown error happened during export handling.',
            'Ein unbekannter Fehler aufgetreten.'
        ],
        'Export.Waiting': [
            'Waiting for document creation',
            'Warten auf Dokumentenerzeugung'
        ],
        'Footer.CookiePolicy': [
            'Cookie Policy',
            'Cookie-Hinweise'
        ],
        'Footer.CorporateInformation': [
            'Corporate Information',
            'Impressum'
        ],
        'Footer.DigitalID': [
            'Digital ID',
            'Digitales Zertifikat'
        ],
        'Footer.PrivacyPolicy': [
            'Privacy Policy',
            'Datenschutz'
        ],
        'Footer.SocialMediaTitle': [
            'Follow our global channels',
            'Folgen Sie uns auf'
        ],
        'Footer.TermsOfUse': [
            'Terms of Use',
            'Nutzungsbedingungen'
        ],
        'Footer.ThirdPartySoftware': [
            'Third Party Software',
            ''
        ],
        'Help.AdditionalInvestment.Content': {
            '0': [
                'Additional investment costs that are necessary, which go beyond pure product costs, can be included in the break-even point calculation as a single item. ',
                'Zusätzlich notwendige Investitionskosten, die über die Produktkosten hinaus gehen, können hier als einmalig anfallende Position in die Amortisationsrechnung aufgenommen werden.'
            ],
            '1': [
                'Up to three individual items can be created for the particular drive system (+ symbol) and the appropriate data entered.',
                'Bis zu drei Einzelpositionen können für das jeweilige Antriebssystem erstellt (+ -Zeichen) und gepflegt werden.'
            ],
            '2': [
                'For each item, individual names can be allocated; e.g. installation, disposal, training, spare parts, additional foundation, etc.',
                ' Dabei können für jede Position individuelle Namen vergeben werden. z.B. Installation, Entsorgung, Schulung, Ersatzteile, zusätzl. Fundament, etc.'
            ]
        },
        'Help.AdditionalInvestment.Title': [
            'Help | Additional Investment',
            'Hilfe | Zusätzliche Investitionskosten'
        ],
        'Help.ChartResultTechnical.Content': {
            '0': [
                'The chart shows the ',
                'Das Liniendiagramm zeigt die '
            ],
            '1': [
                'power consumption',
                'Leistungsaufnahme'
            ],
            '2': [
                ' of both drive systems (dashed) with respect to the flow rate.',
                ' beider Antriebssysteme (gestrichelt) über der Förderrate.'
            ],
            '3': [
                'The areas between the power consumption lines of both drive systems represent the ',
                'Die Fläche zwischen den Linien der Leistungsaufnahmen der Antriebssysteme stellt das '
            ],
            '4': [
                'energy saving potential',
                'Energiesparpotential'
            ],
            '5': [
                ' (green) or a deterioration of the efficiency (red).',
                ' (grün) dar.'
            ],
            '6': [
                'Optionally, the pump power consumption (shaft power) can be displayed as an additional (solid) line.',
                'Optional besteht die Möglichkeit, die Leistungsaufnahme der Pumpe (Wellenleistung) als zusätzliche (durchgezogen) Linie darzustellen.'
            ],
            '7': [
                'Optionally, the ',
                ' Ebenfalls optional kann auch das '
            ],
            '8': [
                'operation profile',
                'Betriebsprofil'
            ],
            '9': [
                ' (operating hours per day with respect to the flow rate) can be shown in the diagram.',
                ' (Betriebsstunden am Tag über Förderrate) in der Grafik dargestellt werden.'
            ],
            '10': [
                'The diagram can be displayed in the full screen mode for a more detailed view.',
                'Zur verbesserten Detaildarstellung kann die Grafik im Vollbildmodus dargestellt werden.'
            ]
        },
        'Help.ChartResultTechnical.Title': [
            'Help | Energy Savings',
            'Hilfe | Energieeinsparung'
        ],
        'Help.ConverterCommercial.Content': {
            '0': [
                'In the commercial view, the converter type (SINAMICS or third-party converter) and the type rating cannot be edited. This is only possible in the technical view.',
                'In der '
            ],
            '1': [
                'The list price of the product is displayed if the selected currency is €. The investment costs and the payback time are calculated based on the customer price. This can be directly entered – or is determined by entering a discount factor based on the list price.',
                'Kommerziellen Sicht'
            ],
            '2': [
                'If one of the other listed currencies is selected, then only the product price field is displayed. The actual customer price must be individually entered into this field.',
                ' kann der Umrichtertyp (SINAMICS oder Fremdumrichter) und die Typleistung nicht editiert werden. Dies ist nur in der '
            ],
            '3': [
                'If a converter cabinet was selected, then the basic configuration of the unit (cabinet version A, without chargeable options) is used as basis for what is displayed.',
                'Technischen Sicht'
            ],
            '4': [
                'If a built-in unit was selected, then the power module and Control Unit are taken into account. Other components are not taken into account from a pricing perspective.',
                ' möglich.'
            ],
            '5': [
                'In the case of SINAMICS G120 Modular, to generate a price, the PM240-2 in conjunction with the CU-230P-2 HVAC is used as basis.',
                'Ist die gewählte Währung '
            ],
            '6': [
                '',
                '€ '
            ],
            '7': [
                '',
                ', wird der Listenpreis des Produktes angezeigt. Zur Berechnung der'
            ],
            '8': [
                '',
                ' Investitionskosten'
            ],
            '9': [
                '',
                ' und der '
            ],
            '10': [
                '',
                'Amortisation'
            ],
            '11': [
                '',
                ' wird der Kundenpreis zugrunde gelegt. Dieser kann direkt eingegeben werden oder wird durch Eingabe eines Rabattfaktors auf Basis des Listenpreises ermittelt.'
            ],
            '12': [
                '',
                'Wurde eine der anderen angebotenen Währungen gewählt, kommt nur das Feld '
            ],
            '13': [
                '',
                ' Produktpreis'
            ],
            '14': [
                '',
                ' zur Anzeige. Der tatsächliche Kundenpreis muss in dieses Feld individuell eingegeben werden.'
            ],
            '15': [
                '',
                'Sofern ein '
            ],
            '16': [
                '',
                'Umrichterschrank'
            ],
            '17': [
                '',
                ' gewählt wurde, liegt die Grundkonfiguration des Gerätes (Schrankausführung A, ohne kostenpflichtige Optionen) der Darstellung zugrunde.'
            ],
            '18': [
                '',
                'Wurde ein '
            ],
            '19': [
                '',
                'Einbaugerät'
            ],
            '20': [
                '',
                ' gewählt, so wird Powermodul und Control Unit berücksichtigt. Weitere Komponenten finden preislich keine Beachtung.'
            ],
            '21': [
                '',
                'Im Falle des SINAMICS G120 Modular wird zur Preisbildung das PM240-2 in Verbindung mit der CU-230P-2 HVAC zugrunde gelegt.'
            ]
        },
        'Help.ConverterCommercial.Title': [
            'Help | Converter',
            'Hilfe | Umrichter'
        ],
        'Help.ConverterTechnical.Content': {
            '0': [
                'The preselection of the converter unit rating is oriented on the unit rating of the preselected motor.',
                'Die Vorauswahl der Umrichter-Typleistung orientiert sich an der Typleistung des ausgewählten Motors. Diese kann übersteuert und an individuelle Vorgaben angepasst werden.'
            ],
            '1': [
                'The preselection can be overridden based on the "unit rating" or "rated current" quantity and adapted to the individual specifications.',
                'Die hier dargestellten Umrichter sind in ihrer Grundkonfiguration, d.h. ohne Optionen wie z.B. Drosseln in der Berechnung berücksichtigt.'
            ],
            '2': [
                ' The following are available for selection:  ',
                'Das Mittelspannugnsumrichtersystem SINAMICS PERFECT HARMONY GH180 wird immer mit integriertem Trafo geliefert. Daher wird der Trafo in diesem Fall auch in den SinaSave -Berechnungen mit einbezogen. '
            ],
            '3': [
                'cabinet devices',
                'Niederspannungsumrichter haben keinen Trafo.'
            ],
            '4': [
                'built-in devices',
                'Im Falle des SINAMICS G120 Modular wird dasPower Modul PM240-2 in Verbindung mit der Control Unit CU-230P-2 HVAC den Berechnungen zugrunde gelegt.'
            ],
            '5': [
                'In the case that SINAMICS G120 Modular is considered, PM240-2 and CU230P-2 HVAC is the basis of the calculations.',
                ''
            ]
        },
        'Help.ConverterTechnical.Title': [
            'Help | Converter',
            'Hilfe | Umrichter'
        ],
        'Help.ENPC.Content': {
            '0': [
                'The black line shows the monthly costs before, during and after the contract.',
                'Die schwarze Linie sind die monatlichen Kosten vor, während und nach Vertragsabschluss.'
            ],
            '1': [
                'At 0 months in the y-axis the Energy Performance contract starts for the alternative system.',
                'Bei 0 Monaten in der y-Achse beginnt der Vertrag mit dem Alternativsystem.'
            ],
            '2': [
                ' The green field between the dash lines are the energy saving potentials with the alternative system.',
                'Die grüne Fläche zwischen den gestrichelten Linien sind die Energieeinsparpotentiale mit dem Alternativsystem.'
            ],
            '3': [
                'Full screen is supported for a more detailed view.',
                'Vollbild für eine detaillierte Ansicht wird unterstützt.'
            ]
        },
        'Help.ENPC.Title': [
            'Help | Monthly costs',
            'Hilfe | Monatliche Kosten'
        ],
        'Help.ExistingPoleChangingMotorTechnical.Content': {
            '0': [
                ' Pole-changing motors have two motor windings and thus two static speeds to choose between. An efficiency can be maintained for each winding, which is then used as the basis for calclulating the energy consumption. ',
                ' Polumschaltbare Motoren besitzen zwei Motorwicklungen und damit zwei statische Drehzahlen, zwischen welchen gewählt werden kann. Für jede Wicklung kann ein Wirkungsgrad gepflegt werden, der als Grundlage zur Berechnung des Energieverbrauchs genutzt wird. '
            ],
            '1': [
                ' Rated speed 1 is taken over on the basis of the entered application speed (pump or fan). Rated speed 2 corresponds to the speed of the motor with double pole pair number. ',
                ' Die Nenndrehzahl 1 wird anhand  der eingegebenen Applikationsdrehzahl (Pumpe oder Lüfter) übernommen. Die Nenndrehzahl 2 entspricht der Drehzahl des Motors mit doppelter Polpaarzahl.   '
            ]
        },
        'Help.FanCommercialControlMode.Content': {
            '0': [
                'The control mode cannot be changed in the commercial view. This is only possible in the technical view.',
                'In der '
            ],
            '1': [
                'Throttle control',
                'Kommerziellen Sicht'
            ],
            '2': [
                'The flow is reduced by increasing the flow resistance in the system.',
                ' kann die Regelungsart nicht geändert werden. Dies ist nur in der '
            ],
            '3': [
                ' Bypass control',
                'Technischen Sicht'
            ],
            '4': [
                'The flow through the bypass is increased – and the effective flow rate for the process itself is reduced.',
                ' möglich.'
            ],
            '5': [
                ' Closed-loop speed control',
                'Drosselregelung'
            ],
            '6': [
                'The pump speed is controlled according to the flow rate currently required in the process.',
                'Durch Erhöhung des Anlagenwiderstandes wird  der effektive Volumenstrom reduziert'
            ],
            '7': [
                '',
                'Bypassregelung'
            ],
            '8': [
                '',
                'Der Bypassförderstrom wird erhöht, der effektive Volumenstrom am Prozess wird dadurch reduziert'
            ],
            '9': [
                '',
                'Drehzahlregelung'
            ],
            '10': [
                '',
                'Regelung der Lüfterdrehzahl gemäß dem aktuell im Prozess benötigten Volumenstrom'
            ]
        },
        'Help.FanCommercialControlMode.Title': [
            'Help | Fan | Control Mode',
            'Hilfe | Lüfter | Regelungssart'
        ],
        'Help.FanLoadPoint.Content': {
            '0': [
                'Required shaft power',
                'Erforderliche Wellenleistung'
            ],
            '1': [
                'Corresponds to the mechanical shaft power requirement of the fan ',
                'Entspricht dem mechanischen Wellenleistungsbedarf des Lüfters '
            ],
            '2': [
                'Fan speed ',
                'Lüfter-Drehzahl'
            ],
            '3': [
                'Fan speed according to the information on the nameplate. This is used to preselect the motor ',
                'Lüfter-Drehzahl gemäß den Angaben auf dem Typenschild. Das dient zur Vorauswahl des Motors '
            ],
            '4': [
                'Operating hours / year',
                'Betriebsstunden / Jahr'
            ],
            '5': [
                'Annual total running time of the system in hours. They are used to calculate the expected energy demand',
                'Jährliche Gesamtlaufzeit der Anlage in Stunden. Sie dienen zur Berechnung des zu erwartenden Energiebedarfs'
            ],
            '6': [
                'Distribution',
                'Verteilung'
            ],
            '7': [
                'The distribution of the operating hours of a typical working day over the percentage production rate ',
                'Die Verteilung der Betriebsstunden eines typischen Arbeitstages über die prozentuale Produktionsrate'
            ]
        },
        'Help.FanLoadPoint.Title': [
            'Help | Load Point and operation profile',
            'Hilfe | Lastpunkt und Betriebsprofil'
        ],
        'Help.FanTechnicalApplication.Content': {
            '0': [
                'Fan name',
                'Bezeichnung des Lüfters'
            ],
            '1': [
                'By allocating a name, the technical fan data are saved, and can be subsequently called up under the same name',
                'Durch Vergabe eines Namens werden die technischen Daten des Lüfters abgespeichert und können unter demselben Namen später wieder aufgerufen werden'
            ],
            '2': [
                'Total pressure difference',
                'Totaldruckdifferenz'
            ],
            '3': [
                'Measurable pressure difference between the suction and discharge side of the fan',
                'Messbare Druckdifferenz zwischen der Saugseite und der Druckseite des Lüfters'
            ],
            '4': [
                'Flow rate',
                'Volumenstrom'
            ],
            '5': [
                'The flow rate demanded by the process',
                'Aus dem Prozess geforderter Volumenstrom'
            ],
            '6': [
                'Speed',
                'Drehzahl'
            ],
            '7': [
                'fan speed as stamped on the rating plate. This is used to preselect the motor',
                'Drehzahl des Lüfters entsprechend der Angaben des Typenschildes. Sie dient zur Vorauswahl des Motors'
            ],
            '8': [
                'Number of fan stages',
                'Anzahl der Lüfterstufen'
            ],
            '9': [
                'Selection of the number of fan stages corresponding to the fan used (data sheet). The number of stages significantly influences the specific speed',
                'Auswahl der  Lüfterstufenzahl entsprechend des zu verwendenden Lüfters (Datenblatt). Die Stufenzahl beeinflusst maßgeblich die spezifische Drehzahl'
            ],
            '10': [
                'Specific speed',
                'Spezifische Drehzahl'
            ],
            '11': [
                'Parameter to characterize fluid flow machines. Values between 8 and 500 are permitted. The laws applicable to fluid flow machines do not apply for fans that lie outside this window',
                'Kennzahl zur Charakterisierung von Strömungsmaschinen. Es sind Werte zwischen 8 und 500 zulässig. Für Lüfter außerhalb dieses Wertefensters gelten die Gesetze der Strömungsmaschinen nicht '
            ],
            '12': [
                'Flow rate',
                'Förderrate '
            ],
            '13': [
                'The flow rate as a function of the rated fan flow rate',
                'Zu förderndes Volumen in Abhängigkeit des Nennfördervolumens des Lüfters'
            ],
            '14': [
                'Efficiency',
                'Wirkungsgrad'
            ],
            '15': [
                'Fan efficiency as a function of the flow rate ',
                'Wirkungsgrad des Lüfters in Abhängigkeit der Förderrate '
            ],
            '16': [
                'Medium',
                'Medium'
            ],
            '17': [
                'Material to be transported/pumped. Media can be saved in the tool by specifying the name and the associated specific density – and subsequently called up (a login is required)',
                'Stoff, der gefördert wird. Medien können durch Angabe eines Namens und zugehöriger Dichte im Tool gespeichert und wieder aufgerufen werden (Login erforderlich)'
            ],
            '18': [
                'Specific density',
                'Dichte'
            ],
            '19': [
                'Specific density of the medium being conveyed/pumped',
                'Dichte des zu fördernden Mediums'
            ]
        },
        'Help.FanTechnicalApplication.Title': [
            'Help | Fan',
            'Hilfe | Lüfter'
        ],
        'Help.FanTechnicalControlMode.Content': {
            '0': [
                'Throttle control',
                'Drosselregelung'
            ],
            '1': [
                'The flow is reduced by increasing the flow resistance in the system',
                'Durch Erhöhung des Anlagenwiderstandes wird der effektive Volumenstrom reduziert'
            ],
            '2': [
                'Bypass control',
                'Bypassregelung'
            ],
            '3': [
                'The flow through the bypass is increased – and the effective flow rate for the process itself is reduced',
                'Der Bypassförderstrom wird erhöht, der effektive Volumenstrom am Prozess wird dadurch reduziert'
            ],
            '4': [
                'Variable speed control',
                'Drehzahlregelung'
            ],
            '5': [
                'The fan speed is controlled according to the flow rate currently required in the process',
                'Regelung der Lüfterdrehzahl gemäß dem aktuell im Prozess benötigten Volumenstrom'
            ]
        },
        'Help.FanTechnicalControlMode.Title': [
            'Help | Fan | Control Mode',
            'Hilfe | Lüfter | Regelungsart'
        ],
        'Help.FanTechnicalOperationProfile.Content': {
            '0': [
                ' The sum of the individual values yields the number of operating hours in a typical working day. It is assumed that "quasi steady state" operating modes are involved. This means: Energy, used for example when braking and accelerating, is not considered in the evaluation.',
                ' Die Summe der Einzelwerte ergibt die Betriebsstundenzahl eines typischen Arbeitstages. Dabei wird angenommen, dass es sich um „quasistationäre“ Betriebszustände handelt. D.h. der Energieverbrauch, der z.B. durch Bremsen und Beschleunigen hervorgerufen wird, findet in der Betrachtung keine Berücksichtigung.'
            ],
            '1': [
                'The operating profile is shown as a bar chart on the right side of the tool in the Energy savings diagram.',
                'Auf der rechten Seite des Tools in der Ergebnisgraphik wird das Betriebsprofil als Balkendiagramm veranschaulicht.'
            ]
        },
        'Help.FanTechnicalOperationProfile.Title': [
            'Help | Fan | Operation Profile',
            'Hilfe | Lüfter | Betriebsprofil'
        ],
        'Help.GovernmentFunding.Content': {
            '0': [
                'Many governments promote the use of efficient drive technology through grants. The funding programs are designed very differently. Information on whether such funding programs exist and what requirements apply is usually provided by the respective economics ministries.',
                'Viele Regierungen fördern den Einsatz effizienter Antriebstechnik durch Zuschüsse. Die Förderprogramme sind sehr unterschiedlich gestaltet. Ob es solche Förderprogramme in Ihrem Land gibt und welche Voraussetzungen gelten, darüber informieren in der Regel die jeweiligen Wirtschaftsministerien.'
            ],
            '1': [
                'If a subsidy is granted, this can be entered in SinaSave as a fixed subsidy amount or as a percentage subsidy on the investment amount. The required investment sum and the expected amortization are reduced accordingly.',
                'Wird ein Zuschuss gewährt, kann dieser in SinaSave als fester Betrag oder als prozentualer Zuschuss auf die Investitionssumme hinterlegt werden. Die erforderliche Investitionssumme und die voraussichtliche Amortisation reduzieren sich entsprechend.'
            ]
        },
        'Help.GovernmentFunding.Title': [
            'Help | Government Funding',
            'Hilfe | Staatliche Förderung'
        ],
        'Help.IES.Content': {
            '0': [
                'Standard IEC 61800-9-2',
                'Die  Norm IEC 61800-9-2 beschreibt Ökodesign-Anforderungen für elektrische Antriebssysteme und deren Komponenten im Niederspannungsbereich (&lt;1000V) bis zu einer Antriebsleistung von 1MW.'
            ],
            '1': [
                ' describes the ecodesign requirements for low-voltage (&lt;1000V) electric drive systems and their components up to a drive rating of 1MW. ',
                ' Nach IEC 61800-9-2 werden u.a.  Verlustleistungen der Antriebssysteme an acht definierten Betriebspunkten beschrieben. Damit können Anwender verschiedene Antriebssysteme anhand von standardisierten Belastungsprofilen analysieren und vergleichen.'
            ],
            '2': [
                'According to IEC 61800-9-2, ',
                ' Die Antriebssysteme werden in Effizienzklassen  IES 0 ,  IES 1 und  IES 2, abhängig von ihren Verlusten, am Volllastpunkt eingeteilt. Als Maßstab dient hier das sog.  Referenz PDS (Power Drive System), welches die ebenfalls von der Norm definierte Bezugsgröße darstellt.'
            ],
            '3': [
                ' drive system power losses',
                ' Mehr Informationen zur IEC 61800-9-2 finden Sie '
            ],
            '4': [
                ' are described at 8 defined operating points. This means that users can analyze and compare various drive systems based on standard load profiles. ',
                ' hier'
            ],
            '5': [
                'Depending on their losses at the full load operating point, drive systems are classified in efficiency classes ',
                ' Die Graphik weist die  Systemverlustleistungen gemäß IEC 61800-9-2 für das oder die umrichtergeregelten Antriebssysteme in [kW] für den Nennpunkt sowie sieben weitere Teillastpunkte aus.'
            ],
            '6': [
                ' IES 0',
                ' Durch die in der IEC 61800-9-2 definierten Teillastpunkte mit Drehzahl 0% bzw. 50% ist diese Graphik nur auf drehzahlvariable Antriebssysteme anwendbar. Für  Festdrehzahllösungen werden deshalb hier keine Werte ausgegeben.'
            ],
            '7': [
                ', ',
                ' Im Vollbildmodus kann neben den Verlustleistungen der Antriebssysteme auch die Verlustleistung des in der Norm IEC 61800-9-2 definierten  Referenz-Power-Drive-System (PDS)  dargestellt werden.'
            ],
            '8': [
                ' IES 1',
                ' Ebenfalls im Vollbildmodus kann die  prozentuale Differenz  zwischen den Verlustleistungen der ausgewählten Antriebssysteme zur Anzeige gebracht werden.'
            ],
            '9': [
                ' and ',
                ''
            ],
            '10': [
                ' IES 2',
                ''
            ],
            '11': [
                '. The ',
                ''
            ],
            '12': [
                ' PDS (Power Drive System) ',
                ''
            ],
            '13': [
                ' reference is used as a benchmark, which is also defined by the standard as reference variable.',
                ''
            ],
            '14': [
                ' You can obtain more information on IEC 61800-9-2',
                ''
            ],
            '15': [
                ' here',
                ''
            ],
            '16': [
                'The diagram shows the ',
                ''
            ],
            '17': [
                ' system losses according to IEC 61800-9-2 ',
                ''
            ],
            '18': [
                'for the ',
                ''
            ],
            '19': [
                'converter-controlled',
                ''
            ],
            '20': [
                ' drive systems in [kW] for the rated operating point – as well as 7 additional partial load operating points.',
                ''
            ],
            '21': [
                'As a result of the partial load points, with a speed of either 0% or 50%, defined in IEC 61800-9-2, this diagram can only be applied to variable-speed drive systems. As a consequence, ',
                ''
            ],
            '22': [
                ' no values are output here for fixed speed solutions',
                ''
            ],
            '23': [
                '.',
                ''
            ],
            '24': [
                'In the full-screen mode, in addition to the power loss of the drive systems, the power loss of the ',
                ''
            ],
            '25': [
                'reference power drive system (PDS) ',
                ''
            ],
            '26': [
                ' defined in standard IEC 61800-9-2 can also be displayed.',
                ''
            ],
            '27': [
                'In the full-screen mode, the ',
                ''
            ],
            '28': [
                ' percentage difference ',
                ''
            ],
            '29': [
                ' between the power losses of the selected drive systems can also be displayed.',
                ''
            ]
        },
        'Help.IES.Title': [
            'Help | Power loss according IEC 61800-9-2',
            'Hilfe | Verlustleistung nach IEC 61800-9-2'
        ],
        'Help.LineSupplyTechnical.Content': {
            '0': [
                ' Depending on the selected basic standard (IEC or NEMA), the following grids are available:  ',
                ' Abhängig von der gewählten Basisnorm (IEC oder NEMA) werden folgende Netze angeboten:  '
            ],
            '1': [
                ' Three-phases line supplies with 50 Hz line frequency for low voltage (400 V, 500 V or 690 V) and medium or high voltage (3.3 kV, 6 kV or 10 kV). ',
                ' dreiphasige Wechselstromnetze mit 50 Hz Netzfrequenz für Niederspannung (400 V, 500 V oder 690 V) und Mittel- bzw. Hochspannung (3,3 kV, 6 kV oder 10 kV). '
            ],
            '2': [
                ' Three-phases line supplies with 60 Hz line frequency for low voltage (480 V)  ',
                ' dreiphasige Wechselstromnetze mit 60 Hz Netzfrequenz für Niederspannung (480 V)  '
            ],
            '3': [
                ' A change in the network affects both drive systems. ',
                ' Eine Änderung des Netzes wirkt sich auf beide Antriebssysteme aus. '
            ],
            '4': [
                ' Only those sysem components that are actually suitable for the selected number of phases, voltage and frequency are considered. This can sometimes mean that no Siemens product is available (e.g. for unusually high power ratings). In these cases, SinaSave offers a neutral calculation model. ',
                ' Es werden nur Systemkomponenten in der Betrachtung berücksichtigt, welche für die gewählte Phasenzahl, Spannung und Frequenz geeignet sind. Dies kann unter Umständen dazu führen, dass (z.B. bei großen Leistungen) für das gewählte Netz kein(e) Siemens-Produkt(e) zur Verfügung steht (stehen). SinaSave bietet in diesen Fällen ein neutrales Rechenschema an. '
            ]
        },
        'Help.LineSupplyTechnical.Title': [
            'Help | Net',
            'Hilfe | Netz'
        ],
        'Help.MotorCommercial.Content': {
            '0': [
                'The motor type (standard SIMOTICS motor, SIMOTICS converter motor or an existing motor) and type rating cannot be edited in the commercial view. This is only possible in the technical view.',
                'In der '
            ],
            '1': [
                ' If the selected currency is €, then the list price of the product in the basic version (without options that are charged for) is displayed. The investment costs and the payback time are calculated based on the customer price. This can be directly entered – or is determined by entering a discount factor based on the list price.',
                'Kommerziellen Sicht'
            ],
            '2': [
                ' If one of the other listed currencies is selected, then only the product price field is displayed. The actual customer price must be individually entered into this field.',
                ' kann der Motortyp (SIMOTICS Standardmotor, SIMOTICS Umrichtermotor oder ein vorhandener Motor) und die Typleistung nicht editiert werden. Dies ist nur in der '
            ],
            '3': [
                '',
                'Technischen Sicht'
            ],
            '4': [
                '',
                ' möglich.'
            ],
            '5': [
                '',
                'Ist die gewählte Währung '
            ],
            '6': [
                '',
                '€ '
            ],
            '7': [
                '',
                ', wird der Listenpreis des Produktes in der Grundausführung (ohne kostenpflichtige Optionen) angezeigt. Zur Berechnung der '
            ],
            '8': [
                '',
                'Investitionskosten'
            ],
            '9': [
                '',
                ' und der '
            ],
            '10': [
                '',
                'Amortisation'
            ],
            '11': [
                '',
                ' wird der Kundenpreis zugrunde gelegt. Dieser kann direkt eingegeben werden oder wird durch Eingabe eines Rabattfaktors auf Basis des Listenpreises ermittelt.'
            ],
            '12': [
                '',
                'Wurde eine der anderen angebotenen Währungen gewählt, kommt nur das Feld '
            ],
            '13': [
                '',
                ' Produktpreis'
            ],
            '14': [
                '',
                ' zur Anzeige. Der tatsächliche Kundenpreis muss in dieses Feld individuell eingegeben werden.'
            ]
        },
        'Help.MotorCommercial.Title': [
            'Help | Motor',
            'Hilfe | Motor'
        ],
        'Help.MotorCommercialAlternative.Content': {
            '0': [
                'The alternative motor was defined in the technical view and cannot be altered in the commercial view.',
                'Der Alternativmotor wurde in der Technischen Sicht festgelegt und kann in der Kommerziellen Sicht nicht geändert werden.'
            ],
            '1': [
                'The list prices are supplied automatically by the system. The customer price can either be entered in the appropriate field or calculated by entering a discount.',
                'Sofern die gewählte '
            ],
            '2': [
                '',
                'Währung €'
            ],
            '3': [
                '',
                ' ist, '
            ],
            '4': [
                '',
                'werden'
            ],
            '5': [
                '',
                ' die '
            ],
            '6': [
                '',
                'Listenpreise automatisch zugespielt'
            ],
            '7': [
                '',
                '. Der Kundenpreis kann direkt im entsprechenden Feld eingegeben werden oder wird durch Eingabe eines Rabattes errechnet.'
            ]
        },
        'Help.MotorCommercialAlternative.Title': [
            'Help | Motors | Alternative Motor',
            'Hilfe | Motoren | Alternativmotor'
        ],
        'Help.MotorCommercialReference.Content': {
            '0': [
                'The reference motor was defined in the technical view and cannot be changed in the commercial view.',
                'Der Referenzmotor wurde in der Technischen Sicht festgelegt und kann in der Kommerziellen Sicht nicht geändert werden.'
            ],
            '1': [
                ' If a SIEMENS motor (SIMOTICS) was specified as the reference, then the system automatically supplies the list prices. The customer price can either be entered in the appropriate field or calculated by entering a discount.',
                ' Wurde ein '
            ],
            '2': [
                ' If an existing motor or a third-party motor is used as a reference, then the price and potential repair and maintenance costs must be entered manually.',
                'SIEMENS-Motor (SIMOTICS)'
            ],
            '3': [
                '',
                ' als Referenz definiert und die gewählte Währung ist '
            ],
            '4': [
                '',
                '€'
            ],
            '5': [
                '',
                ', so'
            ],
            '6': [
                '',
                ' werden'
            ],
            '7': [
                '',
                ' die '
            ],
            '8': [
                '',
                'Listenpreise automatisch'
            ],
            '9': [
                '',
                ' aus dem System '
            ],
            '10': [
                '',
                'zugespielt'
            ],
            '11': [
                '',
                '. Der Kundenpreis kann direkt im entsprechenden Feld eingegeben werden oder dieser wird durch Eingabe eines Rabattes errechnet.'
            ],
            '12': [
                '',
                ' Dient ein bereits vorhandener Motor oder ein Fremdprodukt als Referenz, so müssen die'
            ],
            '13': [
                '',
                ' Kosten für eine evtl. Instandsetzung'
            ],
            '14': [
                '',
                ' manuell eingeben werden.'
            ]
        },
        'Help.MotorCommercialReference.Title': [
            'Help | Motors | Reference Motor',
            'Hilfe | Motoren | Referenzmotor'
        ],
        'Help.MotorCommercialReferenceResult.Content': {
            '0': [
                'The annual energy cost for the reference motor is displayed under energy costs. These costs are derived from annual energy consumption and the energy price. The energy price can be specified in the menu under Settings.',
                'Unter Energiekosten werden die jährlichen Energiekosten des Referenzmotors dargestellt. Diese ergeben sich aus dem jährlichen Energieverbrauch und dem Energiepreis. Der Energiepreis kann im Menü unter Einstellungen festgelegt werden.'
            ],
            '1': [
                'The investment cost of the reference motor is displayed under Investment.',
                'Unter Investition werden die Investitionskosten des Referenzmotors dargestellt.'
            ],
            '2': [
                'If the reference motor is a new motor from SIEMENS (SIMOTICS), then the investment cost is equal to the customer price.',
                'Ist der Referenzmotor ein neuer Motor von SIEMENS (SIMOTICS), dann ist Investition mit dem Kundenpreis gleichzusetzen.'
            ],
            '3': [
                'If an existing motor or a third-party motor is used as a reference, the investment is equal to the cost of any potential repair and maintenance costs.',
                'Dient ein bereits vorhandener Motor oder ein Fremdprodukt als Referenz, so stellt die Investition die Kosten für eine evtl. erforderliche Instandsetzung bzw. -haltung dar.'
            ]
        },
        'Help.MotorTCO.Content': {
            '0': [
                'The two graphs show the ',
                'Die beiden Graphen geben den '
            ],
            '1': [
                'cost trend to be expected',
                'zu erwartenden Kostenverlauf '
            ],
            '2': [
                ' over the operating time of the motors being compared with one. ',
                '  über die Laufzeit der gegenübergestellten Motoren wieder.'
            ],
            '3': [
                'The initial value at operating time = 0 corresponds to the',
                'Der Anfangswert  bei Laufzeit = 0 entspricht dem Wert  '
            ],
            '4': [
                ' investment ',
                ' Investition '
            ],
            '5': [
                'value for the particular motor. The break-even point is where the two lines intersect.',
                ' des jeweiligen Motors. Der Amortisationszeitpunkt ist der Schnittpunkt der beiden Graphen.'
            ],
            '6': [
                'The diagram shows the comparison between the currently selected alternative motor with the reference motor – even if several alternative motors were involved with the comparison with the reference motor. The alternative motor displayed in the diagram is selected using the option button in the result area (this is only listed if more than one alternative motors is being considered).',
                'Die Grafik gibt den Vergleich des aktuell ausgewählten Alternativmotors  mit dem Referenzmotor wieder, auch wenn mehrere Alternativmotoren in der Betrachtung dem Referenzmotor gegenübergestellt werden. Die '
            ],
            '7': [
                '',
                'Auswahl '
            ],
            '8': [
                '',
                'des in der Grafik angezeigten Alternativmotors  erfolgt '
            ],
            '9': [
                '',
                'über Radiobuttons'
            ],
            '10': [
                '',
                ' im Ergebnisbereich (diese werden nur angeboten, wenn mehr als ein Alternativmotor betrachtet wird).'
            ]
        },
        'Help.MotorTCO.Title': [
            'Help I Motors I Total costs of Ownership',
            'Hilfe I Motoren I Lebenszykluskosten (TCO)'
        ],
        'Help.MotorTechnical.Content': {
            '0': [
                ' With throttle and bypass control, the motors are operated directly on the power supply grid. ',
                ' Bei Drossel- und bei Bypassregelung  werden die Motoren direkt am  Netz betrieben.  '
            ],
            '1': [
                ' The motor type rating is preselected based on the determined power requirement of the application (pump or fan) at the rated operating point, based on the delivery height or total pressure difference and volume flow, while taking into account the speed. ',
                ' Die Vorauswahl der Typleistung des Motors erfolgt anhand des ermittelten Leistungsbedarfs der Applikation (Pumpe oder Lüfter) im Nennpunkt aus Förderhöhe bzw. Totaldruckdifferenz und Volumenstrom und unter Berücksichtigung der Drehzahl. '
            ],
            '2': [
                ' The motor speed or pole pair number is selected based on the pump or fan speed that was entered. Small deviations between the rated speed of the motor and that of the application are normal. Variable-speed drives can compensate this difference. ',
                ' Die Motordrehzahl bzw. Polpaarzahl wird anhand der eingegebenen Pumpen- bzw. Lüfterdrehzahl gewählt. Dabei kommt es in der Regel zu geringen Abweichungen zwischen der Bemessungsdrehzahl des Motors und der Applikation. Drehzahlveränderbare Antriebe können diese Differenz ausregeln. '
            ]
        },
        'Help.MotorTechnical.Title': [
            'Help | Motor',
            'Hilfe | Motor'
        ],
        'Help.MotorTechnicalAlternative.Content': {
            '0': [
                ' Once the basic data for the motor is selected under Motor profile, the definition of the alternative motor is completed by entering the energy efficiency class (IE1-IE4) and enclosure material. An assignment is automatically made to the SIMOTICS series (e.g. SIMOTICS GP). Efficiency classes are automatically supplied by the system and in this case cannot be edited.',
                ' Nachdem unter Motorprofil bereits wesentliche Größen des Motors gewählt wurden, wird der Alternativmotor durch die Festlegung von Energieeffizienzklasse (IE1-IE4) und Gehäusematerial vollständig definiert. Die Zuordnung zu einer SIMOTICS Reihe (z.B. SIMOTICS GP) erfolgt automatisch. Die Wirkungsgrade werden aus dem System zugespielt und können in diesem Fall nicht editiert werden.'
            ],
            '1': [
                'The comparison can be expanded by adding additional alternative motors. The ',
                'Der Vergleich kann um weitere Alternativmotoren erweitert werden. Durch '
            ],
            '2': [
                '+ New motor ',
                '+ Neuer Motor '
            ],
            '3': [
                ' option can be used to add motors, which can then be deleted with the ',
                ' können Motoren hinzugefügt und über das '
            ],
            '4': [
                'x',
                'x'
            ],
            '5': [
                '. There must be at least 1 alternative motor.',
                ' wieder gelöscht werden. Es muss mindestens ein Alternativmotor existieren. '
            ]
        },
        'Help.MotorTechnicalAlternative.Title': [
            'Help | Motors | Alternative Motor',
            'Hilfe | Motoren | Alternativmotor'
        ],
        'Help.MotorTechnicalAlternativeResult.Content': {
            '0': [
                '\nAt this point, the energy requirement for the alternative motor is shown, taking into account the operating profile that is, based on the time slices for the various load points.',
                'An dieser Stelle wird der Energiebedarf des Alternativmotors unter Berücksichtigung des Betriebsprofils, d.h. unter Berücksichtigung der Zeitanteile an den jeweiligen Lastpunkten dargestellt.\n'
            ],
            '1': [
                ' The energy saving potential of this alternative motor is derived from the difference between its energy demand, and that of the reference motor.',
                ' Das Energiesparpotential dieses Alternativmotors ergibt sich aus der Differenz des Energiebedarfs dieses Motors und des Energiebedarfs des Referenzmotors.\n'
            ],
            '2': [
                ' The CO2 reduction is directly proportional to the energy saving. The CO2 factor can be individually adjusted under settings or personal settings. The saving relates to the reference motor.',
                ' Der Zusammenhang zwischen Verlustleistung und Motorlast wird in der Grafik dargestellt. Ist mehr als ein Alternativmotor vorhanden, lassen sich die jeweiligen Grafen über Checkboxen aus- bzw. einblenden.\n'
            ],
            '3': [
                ' The diagram shows the correlation between the power loss and motor load. The associated diagram can be displayed or hidden using the checkbox.',
                ' Die CO2-Einsparung steht im direkten Zusammenhang mit der Energieeinsparung. Der CO2-Faktor kann unter '
            ],
            '4': [
                '',
                'Einstellungen'
            ],
            '5': [
                '',
                ' bzw. '
            ],
            '6': [
                '',
                'persönliche Einstellungen'
            ],
            '7': [
                '',
                ' individuell angepasst werden. Die Einsparung bezieht sich auf den Referenzmotor.\n'
            ]
        },
        'Help.MotorTechnicalAlternativeResult.Title': [
            'Help | Motors | Alternative Motor',
            'Hilfe | Motoren | Alternativmotor'
        ],
        'Help.MotorTechnicalChart.Content': {
            '0': [
                'The diagram shows the power losses of the reference and alternative motor(s) for load points 2/4, 3/4 and 4/4 with interpolated lines of the individual points corresponding to the various operating points. When there is more than one alternative motor, you can choose which one is to be used for comparison. Only one alternative motor can be compared with the reference motor at any one time.',
                'Die Grafik stellt die Verlustleistungen von Referenz- und Alternativmotor(en) für die jeweiligen Lastpunkte 2/4, 3/4 und 4/4 durch interpolierte Linien der einzelnen Punkte zu den jeweiligen Betriebspunkten dar. Bei mehr als einem Alternativmotor kann durch Auswahl eines Alternativmotors der gewünschte Vergleich angewählt werden. Es kann immer nur ein Alternativmotor mit dem Referenzmotor verglichen werden.'
            ],
            '1': [
                'The bar chart depicts the operating profile and shows the distribution of time slices at various load points.',
                'Das Balkendiagramm gibt das Betriebsprofil wieder und zeigt die Verteilung der Zeitanteile an den verschiedenen Lastpunkten.'
            ],
            '2': [
                'The diagram can be viewed in the full-screen mode by clicking on the corresponding icon. You can zoom in by expanding an area of the diagram',
                'Die Grafik kann durch Anklicken des entsprechenden Symbols im Vollbildmodus dargestellt werden. Des Weiteren kann durch Vergrößern des Bereichs innerhalb des Grafen gezoomt werden.'
            ]
        },
        'Help.MotorTechnicalChart.Title': [
            'Help | Motors | Energy Savings',
            'Hilfe | Motoren | Energieeinsparung'
        ],
        'Help.MotorTechnicalMotorProfile.Content': {
            '0': [
                'Under Motor profile you can select Power and Pole number – as well as Line supply, i.e. line voltage, number of phases and line frequency. You can also select a Type of protection.',
                'Unter Motorprofil werden die Größen Leistung und Polzahl sowie das Netz , d.h. Phasenzahl, Netzfrequenz und Netzspannung ausgewählt. Des weiteren kann eine Zündschutzklasse gewählt werden. '
            ],
            '1': [
                'All of the values you define in this step apply to all of the motors involved in the comparison – that is, they serve as reference values and as the basis for the reference and alternative motor calculations.',
                'Alle hier definierten Größen gelten gleichermaßen für alle in den Vergleich einbezogenen Motoren, d.h. sie sind Bezugsgrößen und Berechnungsgrundlage für den Referenz- als auch für den Alternativmotor. '
            ],
            '2': [
                'The following interrelationship applies between the number of poles and the (synchronous) motor speed in rpm.',
                'Zwischen Polzahl und (synchroner) Motordrehzahl gilt folgender Zusammenhang.'
            ],
            '3': [
                'For 50Hz line supplies:',
                'Bei 50Hz-Netzen:'
            ],
            '4': [
                '2pole =&gt; 3000 rpm',
                '2pol =&gt; 3000 1/min'
            ],
            '5': [
                '4pole =&gt; 1500 rpm',
                '4pol =&gt; 1500 1/min'
            ],
            '6': [
                '6pole =&gt; 1000 rpm',
                '6pol =&gt; 1000 1/min'
            ],
            '7': [
                '8pole =&gt; 750 rpm',
                '8pol =&gt;   750 1/min '
            ],
            '8': [
                'For 60Hz line supplies:',
                'Bei 60Hz-Netzen:'
            ],
            '9': [
                '2pole =&gt; 3600 rpm',
                '2pol =&gt; 3600 1/min'
            ],
            '10': [
                '4pole =&gt; 1800 rpm',
                '4pol =&gt; 1800 1/min '
            ],
            '11': [
                '6pole =&gt; 1200 rpm',
                '6pol =&gt; 1200 1/min '
            ],
            '12': [
                '8pole =&gt; 900 rpm',
                '8pol =&gt;   900 1/min '
            ]
        },
        'Help.MotorTechnicalMotorProfile.Title': [
            'Help | Motors | Motor Profile',
            'Hilfe | Motoren | Motorprofil'
        ],
        'Help.MotorTechnicalOperationProfile.Content': {
            '0': [
                ' The sum of the individual values yields the number of operating hours in a typical working day. It is assumed that "quasi steady state" operating modes are involved. This means: Energy, used for example when braking and accelerating, is not considered in the evaluation.',
                ' Die Summe der Einzelwerte ergibt die Betriebsstundenzahl eines typischen Arbeitstages. Dabei wird angenommen, dass es sich um „quasistationäre“ Betriebszustände handelt. D.h. Energieverbräuche, die z.B. durch Bremsen und Beschleunigen hervorgerufen werden, finden in der Betrachtung keine Berücksichtigung.'
            ],
            '1': [
                'The operating profile is shown as a bar chart on the right side of the tool in the energy saving diagram.',
                ' Auf der rechten Seite des Tools, in der Grafik der Energieeinsparung, wird das Betriebsprofil als Balkendiagramm veranschaulicht.'
            ]
        },
        'Help.MotorTechnicalOperationProfile.Title': [
            'Help | Motors | Operation Profile',
            'Hilfe | Motoren | Betriebsprofil'
        ],
        'Help.MotorTechnicalReference.Content': {
            '0': [
                'The reference motor represents the reference variable for the alternative motor(s). You can display both greenfield (new systems) and brownfield (retrofit/replacement in an existing system) scenarios.',
                'Der Referenzmotor stellt die Bezugsgröße für den oder die Alternativmotor(en) dar. Dabei sind sowohl Greenfield- (Neue Anlage) als auch Brownfieldszenarien (Retrofit / Ersatz in bestehender Anlage) abbildbar.'
            ],
            '1': [
                'Greenfield: ',
                'Greenfield:'
            ],
            '2': [
                'Once the basic motor data is defined under Motor profile, you can completely define the reference motor by entering the energy efficiency class (IE1-IE4) and enclosure material. The efficiencies are automatically allocated to the specific SIMOTICS series (e.g. SIMOTICS GP).',
                ' Nachdem unter Motorprofil bereits wesentliche Größen des Motors definiert wurden, wird der Referenzmotor durch die Festlegung von Energieeffizienzklasse (IE1-IE4) und Gehäusematerial vollständig definiert. Wirkungsgrade und Zuordnung zur SIMOTICS Reihe (z.B. SIMOTICS GP) erfolgen automatisch.'
            ],
            '3': [
                'Brownfield: ',
                'Brownfield: '
            ],
            '4': [
                'You can change the reference motor to an existing motor by clicking on the motor icon. Then enter the motor name, enclosure material and efficiency corresponding to the data of the existing motor.',
                'Durch Klicken auf das Motorsymbol lässt sich der Referenzmotor zu einem bereits bestehenden Motor wandeln. Hier sind Motorname, Gehäußematerial und die Wirkungsgrade entsprechend den Daten des vorhandenen Motors einzutragen.'
            ]
        },
        'Help.MotorTechnicalReference.Title': [
            'Help | Motors | Reference Motor',
            'Hilfe | Motoren | Referenzmotor'
        ],
        'Help.MotorTechnicalReferenceResult.Content': {
            '0': [
                'At this point, the energy requirements of the reference motor are identified, taking into account the operating profile – that is, the time slices for the respective load points. The energy consumption of the alternative motor is compared to this value. The energy-saving potential is derived from this difference.',
                'Hier wird der Energiebedarf des Referenzmotors unter Berücksichtigung des Betriebsprofiles, d.h. der Zeitanteile an den jeweiligen Lastpunkten ausgewiesen.'
            ],
            '1': [
                '',
                'Gegen diesen Wert wird der Energieverbrauch des Alternativmotors verglichen. Aus der Differenz ergibt sich das Energieeinsparpotenzial.'
            ]
        },
        'Help.MotorTechnicalReferenceResult.Title': [
            'Help | Motors | Reference Motor',
            'Hilfe | Motoren | Referenzmotor'
        ],
        'Help.MotorVSDSRMTechnical.Content': {
            '0': [
                ' The VSD 4000 line is based on synchronous reluctance technology and embodies the most efficient type of drive system. The highest performance is enabled with minimum energy consumption.  ',
                ' Die VSD 4000-Linie  basiert auf Synchronreluktanz-Technologie und stellt das effizienteste Antriebssystem dar. Hierdurch wird die höchste Leistung bei minimalem Energieverbrauch ermöglicht. '
            ],
            '1': [
                ' The VSD 4000 line is operated exclusively on the converter. The dimensions of the synchronous reluctance motors correspond to those of the standard series. ',
                ' Die VSD-4000-Linie wird ausschließlich am Umrichter betrieben. Die Abmessungen der Synchronreluktanz-Motoren entsprechen dabei denen der Standardreihe. '
            ],
            '2': [
                ' The motor type rating is preselected based on the determined power requirement of the application (pump or fan) at the rated operating point, based on the delivery height and volume flow, while taking into account the application speed. ',
                ' Die Vorauswahl der Typleistung des Motors erfolgt anhand des ermittelten Leistungsbedarfs der Applikation (Lüfter oder Pumpe)  im Nennpunkt aus Förderhöhe und Volumenstrom und unter Berücksichtigung der Applikationsdrehzahl. '
            ],
            '3': [
                ' The motor speed or pole pair number is selected based on the pump speed that was entered. Small deviations between the rated speed of the motor and that of the application are normal. Variable-speed drives can compensate this difference. ',
                ' Die Motordrehzahl bzw. Polpaarzahl wird anhand der eingegebenen Drehzahl gewählt. Dabei kommt es in der Regel zu geringen Abweichungen zwischen der Bemessungsdrehzahl des Motors und der Applikation. Drehzahlveränderbare Antriebe können diese Differenz ausregeln. '
            ]
        },
        'Help.MotorVSDStandardTechnical.Content': {
            '0': [
                ' The motor type rating is preselected based on the determined power requirement of the application (pump or fan) at the rated operating point based on the delivery height or total pressure difference and volume flow, while taking into account the speed. ',
                ' Die Vorauswahl der Typleistung des Motors erfolgt anhand des ermittelten Leistungsbedarfs der Applikation (Pumpe oder Lüfter) im Nennpunkt aus Förderhöhe bzw. Totaldruckdifferenz und Volumenstrom und unter Berücksichtigung der Drehzahl. '
            ],
            '1': [
                ' The motor speed or pole pair number is selected based on the pump or fan speed that was entered. Small deviations between the rated speed of the motor and that of the application are normal. Variable-speed drives can compensate this difference. ',
                ' Die Motordrehzahl bzw. Polpaarzahl wird anhand der eingegebenen Drehzahl (der Pumpe oder des Lüfters) gewählt. Dabei kommt es in der Regel zu geringen Abweichungen zwischen der Bemessungsdrehzahl des Motors und der Applikation. Drehzahlveränderbare Antriebe können diese Differenz ausregeln. '
            ],
            '2': [
                ' If a standard low-voltage motor is operated with a converter, then pricing is taken into account for the KTY84 temperature sensors (motor protection) and insulated bearings (option L51) for shaft heights 280 and 315. ',
                ' Wird ein Niederspannungs-Standardmotor am Umrichter betrieben, so werden Temperatursensoren KTY84 (Motorschutz) und für die Achshöhen 280 und 315 isolierte Lager (Option L51) preislich berücksichtigt. '
            ]
        },
        'Help.NemaExistingPoleChangingMotorTechnical.Content': {
            '0': [
                ' Pole-changing motors have two motor windings and thus two static speeds to choose between. An efficiency can be maintained for each winding which is then used as the basis for calculating the energy consumption. ',
                ' Polumschaltbare Motoren besitzen zwei Motorwicklungen und damit zwei statische Drehzahlen, zwischen welchen gewählt werden kann. Für jede Wicklung kann ein Wirkungsgrad gepflegt werden, der als Grundlage zur Berechnung des Energieverbrauchs genutzt wird. '
            ],
            '1': [
                ' Rated speed 1 is taken over on the basis of the entered application speed (pump or fan). Rated speed 2 corresponds to the speed of the motor with double pole pair number. ',
                ' Die Nenndrehzahl 1 wird anhand  der eingegebenen Applikationsdrehzahl (Pumpe oder Lüfter) übernommen. Die Nenndrehzahl 2 entspricht der Drehzahl des Motors mit doppelter Polpaarzahl.  '
            ]
        },
        'Help.NemaMotorTechnical.Content': {
            '0': [
                ' The motor type rating is preselected based on the determined power requirement of the application (pump or fan) at the rated operating point based on the delivery height or total pressure difference and volume flow, while taking into account the speed. ',
                ' Die Vorauswahl der Typleistung des Motors erfolgt anhand des ermittelten Leistungsbedarfs der Applikation (Pumpe oder Lüfter) im Nennpunkt aus Förderhöhe bzw. Totaldruckdifferenz und Volumenstrom und unter Berücksichtigung der Drehzahl. '
            ],
            '1': [
                ' The motor speed or pole pair number is selected based on the application speed that was entered. Minor deviations between the rated speed of the motor and that of the application are normal. Variable-speed drives can compensate this difference. ',
                ' Die Motordrehzahl bzw. Polpaarzahl wird anhand der eingegebenen Applikationsdrehzahl gewählt. Dabei kommt es in der Regel zu geringen Abweichungen zwischen der Bemessungsdrehzahl des Motors und der Applikation. Drehzahlveränderbare Antriebe können diese Differenz ausregeln. '
            ],
            '2': [
                ' The NEMA Premium and NEMA Premium + energy efficiency classes are available. ',
                ' Es stehen die Energieeffizienzklassen NEMA Premium und NEMA Premium + zur Verfügung.  '
            ]
        },
        'Help.NemaThirdPartyMotorTechnical.Content': {
            '0': [
                ' The required shaft power of the application and the efficiency of the motor help form the basis for calculating the required energy consumption. ',
                ' Die Benötigte Wellenleistung der Applikation und der Wirkungsgrad des Motors bilden unter anderem die Grundlage für die Berechnung des benötigten Energieverbrauchs.  '
            ]
        },
        'Help.Project.Content': {
            '0': [
                'Here, you can change the ',
                'Hier können Sie die '
            ],
            '1': [
                ' header data of the project',
                ' Kopfdaten des Projektes '
            ],
            '2': [
                '. The project name is always in synchronism with the name under which the project is saved. ',
                ' ändern. Der Projektname ist immer synchron mit dem Namen unter dem das Projekt gespeichert wird. '
            ],
            '3': [
                'Your input appears on the cover sheet after exporting or sharing your results. ',
                'Ihre Eingaben erscheinen beim Export bzw. beim Teilen des Projekts auf dem Deckblatt der Datei. '
            ]
        },
        'Help.Project.Title': [
            'Help | Project Data',
            'Hilfe | Projektdaten'
        ],
        'Help.ProjectComparisonList.Content': {
            '0': [
                ' No.',
                ' Nr.'
            ],
            '1': [
                ' Each position corresponds to an individual comparison',
                ' Jede Position entspricht einem Einzelvergleich'
            ],
            '2': [
                ' Active',
                ' Aktiv'
            ],
            '3': [
                ' Using active/inactive you can control whether individual comparisons are taken into account in the overall project (chart and project results on the right)',
                ' Über aktiv / inaktiv kann gesteuert werden, ob Einzelvergleiche im Gesamtprojekt (Grafik und Projektergebnisse rechts) berücksichtigt werden'
            ],
            '4': [
                ' Comparison /selection ',
                ' Vergleich / Auswahl '
            ],
            '5': [
                'The name of the comparison can be changed by clicking here ',
                'Der Name des Vergleiches lässt sich hier durch Klicken verändern '
            ],
            '6': [
                ' The selection indicates which system and/or which motor is taken into consideration in the alternative concept being considered. A selection must be made here if there are several alternative motors',
                ' Die Auswahl gibt wieder, welches System bzw. welcher Motor im betrachteten Alternativkonzept berücksichtigt wird. Bei mehreren Alternativmotoren muss hier eine Auswahl getroffen werden'
            ],
            '7': [
                ' Quantity ',
                ' Anzahl '
            ],
            '8': [
                ' Specifies how often the individual comparisons should be taken into account in the overall result of the project ',
                ' Gibt an wie oft der Einzelvergleich im Gesamtergebnis des Projekts berücksichtigt werden soll '
            ],
            '9': [
                'Results',
                'Ergebnisse'
            ],
            '10': [
                ' Correspond to the main results of each individual comparison',
                ' Entsprechen den Hauptergebnissen eines jeden Einzelvergleichs'
            ],
            '11': [
                ' Actions',
                ' Aktionen'
            ],
            '12': [
                ' Open, share, export or delete the project or an individual comparison  ',
                ' Öffnen, teilen, exportieren oder löschen des Projekts bzw. eines Einzelvergleiches  '
            ]
        },
        'Help.ProjectComparisonList.Title': [
            'Help | List of Single Comparisons',
            'Hilfe | Liste der Einzelvergleiche'
        ],
        'Help.ProjectSettings.Content': {
            '0': [
                'The ',
                'Die '
            ],
            '1': [
                'project settings ',
                'Projekteinstellungen '
            ],
            '2': [
                ' are obtained from the "Personal default values". When required, you can change this here for an individual project.',
                ' werden von den "Persönlichen Voreinstellungen" vererbt. Bei Bedarf können Sie diese für ein einzelnes Projekt hier verändern.'
            ],
            '3': [
                'The energy price and the CO',
                'Der Energiepreis und der CO'
            ],
            '4': [
                '2',
                '2'
            ],
            '5': [
                ' factor are the basis for calculating the energy saving potential. ',
                '-Faktor sind die Basis für die Berechnung der Einsparpotentiale. '
            ]
        },
        'Help.ProjectSettings.Title': [
            'Help | Project Settings',
            'Hilfe | Projekteinstellungen'
        ],
        'Help.ProjectTcoChart.Content': {
            '0': [
                'The two graphs show the ',
                'Die beiden Graphen geben den zu erwarteten '
            ],
            '1': [
                'total cost trend to be expected',
                ' Verlauf der Gesamtkosten '
            ],
            '2': [
                ' over the operating time of both concepts. ',
                ' der beiden Konzepte über der Laufzeit wieder. '
            ],
            '3': [
                'The initial value at operating time = 0 corresponds to the',
                'Der Anfangswert bei Laufzeit = 0 entspricht der '
            ],
            '4': [
                ' capital investment required',
                ' erforderlichen Investition'
            ],
            '5': [
                ' for the particular concept. ',
                ' des jeweiligen Konzepts '
            ],
            '6': [
                'The ',
                'Die '
            ],
            '7': [
                'break-even point',
                ' erwartete Amortisationszeit '
            ],
            '8': [
                ' is where the two lines intersect. ',
                ' entspricht dem Schnittpunkt der beiden Graphen. '
            ]
        },
        'Help.ProjectTcoChart.Title': [
            'Help | Total Costs Of Ownership Of The Project',
            'Hilfe | Lebenszykluskosten des Projekts'
        ],
        'Help.PumpCommercialControlMode.Content': {
            '0': [
                'The control mode cannot be changed in the commercial view. This is only possible in the technical view.',
                'In der '
            ],
            '1': [
                'Throttle control',
                'Kommerziellen Sicht'
            ],
            '2': [
                'The flow is reduced by increasing the flow resistance in the system.',
                ' kann die Regelungsart nicht geändert werden. Dies ist nur in der '
            ],
            '3': [
                'Bypass control',
                'Technischen Sicht'
            ],
            '4': [
                'The flow through the bypass is increased – and the effective flow rate for the process itself is reduced.',
                ' möglich.'
            ],
            '5': [
                ' Closed-loop speed control',
                'Drosselregelung'
            ],
            '6': [
                'The pump speed is controlled according to the flow rate currently required in the process.',
                'Durch Erhöhung des Anlagenwiderstandes wird  der Durchfluss reduziert'
            ],
            '7': [
                '',
                'Bypassregelung'
            ],
            '8': [
                '',
                'Der Bypassförderstrom wird erhöht, der effektive Volumenstrom am Prozess wird dadurch reduziert'
            ],
            '9': [
                '',
                'Drehzahlregelung'
            ],
            '10': [
                '',
                'Regelung der der Pumendrehzahl gemäß des aktuell im Prozess benötigten Volumenstromes'
            ]
        },
        'Help.PumpCommercialControlMode.Title': [
            'Help | Pump | Control Mode',
            'Hilfe | Pumpe | Regelungsart'
        ],
        'Help.PumpLoadPoint.Content': {
            '0': [
                ' Required shaft power ',
                ' Benötigte Wellenleistung '
            ],
            '1': [
                ' \t\t\tCorresponds to the mechanical shaft power requirement of the pump',
                ' Entspricht der mechanischen Wellenleistungsanforderung der Pumpe'
            ],
            '2': [
                ' Pump speed ',
                ' Drehzahl der Pumpe '
            ],
            '3': [
                ' Pump speed according to the specifications on the nameplate. It is used to preselect the motor ',
                ' Drehzahl der Pumpe entsprechend der Angaben des Typenschildes. Sie dient zur Vorauswahl des Motors '
            ],
            '4': [
                ' Operating hours / year ',
                ' Betriebsstunden / Jahr '
            ],
            '5': [
                ' Total annual operating time of the system in hours. It is used to calculate the expected energy demand ',
                ' Jährliche Gesamtlaufzeit der Anlage in Stunden. Sie dient zur Berechnung des voraussichtlichen Energiebedarfs '
            ],
            '6': [
                ' Allocation ',
                ' Verteilung '
            ],
            '7': [
                ' Distribution of the operating hours of a typical working day over the flow rate percentage ',
                ' Verteilung der Betriebsstunden eines typischen Arbeitstages über die prozentuale Förderrate '
            ]
        },
        'Help.PumpLoadPoint.Title': [
            'Help | Load point and operation profile',
            'Hilfe | Lastpunkt und Betriebsprofil'
        ],
        'Help.PumpTechnicalApplication.Content': {
            '0': [
                'Centrifugal pump name',
                'Bezeichnung der Kreiselpumpe'
            ],
            '1': [
                ' By allocating a name, the pump technical data can be saved (login required) – and can be subsequently called up under the same name \t\t\t',
                ' Durch Vergabe eines Namens können die technischen Daten der Pumpe abgespeichert (Login erforderlich) und unter dem selben Namen später wieder aufgerufen werden \t\t\t'
            ],
            '2': [
                'Pump head',
                'Förderhöhe'
            ],
            '3': [
                ' This corresponds to the maximum pump head under ideal supplementary conditions (rating plate / data sheet of the pump). The value must be higher than the static pump head ',
                ' Diese entspricht der max. Förderhöhe der Pumpe unter idealen Rahmenbedingungen (Typenschild / Datenblatt der Pumpe). Der Wert muss größer sein als die statische Förderhöhe '
            ],
            '4': [
                ' Static pump head ',
                ' Statische Förderhöhe '
            ],
            '5': [
                ' Represents the back pressure in the system that results, for example, because of a geodetic height difference between the water level on the pressure-side and the suction-side.',
                ' Stellt den Gegendruck im System dar, der z.B. aufgrund eines geodätischen Höhenunterschiedes zwischen druckseitigem und saugseitigem Wasserspiegel zustande kommt'
            ],
            '6': [
                'Nominal flow rate',
                'Nennförderstrom'
            ],
            '7': [
                ' Flow rate for which the pump has been designed \t\t\t',
                ' Förderstrom für den die Pumpe ausgelegt ist \t\t\t'
            ],
            '8': [
                ' Pump speed ',
                ' Drehzahl der Pumpe '
            ],
            '9': [
                ' Pump speed corresponding to what is stamped on the rating plate. This is used to preselect the motor ',
                ' Drehzahl der Pumpe entsprechend der Angaben des Typenschildes. Sie dient zur Vorauswahl des Motors '
            ],
            '10': [
                ' Number of pump stages ',
                ' Anzahl der Pumpenstufen '
            ],
            '11': [
                ' Selects the number of pump stages corresponding to the pump being used (data sheet). The number of stages significantly influences the specific speed. ',
                ' Auswahl der  Pumpenstufen entsprechend der zu verwendenden Pumpe (Datenblatt). Die Stufenzahl beeinflusst maßgeblich die spezifische Drehzahl '
            ],
            '12': [
                ' Specific speed',
                ' Spezifische Drehzahl'
            ],
            '13': [
                ' Parameter that characterizes fluid-flow machines. Values between 8 and 500 are permitted. The laws applying to fluid-flow machines do not apply to pumps outside this value range. \t\t\t',
                ' Kennzahl zur Charakterisierung von Strömungsmaschinen. Es sind Werte zwischen 8 und 500 zulässig. Für Pumpen außerhalb dieses Wertefensters gelten die Gesetze der Strömungsmaschinen nicht \t\t\t'
            ],
            '14': [
                ' Flow rate ',
                ' Förderrate '
            ],
            '15': [
                ' Volume to be pumped depending on the nominal pump flow rate \t\t\t',
                ' Zu förderndes Volumen in Abhängigkeit des Nennfördervolumens der Pumpe \t\t\t'
            ],
            '16': [
                'Efficiency',
                'Wirkungsgrad'
            ],
            '17': [
                'Efficiency at the nominal pump operating point \t\t\t',
                'Wirkungsgrad am Bemessungspunkt der Pumpe \t\t\t'
            ],
            '18': [
                ' Medium ',
                ' Medium '
            ],
            '19': [
                ' Material that is to be pumped. Media can be saved in the tool by specifying the name and the associated specific density – and subsequently called up (a login is required) \t\t\t',
                ' Stoff, der gefördert wird. Medien können durch Angabe eines Namens und zugehöriger Dichte im Tool gespeichert und wieder aufgerufen werden (Login erforderlich) \t\t\t'
            ],
            '20': [
                ' Specific density ',
                ' Dichte '
            ],
            '21': [
                ' Specific density of the medium to be pumped \t\t\t',
                ' Dichte des zu fördernden Mediums \t\t\t'
            ]
        },
        'Help.PumpTechnicalApplication.Title': [
            'Help | Pump',
            'Hilfe | Pumpe'
        ],
        'Help.PumpTechnicalControlMode.Content': {
            '0': [
                ' Throttle control',
                ' Drosselregelung '
            ],
            '1': [
                ' The flow is reduced by increasing the flow resistance in the system',
                ' Durch Erhöhung des Anlagenwiderstandes wird  der Durchfluss reduziert '
            ],
            '2': [
                ' Bypass control',
                ' Bypassregelung '
            ],
            '3': [
                ' The flow through the bypass is increased – and the effective flow rate for the process itself is reduced',
                ' Der Bypassförderstrom wird erhöht, der effektive Volumenstrom am Prozess wird dadurch reduziert '
            ],
            '4': [
                ' Closed-loop speed control',
                ' Drehzahlregelung  '
            ],
            '5': [
                ' The pump speed is controlled according to the flow rate currently required in the process ',
                ' Regelung der Pumpendrehzahl gemäß des aktuell im Prozess benötigten Volumenstromes '
            ]
        },
        'Help.PumpTechnicalControlMode.Title': [
            'Help | Pump | Control Mode',
            'Hilfe | Pumpe | Regelungsart'
        ],
        'Help.PumpTechnicalOperationProfile.Content': {
            '0': [
                'The operating profile is shown as a bar chart on the right side of the tool in the Energy savings diagram.',
                ' Die Summe der Einzelwerte ergibt die Betriebsstundenzahl eines typischen Arbeitstages. Dabei wird angenommen, dass es sich um „quasistationäre“ Betriebszustände handelt. D.h. der Energieverbrauch, der z.B. durch Bremsen und Beschleunigen hervorgerufen wird, findet in der Betrachtung keine Berücksichtigung.'
            ],
            '1': [
                '',
                ' Auf der rechten Seite des Tools in der Ergebnisgraphik wird das Betriebsprofil als Balkendiagramm veranschaulicht.'
            ]
        },
        'Help.PumpTechnicalOperationProfile.Title': [
            'Help | Pump | Operation Profile',
            'Hilfe | Pumpe | Betriebsprofil'
        ],
        'Help.SwitchgearCommercial.Content': {
            '0': [
                'In the commercial view, the type of switching device (SIRIUS motor starter, SIRIUS soft starting, SIRIUS star-delta combination or an existing switching device) cannot be changed. This is only possible in the technical view.',
                'In der '
            ],
            '1': [
                ' The list price of the product is displayed if the selected currency is €. If a SIRIUS star-delta combination  was selected, the corresponding overload relay is taken into account in the list price shown.The investment costs and the payback time are calculated based on the customer price. This can be directly entered – or is determined by entering a discount factor based on the list price.',
                'Kommerziellen Sicht'
            ],
            '2': [
                ' If one of the other listed currencies is selected, then only the Product price field is displayed. The actual customer price must be individually entered into this field.',
                ' kann der Typ des Schaltgerätes (SIRIUS Motorstarter, SIRIUS Sanftstarter, SIRIUS Stern-Dreieck-Kombination oder ein besthendes Schaltgerät) nicht geändert werden. Dies ist nur in der '
            ],
            '3': [
                '',
                'Technischen Sicht'
            ],
            '4': [
                '',
                ' möglich.'
            ],
            '5': [
                '',
                'Ist die gewählte Währung '
            ],
            '6': [
                '',
                '€ '
            ],
            '7': [
                '',
                ', wird der Listenpreis des Produktes angezeigt. Wurde eine '
            ],
            '8': [
                '',
                'SIRIUS Stern-Dreieck-Kombination'
            ],
            '9': [
                '',
                ' gewählt wird im dargestellten Listenpreis das entsprechende '
            ],
            '10': [
                '',
                'Überlastrelais'
            ],
            '11': [
                '',
                ' berücksichtigt. Zur Berechnung '
            ],
            '12': [
                '',
                'Investitionskosten'
            ],
            '13': [
                '',
                ' und der '
            ],
            '14': [
                '',
                'Amortisation'
            ],
            '15': [
                '',
                ' wird der Kundenpreis zugrunde gelegt. Dieser kann direkt eingegeben werden oder wird durch Eingabe eines Rabatfaktors auf Basis des Listenpreises ermittelt.'
            ],
            '16': [
                '',
                'Wurde eine der anderen angebotenen Währungen gewählt, kommt nur das Feld '
            ],
            '17': [
                '',
                ' Produktpreis'
            ],
            '18': [
                '',
                ' zur Anzeige. Der tatsächliche Kundenpreis muss in dieses Feld individuell eingegebene werden.'
            ]
        },
        'Help.SwitchgearCommercial.Title': [
            'Help | Switchgear',
            'Hilfe | Schaltgerät'
        ],
        'Help.SwitchgearTechnical.Content': {
            '0': [
                'The preselection of the type rating of the switching device is oriented to the type rating of the selected motor.',
                'Die Vorauswahl der Typleistung des Schaltgerätes orientiert sich an der Typleistung des ausgewählten Motors.'
            ],
            '1': [
                'The preselection can be overridden and individual entries made.',
                ' Die Vorauswahl kann übersteuert und an individuelle Vorgaben angepasst werden.'
            ],
            '2': [
                'When using IE3 and IE4 motors, observe the changed electrical secondary conditions for the switching devices. SIRIUS switching devices are optimized for use with motors such as these. They are IE3-/IE4-ready. More information:',
                ' Beachten Sie beim Einsatz von IE3- und IE4-Motoren die veränderten elektrischen Rahmenbedingungen für Schaltgeräte. SIRIUS-Schaltgeräte sind für den Einsatz an solchen Motoren optimiert. Sie sind IE3-/IE4-ready. Weitere Informationen:'
            ],
            '3': [
                'Switch and protect with SIRIUS and be IE3/IE4-ready ',
                'Mit SIRIUS : IE3/IE4- ready schalten und schützen'
            ],
            '4': [
                'The following can be selected:',
                'Zur Auswahl stehen:'
            ],
            '5': [
                'Motor starters',
                'Motorstarter'
            ],
            '6': [
                'Soft starters',
                'Sanftstarter'
            ],
            '7': [
                'Star-delta combination',
                'Stern-Dreieck-Kombination'
            ]
        },
        'Help.SwitchgearTechnical.Title': [
            'Help | Switchgear',
            'Hilfe | Schaltgerät'
        ],
        'Help.TCO.Content': {
            '0': [
                'The two graphs show the ',
                'Die beiden Graphen geben den '
            ],
            '1': [
                'total cost trend to be expected',
                'zu erwartenden Verlauf der Gesamtkosten '
            ],
            '2': [
                ' over the operating time of both systems. ',
                '  über der Laufzeit beider Systeme wieder. '
            ],
            '3': [
                'The initial value at operating time = 0 corresponds to the',
                'Der Anfangswert  bei Laufzeit = 0 entspricht dem Wert  '
            ],
            '4': [
                ' investment ',
                ' Investition '
            ],
            '5': [
                'value for the particular system. ',
                ' des jeweiligen Systems. '
            ],
            '6': [
                'The break-even point is where the two lines intersect.',
                'Der Amortisationszeitpunkt ist der Schnittpunkt der beiden Graphen.'
            ]
        },
        'Help.TCO.Title': [
            'Help | Total costs of Ownership',
            'Hilfe | Lebenszykluskosten (TCO)'
        ],
        'Help.ThirdPartyMotorTechnical.Content': {
            '0': [
                ' The ',
                ' Die '
            ],
            '1': [
                'required shaft power',
                'Benötigte Wellenleistung'
            ],
            '2': [
                ' of the application and the efficiency of the motor help form the basis for calculating the required energy consumption. ',
                ' der Applikation und der Wirkungsgrad des Motors bilden unter anderem die Grundlage für die Berechnung des benötigten Energieverbrauchs.  '
            ]
        },
        'HighVoltage.ModalText': {
            '0': [
                'If you want to reach High / Medium Voltage Systems please contact: ',
                'Wenn Sie Hoch-/Mittelspannungssysteme nutzen möchten, wenden Sie sich bitte an: '
            ],
            '1': [
                'sinasave.industry@siemens.com ',
                'sinasave.industry@siemens.com '
            ],
            '2': [
                'HV /MV requests additional permission.',
                'HV/MV benötigt eine Berechtigung.'
            ]
        },
        'HighVoltage.ModalTitle': [
            'Access to high-voltage comparisons is restricted!',
            'Hochspannungsvergleiche erfordern eine Berechtigung!'
        ],
        'Home.FanSystems': [
            'Fan Systems',
            'Antriebssysteme für Lüfter'
        ],
        'Home.LoginBenefits': {
            '0': [
                'Benefits of a SinaSave login include:',
                'Nutzen Sie die Vorteile des Logins in SinaSave:'
            ],
            '1': [
                'Saving and loading projects and comparisons online',
                'Projekte und Vergleiche online speichern und von jedem Gerät laden'
            ],
            '2': [
                'Defining your own profiles',
                'Festlegen persönlicher Voreinstellungen&nbsp;'
            ],
            '3': [
                'Personal default values',
                'Projektansicht mit der Gesamtauswertung zugehöriger Vergleiche'
            ],
            '4': [
                'Project view with all your comparisons',
                'Exportieren und Teilen von Projekten und Vergleichen'
            ],
            '5': [
                'Exporting and sharing projects and comparisons',
                ''
            ]
        },
        'Home.Motors': [
            'Motors',
            'Motoren'
        ],
        'Home.PumpSystems': [
            'Pump Systems',
            'Antriebssysteme für Pumpen'
        ],
        'Import.Error.BothIEClassAndEfficiencyValsNotAllowed': [
            'Either IE class or efficiency values are required, not both!',
            'Es sind entweder IE-Klassen- oder Effizienzwerte erforderlich, nicht beides!'
        ],
        'Import.Error.CastingInvalid': [
            'Casting is invalid!',
            'Gehäusematerial ungültig!'
        ],
        'Import.Error.CastingRequired': [
            'Casting is required!',
            'Gehäusematerial Erforderlich!'
        ],
        'Import.Error.ComparisonNameRequired': [
            'Comparison name is required!',
            'Name des Vergleiches erforderlich'
        ],
        'Import.Error.DiscountFormatInvalid': [
            'Discount format is invalid!',
            'Ungültiges Format für Rabatt!'
        ],
        'Import.Error.DiscountNotInRange': [
            'Discount must be between 0.1 and 100!',
            'Der Rabatt muss zwischen 0,1 % und 100 % liegen!'
        ],
        'Import.Error.DuplicateNames': [
            'The following comparison names are already used in the project',
            'Die folgenden Vergleichsnamen werden im Projekt bereits verwendet'
        ],
        'Import.Error.DuplicateNamesInTemplate': [
            'The following comparison names are used more than once in the template file',
            'Die folgenden Vergleichsnamen werden in der Datei mehrfach verwendet'
        ],
        'Import.Error.Efficiency100FormatInvalid': [
            'Format of efficiency at 4/4 load is invalid!',
            'Ungültiges Format für Wirkungsgrad bei 4/4 Last!'
        ],
        'Import.Error.Efficiency100NotInRange': [
            'Efficiency at 100% load must be between 0.1 and 100!',
            'Der Wirkungsgrad bei 100 % Last muss zwischen 0,1 % und 100 % liegen!'
        ],
        'Import.Error.Efficiency50FormatInvalid': [
            'Format of efficiency at 2/4 load is invalid!',
            'Ungültiges Format für Wirkungsgrad bei 2/4 Last!'
        ],
        'Import.Error.Efficiency50NotInRange': [
            'Efficiency at 50% load must be between 0.1 and 100!',
            'Der Wirkungsgrad bei 50 % Last muss zwischen 0,1 % und 100 % liegen!'
        ],
        'Import.Error.Efficiency75FormatInvalid': [
            'Format of efficiency at 3/4 load is invalid!',
            'Ungültiges Format für Wirkungsgrad bei 3/4 Last!'
        ],
        'Import.Error.Efficiency75NotInRange': [
            'Efficiency at 75% load must be between 0.1 and 100!',
            'Der Wirkungsgrad bei 75 % Last muss zwischen 0,1 % und 100 % liegen!'
        ],
        'Import.Error.EfficiencyClassInvalid': [
            'Efficiency class is invalid, accepted values are: IE1, IE2, IE3.',
            'Die Effizienzklasse ist ungültig. Akzeptierte Werte sind: IE1, IE2, IE3.'
        ],
        'Import.Error.IEClassOrEfficiencyValsRequired': [
            'IE class or efficiency values are required!',
            'IE-Klasse oder Effizienzwerte sind erforderlich!'
        ],
        'Import.Error.ImportFileEmpty': [
            'Import file is empty!',
            'Die Importdatei ist leer!'
        ],
        'Import.Error.InvalidPoleNumber': [
            'Pole number is invalid!',
            'Polzahl ist ungültig!'
        ],
        'Import.Error.MaximumComparisonCountReached': [
            'Maximum comparison count reached',
            'Maximale Vergleichsanzahl erreicht'
        ],
        'Import.Error.MotorNameRequired': [
            'Motor name is required!',
            'Motorname ist erforderlich!'
        ],
        'Import.Error.MotorNameTooLong': [
            "Motor name's maximum length is 30 characters!",
            ''
        ],
        'Import.Error.MotorPowerFormatInvalid': [
            'Motor power format is invalid!',
            'Ungültiges Format für Motorleistung!'
        ],
        'Import.Error.MotorPowerRequired': [
            'Motor power is required!',
            'Motorleistung ist erforderlich!'
        ],
        'Import.Error.NoSuitableEffMotor': [
            "Didn't find suitable eff motor for the given IE class, maybe try manually setting the efficiency values",
            'Für die angegebene IE-Klasse wurde kein passender EFF-Motor gefunden. Versuchen Sie , die Effizienzwerte manuell einzustellen.'
        ],
        'Import.Error.NoSuitableSiemensMotor': [
            'Could not find suitable Siemens motor!',
            'Wir Konnte keinen passenden INNOMOTICS-Motor finden!'
        ],
        'Import.Error.OperatingDaysPerYearFormatInvalid': [
            'Operating days/year format is invalid!',
            'Ungültiges Format für Betriebstage/Jahr!'
        ],
        'Import.Error.OperatingDaysPerYearNotInRange': [
            'Operating days/year must be between 1 and 365!',
            'Betriebstage/Jahr müssen zwischen 1 und 365 liegen!'
        ],
        'Import.Error.OperatingDaysPerYearRequired': [
            'Operating days/year is required!',
            'Betriebstage/Jahr erforderlich!'
        ],
        'Import.Error.OperatingHours100FormatInvalid': [
            'Number format of operating hours at 4/4 load is invalid!',
            'Ungültiges Format für Betriebsstunden bei 4/4 Last!'
        ],
        'Import.Error.OperatingHours50FormatInvalid': [
            'Number format of operating hours at 2/4 load is invalid!',
            'Ungültiges Format für Betriebsstunden bei 2/4 Last!'
        ],
        'Import.Error.OperatingHours75FormatInvalid': [
            'Number format of operating hours at 3/4 load is invalid!',
            'Ungültiges Format für Betriebsstunden bei 3/4 Last!'
        ],
        'Import.Error.OperatingHoursRequired': [
            'Operating hours are required!',
            'Betriebsstunden sind Erforderlich!'
        ],
        'Import.Error.OperatingHoursSumNotInRange': [
            'The sum of operating hours/day values must be between 0.1 and 24!',
            'Die Summe der Betriebsstunden-/Tageswerte muss zwischen 0,1 h und 24 h liegen!'
        ],
        'Import.Error.ParsingErrorMailSubject': [
            'Errors during motor import ({{project_name}})',
            'Fehler beim Motorimport ({{project_name}})'
        ],
        'Import.Error.ParsingErrorMailText': [
            'Motors could not be imported for project "{{project_name}}". The following errors were found in your import file:',
            'Für das Projekt "{{project_name}}" konnten keine Motoren importiert werden. In Ihrer Importdatei wurden folgende Fehler gefunden:'
        ],
        'Import.Error.PoleNumberFormatInvalid': [
            'Pole number format is invalid!',
            'Ungültiges Format für Polzahl!'
        ],
        'Import.Error.PoleNumberRequired': [
            'Pole number is required!',
            'Polzahl ist erforderlich!'
        ],
        'Import.Error.QuantityFormatInvalid': [
            'Quantity format is invalid!',
            'Ungültiges Format für Anzahl!'
        ],
        'Import.Error.QuantityNotInRange': [
            'Quantity must be between 1 and 99!',
            'Der Wert für Anzahl muss zwischen 1 und 99 liegen!'
        ],
        'Import.Error.VersionMismatch': [
            'Unable to determine template version',
            'Die Version des Templates konnte nicht ermittelt werden'
        ],
        'Import.Error.VoltageFormatInvalid': [
            'Voltage format is invalid!',
            'Ungültiges Format für Spannung!'
        ],
        'Import.Error.VoltageRequired': [
            'Voltage is required!',
            'Spannung ist erforderlich'
        ],
        'Import.MotorImportFailedRow': [
            'Row {{row_number}}, comparison name "{{comparison_name}}": {{error_message}}',
            'Zeile {{row_number}},Vergleich "{{comparison_name}}": {{error_message}}'
        ],
        'Import.ProgressBar.Label': [
            'Import Progress',
            'Import Fortschritt'
        ],
        'Import.Row': [
            'Row',
            'Zeile'
        ],
        'Import.Status.Failed': [
            'Import failed',
            'Import fehlgeschlagen'
        ],
        'Import.Status.ImportFinished': [
            'The import process for the project "{{project_name}}" completed successfully.',
            'Der Importvorgang für das Projekt "{{project_name}}" wurde erfolgreich abgeschlossen.'
        ],
        'Import.Status.ImportStarting': [
            'Import is starting',
            'Import startet'
        ],
        'Import.Status.Interrupted': [
            'Interruption was detected. Continuing import for project "{{project_name}}".',
            'Es wurde eine Unterbrechung erkannt. Der Import für das Projekt „{{project_name}}“ wird fortgesetzt.'
        ],
        'Import.Status.ParsingStarting': [
            'Parsing is starting',
            'Parsing startet'
        ],
        'Import.Status.QualityCheckFailed': [
            'Quality check failed, check your email for details.',
            'Die Qualitätsprüfung ist fehlgeschlagen. Bitte überprüfen Sie Ihre E-Mails für weitere Details.'
        ],
        'Import.Status.SheetMissing': [
            'Sheet named "Motor import" is missing from the template file',
            'Das Blatt mit dem Namen „Motorimport“ fehlt im Template'
        ],
        'Import.Status.TemplateAndFieldsChecked': [
            'Template and mandatory fields are checked',
            'Template und Pflichtfelder werden überprüft'
        ],
        'Import.Status.TemplateUploadFailed': [
            'Template upload failed',
            'Import der Vorlage gescheitert'
        ],
        'Import.Status.UnknownErrorHappened': [
            'Unknown error happened during mass import.',
            'Ein unbekannter Fehler ist während des Imports aufgetreten.'
        ],
        'Import.Status.Waiting': [
            'Waiting for comparison creation',
            'Warten auf Erzeugung der Vergleiche'
        ],
        'India.BackToStartPage': [
            'Back to Start Page',
            'Zurück zur Startseite'
        ],
        'India.co2factor.link': {
            '0': [
                'The average CO2-emission factor for country ',
                ''
            ],
            '1': [
                'India in the year 2021 was 726.1 g/kWh',
                ''
            ],
            '2': [
                ' as per IEA 2021 report',
                ''
            ]
        },
        'India.NewReqModalMessageMotor': [
            'Do You want calculate for a replacement of an existing motor (Retrofit) or do you want calculate for a from scratch new installation (New Requirement)?',
            'Möchten Sie den Austausch eines vorhandenen Motors (Retrofit) oder eine völlig neue Installation (Neuanforderung) kalkulieren?'
        ],
        'India.NewReqModalMessageSystem': [
            'Would you like to calculate the replacement of an existing pump or fan application (retrofit) or would you like to calculate a completely new application (new requirement)?',
            'Möchten Sie den Austausch einer bestehenden Pumpen- oder Lüfteranwendung kalkulieren (Retrofit) oder möchten Sie eine komplett neue Anwendung berechnen (Neue Anforderung)?'
        ],
        'India.NewReqModalTitle': [
            'New requirement or retrofit?',
            'Neue Anforderung oder Retrofit?'
        ],
        'India.NewRequirement': [
            'New Requirement',
            'Neue Anforderung'
        ],
        'India.Retrofit': [
            'Retrofit',
            ''
        ],
        'India.RetrofitModalMessageMotor': [
            'This functionality is under development right now.',
            'Diese Funktionalität befindet sich derzeit in der Entwicklung.'
        ],
        'India.RetrofitModalTitle': [
            'Retrofit is under development',
            'Befindet sich in Entwicklung'
        ],
        'ListImport.CreateComparisons': [
            'Create comparisons',
            'Vergleiche erzeugen'
        ],
        'ListImport.DragNDropExplanation': [
            'Drag and drop a file here or click to browse file',
            'Ziehen Sie die Importdatei hierher oder klicken Sie, um zur Datei zu navigieren.'
        ],
        'ListImport.Error': [
            'Errors have been found in the import file. Please check the following txt-file or your email for further details.',
            'In der Importdatei wurden Fehler gefunden. Bitte überprüfen Sie die folgende txt-Datei oder Ihre E-Mail für weitere Details.'
        ],
        'ListImport.FileSelection': [
            'List import - file selection',
            'Listen import - Datei wählen'
        ],
        'ListImport.FileUpload': [
            'List import - file upload',
            'Listen import - Datei Importieren'
        ],
        'ListImport.FileUploadExplanation': {
            '0': [
                'The quality check of the list import was successful. ',
                'Die Qualitätsprüfung des Listenimports war erfolgreich. '
            ],
            '1': [
                'The creation of the comparisons can now be triggered. Depending on the scope, this process may take some time. The status can be seen in the project overview. ',
                'Die Erzeugung der Vergleiche kann nun angestoßen werden. Je nach Umfang kann dieser Prozess etwas Zeit in Anspruch nehmen. Der Status ist in der Projektübersicht ersichtlich. '
            ],
            '2': [
                'The project cannot be opened until all comparisons have been created.',
                'Bis alle Vergleiche erstellt sind kann das Projekt nicht geöffnet werden.'
            ]
        },
        'ListImport.IncorrectVersion': [
            'Use of an incorrect Exel template version',
            'Sie Verwendung eine ungültige Datei (Version).'
        ],
        'ListImport.QualityCheck': [
            'List import - quality check',
            'Listen import - Qualitäts check'
        ],
        'ListImport.TemplateDescription': [
            'For the import via Excel list please use the current version of the template. You can download these here:',
            'Für den Import per Excel-Liste verwenden Sie bitte die aktuelle Version der Vorlage. Diese können Sie hier herunterladen:'
        ],
        'ListImport.TemplateDownload': [
            'List import - template download',
            'Listen import - Template herunterladen'
        ],
        'ListImport.TooManyComparisons': [
            'The maximum number of comparisons per project is 1000. Try with fewer comparisons or create a new project.',
            'Die maximale Anzahl an Vergleichen pro Projekt beträgt 1000. Versuchen Sie es mit weniger Vergleichen oder erstellen Sie ein neues Projekt'
        ],
        'ListImport.Upload': [
            'Upload',
            'Importieren'
        ],
        'Login.MyDashboard': [
            'Personal default settings',
            'Persönliche Voreinstellungen'
        ],
        'Mail.Attachment': [
            'Attachment',
            'Diesen Vergleich / dieses Projekt als Datei-Download teilen'
        ],
        'Mail.AttachmentDescription': [
            'Share your project by sending it as email attachment',
            'Senden Sie die Ergebnisse dieses Vergleiches oder dieses Projektes als Mail-Anhang an eine andere Person. Bitte wählen Sie das Dateiformat: '
        ],
        'Mail.DirectMessage': {
            '0': [
                'Dear Sir or Madam,',
                'Sehr geehrte Damen und Herren,'
            ],
            '1': [
                '{{username}} would like to share an energy efficiency calculation with you. ',
                '{{username}} hat Ihnen eine Kopie eines SinaSave-Energieeffizienz-Projektes zur Verfügung gestellt.'
            ],
            '2': [
                'The calculation project is made available to you directly in the SinaSave application.',
                '&nbsp;'
            ],
            '3': [
                'Please go to',
                'Sie finden die Kopie in Ihrem SinaSave-Postfach. Bitte gehen Sie zu '
            ],
            '4': [
                ' www.sinasave.siemens.com',
                'www.sinasave.siemens.com'
            ],
            '5': [
                ' and login',
                ' und'
            ],
            '6': [
                'With kind regards,',
                ' loggen Sich mit dieser E-Mailadresse'
            ],
            '7': [
                '',
                ' ein. '
            ],
            '8': [
                '',
                'Das Projekt steht Ihnen 6 Wochen zur Annahme im Postfach zur Verfügung. Wird es in dieser Zeit nicht angenommen, wird es automatisch gelöscht.'
            ],
            '9': [
                '',
                '&nbsp;&nbsp;'
            ],
            '10': [
                '',
                'Mit besten Grüßen'
            ],
            '11': [
                '',
                'Ihr SinaSave - Team'
            ]
        },
        'Mail.DirectMessageRegistered': {
            '0': [
                'Dear Sir or Madam,',
                'Sehr geehrte Damen und Herren,'
            ],
            '1': [
                'Sender (',
                'der Absender('
            ],
            '2': [
                '{{senderMail}}',
                ' {{senderMail}}'
            ],
            '3': [
                ') would like to share an energy efficiency calculation with you.',
                ') möchte eine Energieeffizienzberechnung mit Ihnen teilen.'
            ],
            '4': [
                'The calculation project is made available to you directly in the Webapplication SinaSave. Choose Accept to add this project to your own SinaSave-project list. Decline deletes the project and removes it from your SinaSave mailbox. Both actions require a SinaSave login.',
                'Das Berechnungsprojekt wird Ihnen direkt im webtool SinaSave zur Verfügung gestellt. Wählen Sie „Akzeptieren“, um dieses Projekt zu Ihrer eigenen SinaSave-Projektliste hinzuzufügen. Ablehnen löscht das Projekt und Entfernt es aus Ihrem SinaSave-Postfach. Für beide Aktionen ist ein SinaSave-Login erforderlich.'
            ],
            '5': [
                'With kind regards,',
                'Mit Freundlichen Grüßen'
            ]
        },
        'Mail.DirectMessageTable': {
            '0': [
                'Project',
                'Projekt'
            ],
            '1': [
                'From',
                'Von'
            ],
            '2': [
                'Shared at',
                'Geteilt am'
            ],
            '3': [
                'Availabe until',
                'Verfügbar für'
            ],
            '4': [
                'Action',
                'Aktion'
            ],
            '5': [
                '{{projectName}}',
                '{{projectName}}'
            ],
            '6': [
                '{{From}}',
                '{{From}}'
            ],
            '7': [
                '{{sharedAt}}',
                '{{sharedAt}}'
            ],
            '8': [
                '{{availableUntil}}',
                '{{availableUntil}}'
            ],
            '9': [
                'Accept',
                'Annehmen'
            ],
            '10': [
                'Decline',
                'Ablehnen'
            ]
        },
        'Mail.DirectMessageUnregistered': {
            '0': [
                'Dear sir or Madam,',
                'Sehr geehrte Damen und Herren,'
            ],
            '1': [
                'Sender (',
                'der Absender dieser Nachricht, {{username}} möchte Ihnen eine Energieeffizienzberechnung zur Verfügung stellen.'
            ],
            '2': [
                '{{senderMail}}',
                'Das Berechnungsprojekt wird Ihnen direkt in der SinaSave-Anwendung bereit gestellt.'
            ],
            '3': [
                ')',
                'Bitte gehen Sie auf '
            ],
            '4': [
                ' would like to share an energy efficiency calculation with you.',
                'www.sinasave.siemens.com'
            ],
            '5': [
                'The calculation project is made available to you directly in the SinaSave application.',
                ' und melden Sie sich an.'
            ],
            '6': [
                'Please go to ',
                'Mit freundlichen Grüßen'
            ],
            '7': [
                'www.sinasave.siemens.com',
                'Ihr SinaSave-Team'
            ],
            '8': [
                ' and login',
                ''
            ],
            '9': [
                'With kind regards,',
                ''
            ]
        },
        'Mail.DocxPdfMessage': {
            '0': [
                'Dear Sir or Madam,',
                'Sehr geehrte Damen und Herren,'
            ],
            '1': [
                'attached to this email you will find the file with the results exported from the ',
                'im Anhang dieser Email finden Sie einen Ergebnisexport des Tools '
            ],
            '2': [
                'SinaSave',
                'SinaSave'
            ],
            '3': [
                ' tool. This document includes all of the input parameters and all of the results of the energy efficiency comparison or of the complete project - for example: ',
                '. Inhalt dieser Dokumentation sind sowohl alle Eingabeparameter als auch alle Ergebnisse des Energieeffizienzvergleiches bzw. des gesamten Projektes wie z.B.: '
            ],
            '4': [
                'Energy saving potential',
                'Energieeinsparpotential'
            ],
            '5': [
                ', ',
                ', '
            ],
            '6': [
                'cost saving potential',
                'Kosteneinsparpotential'
            ],
            '7': [
                ' and ',
                ' und '
            ],
            '8': [
                'expected payback time',
                'erwartete Amortisationszeit'
            ],
            '9': [
                '.',
                '.'
            ],
            '10': [
                'The SinaSave tool is available at no charge at: ',
                'Das Tool SinaSave steht Ihnen kostenlos zur Verfügung unter: '
            ],
            '11': [
                'www.sinasave.siemens.com',
                'www.sinasave.siemens.com'
            ],
            '12': [
                'With kind regards,',
                'Mit freundlichen Grüßen,'
            ]
        },
        'Mail.DocxSubject': [
            'SinaSave Calculation',
            'SinaSave Berechnung'
        ],
        'Mail.ExportFinished': {
            '0': [
                'Dear Sir or Madam,',
                'Sehr geehrte Damen und Herren,'
            ],
            '1': [
                'The calculation project ({{projectName}}) is ready for download and it is',
                'folgendes SinaSave-Projekt ({{projectName}}) steht Ihnen zum Download bereit und wird Ihnen direkt in der SinaSave-Anwendung zur Verfügung gestellt.'
            ],
            '2': [
                'made available to you directly in SinaSave application.',
                'Wählen Sie „Herunterladen“, um das Projekt herunterzuladen.'
            ],
            '3': [
                'Choose Download to download the project. Choose Delete to delete the project.',
                'Wählen Sie Löschen, um das Projekt zu löschen.'
            ],
            '4': [
                'Both actions require a SinaSave login.',
                'Für beide Aktionen loggen Sie sich bitte in SinaSave ein.'
            ],
            '5': [
                'With kind regards,',
                'Mit freundlichen Grüßen,'
            ],
            '6': [
                'The SinaSave Team',
                'IhrSinaSave-Team'
            ],
            '7': [
                'Project',
                'Projekt'
            ],
            '8': [
                'Created on',
                'Erstellt am'
            ],
            '9': [
                'Availabe until',
                'Verfügbar für '
            ],
            '10': [
                'Action',
                'Aktion'
            ],
            '11': [
                '{{projectName}}',
                '{{projectName}}'
            ],
            '12': [
                '{{createdOn}}',
                '{{createdOn}}'
            ],
            '13': [
                '{{availableUntil}}',
                '{{availableUntil}}'
            ],
            '14': [
                'Download',
                'Download'
            ],
            '15': [
                'Delete',
                'Löschen'
            ]
        },
        'Mail.ExportFinishedSubject': [
            'Export completed successfully ({{projectName}})',
            'Export für ({{projectName}}) erfolgreich fertiggestellt.'
        ],
        'Mail.From': [
            'From',
            'Von'
        ],
        'Mail.ImportFinished': {
            '0': [
                'Dear {{userName}},',
                'Lieber {{userName}},'
            ],
            '1': [
                'Your import has been completed successfully!',
                'Ihr Import wurde erfolgreich abgeschlossen!'
            ],
            '2': [
                'You can open it by clicking on the link below',
                'Sie können es öffnen, indem Sie auf den untenstehenden Link klicken'
            ],
            '3': [
                'Open Project',
                'Projekt öffnen'
            ]
        },
        'Mail.ImportFinishedSubject': [
            'Import completed successfully ({{projectName}})',
            'Import für ({{projectName}}) erfolgreich fertiggestellt.'
        ],
        'Mail.ImportFinishedWithErrors': {
            '0': [
                'Dear {{userName}},',
                'Lieber {{userName}},'
            ],
            '1': [
                'Your import has been completed with errors!',
                'Ihr Import wurde mit Fehlern abgeschlossen!'
            ],
            '2': [
                'The following errors occured:',
                'Folgender Fehler ist aufgetreten:'
            ],
            '3': [
                '{{errors}}',
                '{{errors}}'
            ],
            '4': [
                'You can open the project with the successfully imported comparisons by clicking on the link below.',
                'Sie können das Projekt mit den erfolgreich importierten Vergleichen öffnen, indem Sie auf den untenstehenden Link klicken.'
            ],
            '5': [
                'Open Project',
                'Projekt öffnen'
            ]
        },
        'Mail.ImportFinishedWithErrorsSubject': [
            'Import completed with errors ({{projectName}})',
            'Import für ({{projectName}}) mit Fehlern fertiggestellt.'
        ],
        'Mail.InactiveUserReminder': {
            '0': [
                '="margin: 16px 0"]{margin: 0 !important;}#MessageViewBody,#MessageWebViewDiv{width: 100% !important;}table,td{mso-table-lspace: 0pt !important;mso-table-rspace: 0pt !important;}table{border-spacing: 0 !important;border-collapse: collapse !important;table-layout: fixed !important;margin: 0 auto !important;}img{-ms-interpolation-mode: bicubic;}a[x-apple-data-detectors],.unstyle-auto-detected-links a,.aBn {border-bottom: 0 !important;cursor: default !important;color: inherit !important;font-size: inherit !important;font-family: inherit !important;font-weight: inherit !important;line-height: inherit !important;}.a6S{display: none !important;opacity: 0.01 !important;}.im{color: inherit !important;}img.g-img+div{display: none !important;}',
                ''
            ],
            '1': [
                'SinaSave',
                ''
            ],
            '2': [
                'SinaSave Reminder',
                ''
            ],
            '3': [
                'Dear {{userName}}',
                ''
            ],
            '4': [
                'Thank you for being a registered user in the SinaSave tool at ',
                ''
            ],
            '5': [
                '{{url}}',
                ''
            ],
            '6': [
                'We notice you have not logged into the portal in over 11 months. If you would like to keep your user account active, please visit the tool and login. Once you login again, your user account will remain active and you will be able to continue to take full advantage of the tool.',
                ''
            ],
            '7': [
                'Benefits of a SinaSave login include:',
                ''
            ],
            '8': [
                'Saving and loading projects and comparisons online',
                ''
            ],
            '9': [
                'Defining your own profiles',
                ''
            ],
            '10': [
                'Personal default values',
                ''
            ],
            '11': [
                'Project view with all your comparisons',
                ''
            ],
            '12': [
                'Exporting and sharing projects and comparisons',
                ''
            ],
            '13': [
                "Don't miss the advantage of being a registered SinaSave user! Please note that if you do not login within the ",
                ''
            ],
            '14': [
                'next month/next week',
                ''
            ],
            '15': [
                ', your user account and projects will be removed.',
                ''
            ],
            '16': [
                'Regards,',
                ''
            ],
            '17': [
                'The SinaSave Team',
                ''
            ],
            '18': [
                '&gt;',
                ''
            ],
            '19': [
                ' Contact us',
                ''
            ],
            '20': [
                'All information provided on this page is for informative purposes only and is not binding. The right to change information on this page at any time is reserved. For the guarantee of accuracy of the information contained herein no liability will be accepted.',
                ''
            ],
            '21': [
                'Corporate Information:',
                ''
            ],
            '22': [
                'Siemens Aktiengesellschaft: Chairman of the Supervisory Board: Jim Hagemann Snabe; Managing Board: Roland Busch, Chairman, President and Chief Executive Officer; Cedrik Neike, Matthias Rebellius, Ralf P. Thomas, Judith Wiese; Registered offices: Berlin and Munich, Germany; Commercial registries: Berlin-Charlottenburg, HRB 12300, Munich, HRB 6684; WEEE-Reg.-No. DE 23691322',
                ''
            ],
            '23': [
                'siemens.com Global Website',
                ''
            ],
            '24': [
                'This mail was sent to ',
                ''
            ],
            '25': [
                '{{userName}}',
                ''
            ]
        },
        'Mail.Message': [
            'Message',
            'Nachricht'
        ],
        'Mail.Send': [
            'Send',
            'Senden'
        ],
        'Mail.SendFailed': [
            'Email sending failed!',
            'Die Email konnte nicht versendet werden.'
        ],
        'Mail.SendMail': [
            'Send mail',
            'E-Mail versenden'
        ],
        'Mail.SendSuccess': [
            'Email sent successfully.',
            'Die Email wurde erfolgreich versendet.'
        ],
        'Mail.Subject': [
            'Subject',
            'Betreff'
        ],
        'Mail.To': [
            'To',
            'An'
        ],
        'Main.AfterContractTime': [
            'Running costs after contract time',
            'Laufende Kosten - Nach Vertragslaufzeit'
        ],
        'Main.AfterInnovation': [
            'Running costs after innovation',
            'Betriebskosten nach Erneuerung'
        ],
        'Main.Application': [
            'Application',
            'Applikation'
        ],
        'Main.ContractRate': [
            'Contract rate',
            'Vertragsrate'
        ],
        'Main.DuringContractTime': [
            'Running costs during contract time',
            'Laufende Kosten - Während der Vertragslaufzeit'
        ],
        'Main.FinancingCosts': [
            'Financing costs',
            'Finanzierungskosten'
        ],
        'Main.Home': [
            'SinaSave Start',
            ''
        ],
        'Main.Information': [
            'Information',
            ''
        ],
        'Main.InvestmentSum': [
            'Investment sum',
            'Investitionssumme'
        ],
        'Main.LanguageText': [
            'Language',
            'Sprache'
        ],
        'Main.NPerA': [
            'n/a',
            ''
        ],
        'Main.Options': [
            'Options',
            'Optionen'
        ],
        'Main.RunningCostsToday': [
            'Running costs today',
            'Laufende Kosten - Heute'
        ],
        'Main.SettingsText': [
            'Personal Default Values',
            'Persönliche Voreinstellungen'
        ],
        'MassImport.FileUploadedText': [
            'File uploaded successfully, you will be redirected to your projects page',
            'Die Datei wurde erfolgreich hochgeladen. Sie werden zu Ihrer Projektseite weitergeleitet.'
        ],
        'MassImport.FileUploadedTitle': [
            'File uploaded successfully',
            'Datei erfolgreich importiert'
        ],
        'Menu.ChangePageLanguage': [
            'Choose your language',
            'Wähle Deine Sprache'
        ],
        'Menu.RecognizedCountry': {
            '0': [
                'Your location is recognized as {{countryCode}}',
                'Ihr Standort {{countryCode}}'
            ]
        },
        'Meps.MepsServiceUnavailable': [
            "The MEPS service is currently unavailable, MEPS data could not be verified for the motors! Depending on the current country's ({{COUNTRY_NAME}}) regulation the offered motor may no longer be placed on the market due to its efficiency class! Please verify manually.",
            'Der MEPS-Dienst ist derzeit nicht verfügbar, die MEPS-Daten konnten für die Motoren nicht verifiziert werden! Je nach aktueller Landesverordnung ({{COUNTRY_NAME}}) darf der angebotene Motor aufgrund seiner Effizienzklasse nicht mehr auf den Markt gebracht werden! Bitte überprüfen Sie manuell!'
        ],
        'Meps.MepsServiceUnavailableTitle': [
            'MEPS service unavailable',
            'MEPS Service nicht erreichbar.'
        ],
        'MepsWarning.Content': {
            '0': [
                'Please note that ',
                'Please note that '
            ],
            '1': [
                'according ',
                'according '
            ],
            '2': [
                'to ',
                'to '
            ],
            '3': [
                'EU regulation EU2019 / 1781',
                'EU regulation EU2019 / 1781'
            ],
            '4': [
                ' this motor ',
                ' this motor '
            ],
            '5': [
                'may ',
                'may '
            ],
            '6': [
                'no longer be placed on the market',
                'no longer be placed on the market'
            ],
            '7': [
                ' in the countries of the European Union or in the EFTA area ',
                ' in the countries of the European Union or in the EFTA area '
            ],
            '8': [
                'because of its efficiency class',
                'because of its efficiency class'
            ],
            '9': [
                '! ',
                '! '
            ],
            '10': [
                'Different regulations apply in different regions or countries. You can find an overview of the most important regulations in the ',
                'Different regulations apply in different regions or countries. You can find an overview of the most important regulations in the '
            ],
            '11': [
                'MEPS Web Guide.',
                'MEPS Web Guide.'
            ]
        },
        'MepsWarning.Content.Cn': {
            '0': [
                'Please note that according to ',
                'Bitte beachten Sie, dass dieser Motor gemäß den '
            ],
            '1': [
                'Chinese regulations CEL 007-2021',
                'chinesischen Vorschriften CEL 007-2021'
            ],
            '2': [
                ' and ',
                ' und '
            ],
            '3': [
                'GB 18613:2020',
                'GB 18613:2020'
            ],
            '4': [
                ' this motor may no longer be placed on the market.',
                ' nicht mehr auf den Markt gebracht werden darf.'
            ],
            '5': [
                'Different regulations apply in different regions or countries. You can find an overview of the most important regulations in the&nbsp;',
                'In verschiedenen Regionen oder Ländern gelten unterschiedliche Vorschriften. Eine Übersicht über die wichtigsten Regelungen finden Sie im&nbsp;'
            ],
            '6': [
                'MEPS Web Guide.',
                'MEPS Web Guide.'
            ]
        },
        'MepsWarning.Content.Fallback': {
            '0': [
                'Please note that according',
                'Bitte beachten Sie, dass dieser Motor gemäß den Vorschriften Ihres aktuellen Landes &nbsp;'
            ],
            '1': [
                "to your current country's ({{COUNTRY_NAME}}) regulation this motor&nbsp;",
                '({{COUNTRY_NAME}}) nicht mehr auf den Markt gebracht werden darf!'
            ],
            '2': [
                'may no longer be placed on the market',
                '!&nbsp;'
            ],
            '3': [
                '!&nbsp;',
                'In verschiedenen Regionen oder Ländern gelten unterschiedliche Vorschriften. Eine Übersicht über die wichtigsten Regelungen finden Sie im &nbsp;'
            ],
            '4': [
                'Different regulations apply in different regions or countries. You can find an overview of the most important regulations in the&nbsp;',
                'MEPS Web Guide.'
            ],
            '5': [
                'MEPS Web Guide.',
                ''
            ]
        },
        'MepsWarning.Content.In': {
            '0': [
                'Please note that according to ',
                'Bitte beachten Sie, dass dieser Motor gemäß den  '
            ],
            '1': [
                'Indian regulation IS 12615: 2018',
                ' Indischen Vorschriften IS 12615: 2018'
            ],
            '2': [
                ' this motor may no longer be placed on the market.',
                ' nicht mehr auf den Markt gebracht werden darf.'
            ],
            '3': [
                'Different regulations apply in different regions or countries. You can find an overview of the most important regulations in the&nbsp;',
                ' In verschiedenen Regionen oder Ländern gelten unterschiedliche Vorschriften. Eine Übersicht über die wichtigsten Regelungen finden Sie im &nbsp;'
            ],
            '4': [
                'MEPS Web Guide.',
                'MEPS Web Guide.'
            ]
        },
        'MepsWarning.Title': [
            'Information',
            ''
        ],
        'Motor.24Load': [
            '2/4 load',
            '2/4 Last'
        ],
        'Motor.34Load': [
            '3/4 load',
            '3/4 Last'
        ],
        'Motor.44Load': [
            '4/4 load',
            '4/4 Last'
        ],
        'Motor.AddMotor': [
            'Add motor',
            'Motor hinzufügen'
        ],
        'Motor.AlternativeMotor': [
            'Alternative Motor',
            'Alternativmotor'
        ],
        'Motor.Casting': [
            'Casting',
            'Gehäusematerial'
        ],
        'Motor.Caution': [
            'Caution',
            'Bitte beachten!'
        ],
        'Motor.CautionInfoCase2MText': [
            'Until an operation time of {{paybacktime}} years, the {{mostEconomicalMotorName}} is the more economical. For longer lifetimes the Reference Motor is in advantage because of being more efficient.',
            'Bis zu einer Betriebszeit von ungefähr {{paybacktime}} Jahren ist das {{mostEconomicalMotorName}} der wirtschaftlichere. Bei längeren Betriebszeiten ist der Referenzmotor im Vorteil, da er effizienter ist.'
        ],
        'Motor.CautionInfoText': [
            'Up to an operating time of {{paybackTime}} years, the {{motorName}} is more economical than the Reference motor. This no longer applies to longer periods of operation!',
            'Bis zu einer Betriebsdauer von {{paybackTime}} Jahren ist {{motorName}} wirtschaftlicher als der Referenzmotor. Dies gilt nicht mehr für längere Betriebszeiten!'
        ],
        'Motor.CautionInfoTextReference': [
            'Up to an operating time of {{paybackTime}} years, the {{motorName}} \nis the more economical solution. \nThis no longer applies to longer periods of operation.',
            'Bis zu einer Betriebszeit von {{paybackTime}} Jahren ist der {{motorName}} die wirtschaftlichere Lösung. Dies gilt nicht für längere Betriebszeiten!'
        ],
        'Motor.EfficiencyClass': [
            'Efficiency class',
            'Wirkungsgradklasse'
        ],
        'Motor.EfficiencyClasses.Other': [
            'editable',
            'editierbar'
        ],
        'Motor.GetTechnicalData': [
            'Get technical data',
            'Zu den technischen Daten'
        ],
        'Motor.GrantRate': [
            'Government funding rate',
            'Förderrate'
        ],
        'Motor.GrantSum': [
            'Government funding sum',
            'Fördersumme'
        ],
        'Motor.IgnitionProtection': [
            'Ignition protection class',
            'Zündschutzklasse'
        ],
        'Motor.MostEconomical': [
            'Commercial motor result',
            'Ergebnis des kommerziellen Motorenvergleiches'
        ],
        'Motor.MostEconomicalByInvestmentInfoText': [
            'In direct comparison to the Reference Motor, {{motorName}} is the more economical solution from day one. The required investment and energy costs are lower than those of the Reference Motor.',
            'Im direkten Vergleich zum Referenzmotor ist {{motorName}} vom ersten Tag an die wirtschaftlichere Lösung. Die erforderliche Investition und die Energiekosten sind geringer als die des Referenzmotors.'
        ],
        'Motor.MostEconomicalInfoText': [
            'Comparing the Reference Motor and Alternative Motor(s), {{motorName}} is the most economical solution. The investment payback is within the planned operation time.',
            'Im Vergleich des Referenzmotors und alternativer Motoren ist {{motorName}} die wirtschaftlichste Lösung. Die Amortisation der Investition erfolgt innerhalb der geplanten Betriebszeit.'
        ],
        'Motor.MostEconomicalInfoTextReference': [
            'According to the set operation time, {{motorName}} is more economical.',
            'Entsprechend der gewählten Betriebsdauer ist der {{motorName}} wirtschaftlicher.'
        ],
        'Motor.MostEfficientInfoText': [
            'The {{motorName}} is more efficient than the other motors. Please check the Commercial View to see which is the most economical motor.',
            'Der {{motorName}} ist effizienter als die anderen Motoren. Bitte überprüfen Sie die Kommerzielle Sicht, um zu sehen, welcher Motor der wirtschaftlichste ist.'
        ],
        'Motor.MostEfficientInfoTitle': [
            'Motor efficiency result',
            'Ergebnis Motoreneffizienzvergleich'
        ],
        'Motor.MotorLoad': [
            'Motor load',
            'Motorlast'
        ],
        'Motor.MotorProfile': [
            'Motor Profile',
            'Motorprofil'
        ],
        'Motor.PoleNumber': [
            'Pole number',
            'Polzahl'
        ],
        'Motor.Powerloss': [
            'Power Loss',
            'Verlustleistung'
        ],
        'Motor.PowerOutputIn': [
            'Power output',
            'Abgegebene Leistung'
        ],
        'Motor.PowerPN': {
            '0': [
                'Power P',
                'Leistung P'
            ],
            '1': [
                'N',
                'N'
            ]
        },
        'Motor.ReferenceMotor': [
            'Reference Motor',
            'Referenzmotor'
        ],
        'Motor.Siemens': [
            'SIMOTICS',
            ''
        ],
        'Motor.YourMotor': [
            'Existing Motor',
            'Vorhandener Motor'
        ],
        'Motorinfo.1LE7': [
            'For motors with shaft heights 280 and 315  insulated bearings (option L53) are taken into account from a pricing perspective.',
            'Bei Motoren mit Achshöhen 280 und 315 sind isolierte Lager (Option L53) im Listenpreis berücksichtigt.'
        ],
        'Motorinfo.one': {
            '0': [
                'If a standard low-voltage motor is operated with a converter, then a ',
                'Bei Standardmotoren, die am Umrichter betrieben werden sind '
            ],
            '1': [
                'KTY84',
                'Temperatursensor KTY84 (Motorschutz)'
            ],
            '2': [
                ' temperature sensor (motor protection) – and for ',
                ' und für die '
            ],
            '3': [
                'shaft heights 280 ',
                'Achshöhen 280 und 315 isolierte Lager (Option L51) '
            ],
            '4': [
                'and ',
                'im Listenpreis berücksichtigt.'
            ],
            '5': [
                '315',
                ''
            ],
            '6': [
                ' – ',
                ''
            ],
            '7': [
                'insulated bearings',
                ''
            ],
            '8': [
                ' (option L51) are taken into account from a pricing perspective.',
                ''
            ]
        },
        'Pagination.InfoText': [
            'Showing {{first}} to {{last}} of {{total}} entries',
            ''
        ],
        'Pagination.ItemsPerPage': [
            'Items per page',
            ''
        ],
        'Profile.ExistingMediums': [
            'Existing mediums',
            ''
        ],
        'Profile.ExistingProfiles': [
            'Existing profiles',
            ''
        ],
        'Project.AddComparison': [
            'Add comparison',
            'Vergleich hinzufügen'
        ],
        'Project.AddComparisonTo': [
            'Add comparison to',
            'Vergleich hinzufügen zu '
        ],
        'Project.AddExisting': [
            'Add existing',
            'Vergleich hinzufügen'
        ],
        'Project.AlternativeConcept': [
            'Alternative concept',
            'Alternativkonzept'
        ],
        'Project.AmortizationChartHeader': [
            'Amortization of the whole Project',
            'Amortisation des Gesamtprojektes'
        ],
        'Project.Comment': [
            'Comment',
            'Kommentar'
        ],
        'Project.Company': [
            'Company',
            'Firma'
        ],
        'Project.ComparisonAlreadyExists': [
            'A comparison already exists with this name.',
            'Es besteht bereits ein Vergleich mit diesem Namen. Bitte wählen Sie einen anderen Namen.'
        ],
        'Project.ComparisonList': [
            '{{count}} single comparison',
            '{{count}} Einzelvergleiche'
        ],
        'Project.ComparisonListPlural': [
            '{{count}} single comparisons',
            '{{count}} Einzelvergleiche'
        ],
        'Project.ComparisonName': [
            'Comparison name',
            'Name des Vergleiches'
        ],
        'Project.ComparisonWillBeDeleted': [
            'You will delete the following comparison: {{comparison_name}}',
            ''
        ],
        'Project.CreateNew': [
            'Create new',
            'Neu erstellen'
        ],
        'Project.CreateNewProject': [
            'Create new project',
            'Neues Projekt erstellen'
        ],
        'Project.CreationDate': [
            'Creation date',
            'Erstelldatum'
        ],
        'Project.Customer': [
            'Customer',
            'Kunde'
        ],
        'Project.Delete.ButtonNoText': [
            'No',
            'Nein'
        ],
        'Project.Delete.ButtonYesText': [
            'Yes',
            'Ja'
        ],
        'Project.Delete.ConfirmDelete': [
            'Are you sure to delete &#34;{{project_name}}&#34;?',
            'Möchten Sie das Projekt "{{project_name}}" wirklich löschen?'
        ],
        'Project.Delete.ConfirmDeleteWithComparisons': [
            'If you delete project &#34;{{project_name}}&#34; then its comparisons will be also deleted.',
            'Wenn Sie das Projekt "{{project_name}}" Löschen werden auch die Vergleiche des Projektes gelöscht.'
        ],
        'Project.Delete.Message': [
            'Are you sure to delete?',
            'Wirklich löschen?'
        ],
        'Project.DeleteMessagePlural': [
            '{{delete_count}} projects will be deleted.',
            ''
        ],
        'Project.DeleteMessageSingular': [
            '1 project will be deleted.',
            ''
        ],
        'Project.DeletePluralTitle': [
            'Are you sure you want to delete the selected projects?',
            ''
        ],
        'Project.DeleteSingularTitle': [
            'Are you sure you want to delete the selected project?',
            ''
        ],
        'Project.Department': [
            'Department',
            'Abteilung'
        ],
        'Project.DownloadComparison': [
            'Download comparison',
            'Vergleich herunterladen'
        ],
        'Project.DownloadImportTemplate': [
            'Download template',
            'Template herunterladen'
        ],
        'Project.EnergyCostsForAlt': [
            'Alternative concept',
            'Alternativkonzept'
        ],
        'Project.EnergyCostsForRef': [
            'Reference concept',
            'Referenzkonzept'
        ],
        'Project.EnergyPerformance': [
            'Energy performance',
            'Monatliche Kosten'
        ],
        'Project.ExportEnergyCostForAlt': [
            'Expected energy costs of alternative concept',
            'Voraussichtliche Energiekosten Alternativsystem'
        ],
        'Project.ExportEnergyCostForRef': [
            'Expected energy costs of reference concept',
            'Voraussichtliche Energiekosten Referenzkonzept'
        ],
        'Project.ExportInvestmentForAlt': [
            'Required Investment for alternative concept',
            'Erforderliche Investition Alternativkonzept'
        ],
        'Project.ExportInvestmentForRef': [
            'Required Investment for reference concept',
            'Erforderliche Investition Referenzkonzept'
        ],
        'Project.ExportPotentialCO2': {
            '0': [
                'Saving potential of CO',
                'Einsparpotential CO'
            ],
            '1': [
                '2',
                '2'
            ]
        },
        'Project.ExportPotentialEnergyCostSavings': [
            'Saving potential of energy costs',
            'Einsparpotential Energiekosten'
        ],
        'Project.ExportPotentialEnergySavings': [
            'Saving potential of energy',
            'Einsparpotential Energie'
        ],
        'Project.Facility': [
            'Facility',
            'Anlage'
        ],
        'Project.HideComparisons': [
            'hide comparisons',
            'Vergleiche verbergen'
        ],
        'Project.ImportMotors': [
            'Import motor list',
            'Motorenliste importieren'
        ],
        'Project.ImportMotorsTemplate': [
            'Import motors template',
            'Template Motorenimport'
        ],
        'Project.InfoCard.Case1A': [
            'Comparing the Reference Concept and Alternative Concept, the Alternative Concept is the more economical solution. The investment payback is within the planned operation time.',
            'Im Vergleich von Referenzkonzept und Alternativkonzept ist das Alternativkonzept die wirtschaftlichere Lösung. Die Amortisation der Investition erfolgt innerhalb der geplanten Betriebszeit.'
        ],
        'Project.InfoCard.Case1B': [
            'According to the set operation time of {{x}} years, the Reference Concept is more economical. For the Alternative Concept to be more economical an operation time of approximately {{paybacktime}} years is required.',
            'Entsprechend der gewählten Betriebszeit von {{x}} Jahren ist das Referenzkonzept wirtschaftlicher. Es ist eine Betriebszeit von ungefähr {{paybacktime}} Jahren erforderlich bis das Alternativkonzept wirtschaftlicher als das Referenzkonzept wird.'
        ],
        'Project.InfoCard.Case2': [
            'Comparing the Reference Concept and Alternative Concept, the Alternative Concept is from the first day the more economical solution. The required investment and the energy costs are lower than those of the Reference Concept.',
            'Im Vergleich von Referenzkonzept und Alternativkonzept ist das Alternativkonzept vom ersten Tag an die wirtschaftlichere Lösung. Die erforderliche Investition und die Energiekosten sind geringer als die des Referenzkonzeptes.'
        ],
        'Project.InfoCard.Case3': [
            'Comparing the Reference Concept and Alternative Concept, the Reference Concept is the more economical solution. The required investment and the energy costs are lower than those of the Alternative Concept.',
            'Im Vergleich von Referenzkonzept und Alternativkonzept ist das Referenzkonzept die wirtschaftlichere Lösung. Die erforderliche Investition und die Energiekosten sind geringer als die des Alternativkonzeptes.'
        ],
        'Project.InfoCard.Case4A': [
            'From an operation time of {{paybacktime}} years on the Reference Concept is the more economical!',
            'Ab einer Betriebszeit von ungefähr {{paybacktime}} Jahren ist das Referenzkonzept das wirtschaftlichere.'
        ],
        'Project.InfoCard.Case4B': [
            'Until an operation time of {{paybacktime}} years, the Alternative Concept is the more economical. For longer lifetimes the Reference Concept is in advantage because of being more efficient.',
            'Bis zu einer Betriebszeit von ungefähr {{paybacktime}} Jahren ist das Alternativkonzept das wirtschaftlichere. Bei längeren Betriebszeiten ist das Referenzkonzept im Vorteil, da es das effizientere Konzept ist.'
        ],
        'Project.Investment': [
            'Alternative concept',
            'Alternativkonzept'
        ],
        'Project.InvestmentExisting': [
            'Reference concept',
            'Referenzkonzept'
        ],
        'Project.MostEconomical': [
            'Most economical concept',
            'Ergebnis des kommerziellen Konzeptvergleiches'
        ],
        'Project.MySavedProjectsOverview': [
            'My saved projects',
            'Meine Projekte'
        ],
        'Project.Name': [
            'Project name',
            'Projektname'
        ],
        'Project.NoActiveComparison': [
            'No results available. This project contains no active comparisons.',
            'Es können keine Ergebnisse dargestellt werden. Das Projekt enthält keine aktiven Vergleiche.'
        ],
        'Project.NoConfigurations': [
            'Please add a comparison e.g with "Create new comparison".',
            'Bitte fügen Sie einen Vergleich z.B. mit "Neuen Vergleich erstellen" hinzu.'
        ],
        'Project.NoResults': [
            'This Project containes no comparisons. ',
            'Dieses Projekt enthält keine Vergleiche. '
        ],
        'Project.Phone': [
            'Phone',
            'Telefon-Nr.'
        ],
        'Project.ProjectData': [
            'Project data',
            'Projektdaten'
        ],
        'Project.ProjectHasNoComparison': [
            'This Project owns no comparison',
            'Diesem Projekt sind keine Vergleiche zugeordnet!'
        ],
        'Project.ReferenceConcept': [
            'Reference concept',
            'Referenzkonzept'
        ],
        'Project.Results': [
            'Project results',
            'Projektergebnisse'
        ],
        'Project.SaveAs': [
            'Save as',
            'Speichern unter'
        ],
        'Project.SearchForComparisons': [
            'Search for comparisons',
            'Nach Vergleichen suchen'
        ],
        'Project.ShowComparisons': [
            'show comparisons',
            'Vergleiche einblenden'
        ],
        'Project.SingleComparison': [
            'single comparison',
            'Einzelvergleich'
        ],
        'Project.SingleComparisons': [
            'single comparisons',
            'Einzelne Vergleiche'
        ],
        'Project.TcoChartHeader': [
            'Total Cost of Ownership of the Project',
            'Total Cost of Ownership für dieses Projekt'
        ],
        'Project.TooManyComparisons': [
            "The maximum number of comparisons per project is 1000. You can delete the not used ones from the project's comparison list, or create a new project.\n",
            'Die maximale Anzahl an Vergleichen pro Projekt beträgt 1000. Sie können die nicht verwendeten Vergleiche aus der Vergleichsliste des Projekts löschen oder ein neues Projekt erstellen.\n'
        ],
        'Project.TotalCostsOfOwnership': [
            'Total costs of Ownership (TCO)',
            'Gesamtbetriebskosten (TCO)'
        ],
        'Project.TotalSavingsIn': [
            'Expected total costs savings in {{x}} years',
            'Erwartete Gesamtkostenersparnis in {{x}} Jahren'
        ],
        'Project.TotalSavingsIn1Year': [
            'Expected total costs savings in 1 year',
            'Erwartete jährliche Gesamtkostenersparnis'
        ],
        'Settings.AllComparisonsAffectedMessage': [
            'This change will be applied to all comparisons in this project',
            'Diese Änderungen werden auf alle Vergleiche dieses Projektes angewended'
        ],
        'Settings.BasedOnIp': [
            'Country based on IP, currently: {{COUNTRY_NAME}}',
            'Land entsprechend der IP, im Moment: {{COUNTRY_NAME}}'
        ],
        'Settings.CO2Factor': {
            '0': [
                'CO',
                'CO'
            ],
            '1': [
                '2',
                '2'
            ],
            '2': [
                ' factor',
                ' Faktor'
            ]
        },
        'Settings.Comment': [
            'Comment',
            'Kommentar'
        ],
        'Settings.Company': [
            'Company',
            'Firma'
        ],
        'Settings.Country': [
            'Country',
            'Land'
        ],
        'Settings.CurrenciesLastUpdateHint': [
            'The calculation factor for the currency was updated at:',
            'Der Umrechnungsfaktor für Währungen wurde zuletzt aktualisiert am: '
        ],
        'Settings.Customer': [
            'Customer',
            'Kunde'
        ],
        'Settings.Date': [
            'Date',
            'Datum'
        ],
        'Settings.Department': [
            'Department',
            'Abteilung'
        ],
        'Settings.Disclaimer': [
            'Disclaimer',
            'Haftungsausschluss'
        ],
        'Settings.Email': [
            'Email',
            'E-Mail-Adresse'
        ],
        'Settings.Facility': [
            'Facility',
            'Anlage'
        ],
        'Settings.Name': [
            'Name',
            ''
        ],
        'Settings.Phone': [
            'Phone',
            'Telefon-Nr.'
        ],
        'Settings.ProjectName': [
            'Project name',
            'Projektname'
        ],
        'Settings.ProjectSettings': [
            'Project settings',
            'Projekteinstellungen'
        ],
        'Settings.ProjectSettingsWillBeOverwritten': [
            "The new comparison's settings will be applied to all existing comparisons in this project.",
            'Die Einstellungen des neuen Vergleichs werden auf alle vorhandenen Vergleiche in diesem Projekt angewendet.'
        ],
        'Settings.SendToSender': [
            'Copy to sender',
            'Kopie an mich'
        ],
        'Settings.Settings': [
            'Settings',
            'Einstellungen'
        ],
        'Settings.SupplyTypeHint': [
            'Default line supply affects only system comparisons.',
            'Der Standardwert der Netzversorgung gilt nur für Systemvergleiche.'
        ],
        'Settings.User': [
            'User',
            'Ansprechpartner'
        ],
        'si-dropdown.clear.aria-label': [
            'Reset',
            'Werte zurücksetzen'
        ],
        'SignalR.CantConnect': [
            "Connection to the server could not be established. It is possible that the application won't work properly in this state. Refreshing might solve the issues.",
            'Es konnte keine Verbindung zum Server hergestellt werden. Es ist möglich, dass die Anwendung in diesem Zustand nicht ordnungsgemäß funktioniert. Neu Laden der Anwendung könnte evtl. das Problem lösen.'
        ],
        'SignalR.ConnectionLost': [
            "The application timed out while trying to reconnect to the server and won't try it again. It is possible that the application won't work properly. Refreshing might solve the issues.",
            'Beim Versuch, die Verbindung zum Server wiederherzustellen, ist  eine Zeitüberschreitung aufgetreten. Es wird kein erneuter Versuch unternommen. Es ist möglich, dass die Anwendung nicht ordnungsgemäß funktioniert. Neu Laden der Anwendung könnte evtl. das Problem lösen.'
        ],
        'SignalR.ConnectionRestored': [
            'Connection to the server is restored.',
            'Die Verbindung zum Server wurde wiederhergestellt.'
        ],
        'SignalR.TryToReconnect': [
            "Connection is lost to the server, the application will try to reestablish it. It is possible that the application won't work properly in the meantime. You will be notified if restoring the connection is successful or not.",
            'Die Verbindung zum Server ist unterbrochen. Die Anwendung versucht sie wiederherzustellen. Es kann sein, dass die Anwendung in der Zwischenzeit nicht richtig funktioniert. Sie werden benachrichtigt, ob die Wiederherstellung der Verbindung erfolgreich war oder nicht.'
        ],
        'si-header.toggle-mobile-nav.aria-label': [
            'Close',
            'Schließen'
        ],
        'si-newton-dropdown.clear.aria-label': [
            'Reset',
            'Werte zurücksetzen'
        ],
        'si-newton-header.toggle-mobile-nav.aria-label': [
            'Close',
            'Schließen'
        ],
        'si-newton-pagination.backward.aria-label': [
            'Previous',
            'Zurück'
        ],
        'si-newton-pagination.fast-backward.aria-label': [
            'Previous',
            'Zurück'
        ],
        'si-newton-pagination.fast-forward.aria-label': [
            'Next',
            'Weiter'
        ],
        'si-newton-pagination.forward.aria-label': [
            'Next',
            'Weiter'
        ],
        'si-newton-table.column.sort-asc.aria-label': [
            'Reset',
            'Werte zurücksetzen'
        ],
        'si-newton-table.column.sort-desc.aria-label': [
            'Reset',
            'Werte zurücksetzen'
        ],
        'si-newton-toast.close.aria-label': [
            'Close',
            'Schließen '
        ],
        'si-pagination.backward.aria-label': [
            'Previous',
            'Zurück'
        ],
        'si-pagination.fast-backward.aria-label': [
            'Previous',
            'Zurück'
        ],
        'si-pagination.fast-forward.aria-label': [
            'Next',
            'Weiter'
        ],
        'si-pagination.forward.aria-label': [
            'Next',
            'Weiter'
        ],
        'si-table.column.sort-asc.aria-label': [
            'Reset',
            'Werte zurücksetzen'
        ],
        'si-table.column.sort-desc.aria-label': [
            'Reset',
            'Werte zurücksetzen'
        ],
        'si-toast.close.aria-label': [
            'Close',
            'Schließen'
        ],
        'System.Actions': [
            'Actions',
            'Aktionen'
        ],
        'System.AdditionalInvestment': [
            'Additional Investment',
            'Zusätzliche Investitionen'
        ],
        'System.Allocation': [
            'Allocation',
            'Verteilungsprofil'
        ],
        'System.AllRightReserved': [
            'All rights reserved',
            'Alle Rechte vorbehalten'
        ],
        'System.AlternativeMoreEfficientInfoText': [
            'The Alternative System is more efficient than the Reference System. Please check the Commercial View to see which is the more economical system.',
            'Das Alternativsystem ist effizienter als das Referenzsystem. Bitte überprüfen Sie die Kommerzielle Sicht, um zu sehen, welches System das wirtschaftlichere ist.'
        ],
        'System.AlternativeSystem': [
            'Alternative System',
            'Alternativsystem'
        ],
        'System.Amortization': [
            'Amortization time',
            'Amortisationszeit '
        ],
        'System.AmortizationInMonths': [
            'Amortization in month',
            'Amortisation in Monaten'
        ],
        'System.AmortizationInYears': [
            'Amortization in years',
            'Amortisationszeit nach Jahren'
        ],
        'System.AmortizationTime': [
            'Expected amortization time',
            'Erwartete Amortisationszeit'
        ],
        'System.Asterix': {},
        'System.At': [
            'at',
            'bei '
        ],
        'System.BasicView': [
            'Basic view',
            'Standardansicht'
        ],
        'System.Caution': [
            'Caution',
            'Bitte beachten!'
        ],
        'System.ChangeMarkedValues': [
            'Please enter correct values in the highlighted inputfields.',
            'Bitte geben Sie korrekte Werte in den hervogehobenen Feldern ein.'
        ],
        'System.Co2Saving': {
            '0': [
                'CO',
                'CO2 Sparpotential'
            ],
            '1': [
                '2',
                ''
            ],
            '2': [
                ' emission savings',
                ''
            ]
        },
        'System.Comparison': [
            'Comparison',
            'Einsparungen'
        ],
        'System.Comparisons': [
            'Comparisons',
            ''
        ],
        'System.Configuration': [
            'Configuration',
            'Konfiguration'
        ],
        'System.Contracting': [
            'Contracting',
            ''
        ],
        'System.ContractPeriod': [
            'Contract period',
            'Vertragslaufzeit'
        ],
        'System.ContractRate': [
            'Contract rate',
            'Vertragsrate'
        ],
        'System.ControlMode': [
            'Control Mode',
            'Regelungsart'
        ],
        'System.ControlType.Bypass': [
            'Bypass',
            'Bypassregelung'
        ],
        'System.ControlType.BypassContext': [
            ' Controlling by increasing the bypass flow.  The pump is always operating with rated power ',
            ' Regelung durch Erhöhung des Bypassförderstromes.  Die Pumpe wird immer mit Nennleistung betrieben. '
        ],
        'System.ControlType.BypassContextFan': [
            'Controlling by increasing the bypass flow. The fan is always operating with rated power.',
            'Regelung durch Erhöhung des Bypassförderstromes. Der Lüfter wird immer mit Nennleistung betrieben.'
        ],
        'System.ControlType.BypassController': [
            'Bypass controller',
            'Bypassregelung'
        ],
        'System.ControlType.Converter': [
            'Converter',
            'Drehzahlregelung'
        ],
        'System.ControlType.ConverterContext': [
            ' Controlling by reducing the speed of the pump.  The pump is always operating on demand. ',
            ' Regelung durch Integration eines Frequenzumrichters.  Die Pumpe wird entsprechend des Förderstrombedarfs betrieben. '
        ],
        'System.ControlType.ConverterContextFan': [
            'Controlling by reducing the speed of the fan. The fan is always operating on demand.',
            'Regelung durch Integration eines Frequenzumrichters. Der Lüfter wird entsprechend des Förderstrombedarfs betrieben.'
        ],
        'System.ControlType.ConverterController': [
            'Converter controlled',
            'Umrichterregelung'
        ],
        'System.ControlType.Throttle': [
            'Throttle',
            'Drosselregelung'
        ],
        'System.ControlType.ThrottleContext': [
            ' Controlling by increasing the flow resistance.  The pump is always operating with rated speed. ',
            ' Regelung durch Erhöhung des Anlagenwiderstandes.  Die Pumpe wird immer bei Nenndrehzahl betrieben. '
        ],
        'System.ControlType.ThrottleContextFan': [
            'Controlling by increasing the flow resistance. The fan is always operating with rated speed.',
            'Regelung durch Erhöhung des Anlagenwiderstandes.  Der Lüfter wird immer bei Nenndrehzahl betrieben. '
        ],
        'System.ControlType.ThrottleController': [
            'Throttle controlled',
            'Drossel-Regelung'
        ],
        'System.ControlTypes': [
            'Controller',
            'Regelungsart'
        ],
        'System.Converter.DesignType': [
            'Design type',
            'Aufbauform'
        ],
        'System.Converter.DesignTypes.Builtin': [
            'Chassis',
            'Einbaugerät'
        ],
        'System.Converter.DesignTypes.Cabinet': [
            'Cabinet',
            'Schrank'
        ],
        'System.Converter.RatedPower': [
            'Rated power',
            'Typleistung'
        ],
        'System.Converter.Siemens': [
            'SINAMICS',
            ''
        ],
        'System.Converter.ThirdParty': [
            'Third party converter',
            'Fremdumrichter'
        ],
        'System.ConverterEfficiency': [
            'Converter efficiency',
            'Wirkungsgrad'
        ],
        'System.ConverterHeaderLabel': [
            'Converter',
            'Umrichter'
        ],
        'System.CostSavingsPer': [
            'Cost savings per',
            'Kosteneinsparung in '
        ],
        'System.CreatedAt': [
            'Created on',
            'Erstellt am'
        ],
        'System.Currencies.AED.Name': [
            'UAE dirham',
            'VAE Dirham'
        ],
        'System.Currencies.AFN.Name': [
            'Afghan afghani',
            'Afghani'
        ],
        'System.Currencies.AOA.Name': [
            'Angolan kwanza',
            'Kwanza'
        ],
        'System.Currencies.ARS.Name': [
            'Argentine peso',
            'Argentinischer Peso'
        ],
        'System.Currencies.AUD.Name': [
            'Australian dollar',
            'Australischer Dollar'
        ],
        'System.Currencies.BDT.Name': [
            'Bangladeshi taka',
            'Taka'
        ],
        'System.Currencies.BGN.Name': [
            'Bulgarian lev',
            'Lew'
        ],
        'System.Currencies.BHD.Name': [
            'Bahraini dinar',
            'Bahrain-Dinar'
        ],
        'System.Currencies.BND.Name': [
            'Brunei dollar',
            'Brunei-Dollar'
        ],
        'System.Currencies.BOB.Name': [
            'Bolivian boliviano',
            'Boliviano'
        ],
        'System.Currencies.BRL': [
            'R$',
            ''
        ],
        'System.Currencies.BRL.Name': [
            'Brazilian Real',
            'Brasilianischer Real'
        ],
        'System.Currencies.BWP.Name': [
            'Botswana pula',
            'Pula'
        ],
        'System.Currencies.BYR.Name': [
            'Belarusian ruble',
            'Weißrussischer Rubel'
        ],
        'System.Currencies.CAD.Name': [
            'Canadian dollar',
            'Kanadischer Dollar'
        ],
        'System.Currencies.CHF': [
            'CHF',
            ''
        ],
        'System.Currencies.CHF.Name': [
            'Swiss Franc',
            'Schweizer Franken'
        ],
        'System.Currencies.CLP.Name': [
            'Chilean peso',
            'Chilenischer Peso'
        ],
        'System.Currencies.CNY': [
            '¥',
            ''
        ],
        'System.Currencies.CNY.Name': [
            'Chinese Yuan Renminbi',
            'Chinesischer Renminbi Yuan'
        ],
        'System.Currencies.COP.Name': [
            'Colombian peso',
            'Kolumbianischer Peso'
        ],
        'System.Currencies.CRC.Name': [
            'Costa Rican colon',
            'Costa-Rica Colón'
        ],
        'System.Currencies.CZK': [
            'Kč',
            ''
        ],
        'System.Currencies.CZK.Name': [
            'Czech koruna',
            'Tschechische Krone'
        ],
        'System.Currencies.DefaultSymbol': [
            '€',
            ''
        ],
        'System.Currencies.DKK': [
            'kr',
            ''
        ],
        'System.Currencies.DKK.Name': [
            'Danish krone',
            'Dänische Krone'
        ],
        'System.Currencies.DOP.Name': [
            'Dominican peso',
            'Dominikanischer Peso'
        ],
        'System.Currencies.DZD.Name': [
            'Algerian dinar',
            'Algerischer Dinar'
        ],
        'System.Currencies.EGP.Name': [
            'Egyptian pound',
            'Ägyptisches Pfund'
        ],
        'System.Currencies.ETB.Name': [
            'Ethiopian birr',
            'Birr'
        ],
        'System.Currencies.EUR': [
            '€',
            ''
        ],
        'System.Currencies.EUR.Name': [
            'Euro',
            ''
        ],
        'System.Currencies.GBP': [
            '£',
            ''
        ],
        'System.Currencies.GBP.Name': [
            'British Pound',
            'Britisches Pfund'
        ],
        'System.Currencies.GHS.Name': [
            'Ghanaian cedi',
            'Ghanaischer Cedi'
        ],
        'System.Currencies.GTQ.Name': [
            'Guatemalan quetzal',
            'Quetzal'
        ],
        'System.Currencies.HKD.Name': [
            'Hong Kong dollar',
            'Hongkong-Dollar'
        ],
        'System.Currencies.HNL.Name': [
            'Honduran lempira',
            'Lempira'
        ],
        'System.Currencies.HRK.Name': [
            'Croatian kuna',
            'Kuna'
        ],
        'System.Currencies.HUF': [
            'Ft',
            ''
        ],
        'System.Currencies.HUF.Name': [
            'Hungarian forint',
            'Forint'
        ],
        'System.Currencies.IDR.Name': [
            'Indonesian rupiah',
            'Indonesische Rupiah'
        ],
        'System.Currencies.ILS.Name': [
            'Israeli new sheqel',
            'Neuer Schekel'
        ],
        'System.Currencies.INR': [
            '₹',
            ''
        ],
        'System.Currencies.INR.Name': [
            'Indian Rupee',
            'Indische Rupie'
        ],
        'System.Currencies.IRR.Name': [
            'Iranian rial',
            'Iranischer Rial'
        ],
        'System.Currencies.ISK.Name': [
            'Icelandic krona',
            'Isländische Krone'
        ],
        'System.Currencies.JOD.Name': [
            'Jordanian dinar',
            'Jordanischer Dinar'
        ],
        'System.Currencies.JPY.Name': [
            'Japanese yen',
            'Yen'
        ],
        'System.Currencies.KES.Name': [
            'Kenyan shilling',
            'Kenia-Schilling'
        ],
        'System.Currencies.KRW.Name': [
            'South Korean won',
            'Südkoreanischer Won'
        ],
        'System.Currencies.KWD.Name': [
            'Kuwaiti dinar',
            'Kuwait-Dinar'
        ],
        'System.Currencies.KZT.Name': [
            'Kazakhstani tenge',
            'Tenge'
        ],
        'System.Currencies.LKR.Name': [
            'Sri Lankan rupee',
            'Sri Lanka-Rupie'
        ],
        'System.Currencies.LSL.Name': [
            'Lesotho loti',
            'Loti'
        ],
        'System.Currencies.LYD.Name': [
            'Libyan dinar',
            'Libyscher Dinar'
        ],
        'System.Currencies.MAD.Name': [
            'Moroccan dirham',
            'Marokkanischer Dirham'
        ],
        'System.Currencies.MUR.Name': [
            'Mauritian rupee',
            'Mauritius-Rupie'
        ],
        'System.Currencies.MWK.Name': [
            'Malawian kwacha',
            'Malawi-Kwacha'
        ],
        'System.Currencies.MXN': [
            '$',
            ''
        ],
        'System.Currencies.MXN.Name': [
            'Mexican Peso',
            'Mexikanischer Peso'
        ],
        'System.Currencies.MYR.Name': [
            'Malaysian ringgit',
            'Ringgit'
        ],
        'System.Currencies.MZN.Name': [
            'Mozambican metical',
            'Metical'
        ],
        'System.Currencies.NAD.Name': [
            'Namibian dollar',
            'Namibischer Dollar'
        ],
        'System.Currencies.NGN.Name': [
            'Nigerian naira',
            'Naira'
        ],
        'System.Currencies.NIO.Name': [
            'Nicaraguan cordoba',
            'Córdoba Oro'
        ],
        'System.Currencies.NOK.Name': [
            'Norwegian krone',
            'Norwegische Krone'
        ],
        'System.Currencies.NZD.Name': [
            'New Zealand dollar',
            'Neuseeland-Dollar'
        ],
        'System.Currencies.OMR.Name': [
            'Omani rial',
            'Omanischer Rial'
        ],
        'System.Currencies.PEN.Name': [
            'Peruvian nuevo sol',
            'Nuevo Sol'
        ],
        'System.Currencies.PHP.Name': [
            'Philippine peso',
            'Philippinischer Peso'
        ],
        'System.Currencies.PKR.Name': [
            'Pakistani rupee',
            'Pakistanische Rupie'
        ],
        'System.Currencies.PLN.Name': [
            'Polish zloty',
            'Polnische Zloty'
        ],
        'System.Currencies.QAR.Name': [
            'Qatari riyal',
            'Katar-Riyal'
        ],
        'System.Currencies.RON.Name': [
            'Danish krone',
            'Dänische Krone'
        ],
        'System.Currencies.RSD.Name': [
            'Serbian dinar',
            'Serbischer Dinar'
        ],
        'System.Currencies.RUB': [
            '₽',
            ''
        ],
        'System.Currencies.RUB.Name': [
            'Russian Ruble',
            'Russischer Rubel'
        ],
        'System.Currencies.SAR.Name': [
            'Saudi riyal',
            'Saudi-Rial'
        ],
        'System.Currencies.SEK': [
            'kr',
            ''
        ],
        'System.Currencies.SEK.Name': [
            'Swedish krona',
            'Schwedische Krone'
        ],
        'System.Currencies.SGD.Name': [
            'Singapore dollar',
            'Singapur-Dollar'
        ],
        'System.Currencies.SVC.Name': [
            'Salvadoran colón',
            'Colón'
        ],
        'System.Currencies.SZL.Name': [
            'Swazi lilangeni',
            'Lilangeni'
        ],
        'System.Currencies.THB.Name': [
            'Thai baht',
            'Baht'
        ],
        'System.Currencies.TND.Name': [
            'Tunisian dinar',
            'Tunesischer Dinar'
        ],
        'System.Currencies.TRY.Name': [
            'Turkish lira',
            'Neue Lira'
        ],
        'System.Currencies.TWD.Name': [
            'New Taiwan dollar',
            'Taiwan-Dollar'
        ],
        'System.Currencies.TZS.Name': [
            'Tanzanian shilling',
            'Tansania-Schilling'
        ],
        'System.Currencies.UAH.Name': [
            'Ukrainian hryvnia',
            'Hrywna'
        ],
        'System.Currencies.USD': [
            '$',
            ''
        ],
        'System.Currencies.USD.Name': [
            'US Dollar',
            'US Dollar'
        ],
        'System.Currencies.UYU.Name': [
            'Uruguayan peso',
            'Uruguayischer Peso'
        ],
        'System.Currencies.UZS.Name': [
            'Uzbekistani som',
            'So´m'
        ],
        'System.Currencies.VEF.Name': [
            'Venezuelan bolivar',
            'Bolívar fuerte'
        ],
        'System.Currencies.VND.Name': [
            'Vietnamese dong',
            'Dong'
        ],
        'System.Currencies.YER.Name': [
            'Yemeni rial',
            'Jemen-Rial'
        ],
        'System.Currencies.ZAR': [
            'R',
            ''
        ],
        'System.Currencies.ZAR.Name': [
            'South African Rand',
            'Südafrikanischer Rand'
        ],
        'System.Currencies.ZMW.Name': [
            'Zambian kwacha',
            'Sambischer Kwacha'
        ],
        'System.CustomerPrice': [
            'Customer Price',
            'Kundenpreis'
        ],
        'System.DefineOperatingHoursInRange': [
            'Define operating hours within the range',
            'Bringen Sie den Wert für Betriebsstunden / Tag in den gültigen Wertebereich,'
        ],
        'System.Delete': [
            'Delete',
            'Löschen'
        ],
        'System.DesignType': [
            'Design type',
            'Aufbauform'
        ],
        'System.Difference': [
            'Difference',
            'Differenz'
        ],
        'System.Discard': [
            'Discard',
            'Verwerfen'
        ],
        'System.DisclaimerText': [
            'The displayed results are non-binding values. The actual results depend on the specific conditions of use and may vary considerably. Siemens assumes no warranty or liability whatsoever for the correctness or feasibility of the displayed results.    ',
            'Die dargestellten Ergebnisse sind unverbindliche Werte. Die tatsächlichen Werte hängen von den konkreten Einsatzbedingungen ab und können daher deutlich variieren. Siemens haftet nicht für die Richtigkeit oder Realisierbarkeit der Ergebnisse'
        ],
        'System.Discount': [
            'Discount',
            'Rabatt'
        ],
        'System.DiscountUnit': [
            '%',
            ''
        ],
        'System.DriveSystem': [
            'Drive System',
            'Antriebssystem'
        ],
        'System.Efficiency': [
            'Efficiency',
            'Wirkungsgrad'
        ],
        'System.EfficiencyAtRatedLoad': [
            'Efficiency at rated load',
            'Wirkungsgrad am Bemessungspunkt'
        ],
        'System.EfficiencyClass.Label': [
            'System efficiency class',
            'Systemwirkungsgradklasse'
        ],
        'System.EfficiencyNn': {
            '0': [
                'Efficiency n',
                'Wirkungsgrad nN'
            ],
            '1': [
                'N',
                ''
            ]
        },
        'System.EfficiencySign': {
            '0': [
                'η',
                ''
            ],
            '1': [
                'N',
                ''
            ]
        },
        'System.EnergyCosts': [
            'Energy costs',
            'Energiekosten'
        ],
        'System.EnergyCostsAlternativeSystem': [
            'Energy costs Alternative system',
            'Energiekosten Alternativsystem'
        ],
        'System.EnergyCostsPer': [
            'Energy costs per',
            'Energiekosten in'
        ],
        'System.EnergyCostsPerLifetime': [
            'Energy costs per lifetime',
            'Energiekosten während des Lebenszykluses'
        ],
        'System.EnergyCostsReferenceSystem': [
            'Energy costs Reference system',
            'Energiekosten Referenzsystem'
        ],
        'System.EnergyCostsSavings': [
            'Energy costs savings',
            'Energiekosteneinsparung'
        ],
        'System.EnergyDemand': [
            'Energy Demand',
            'Voraussichtlicher Energiebedarf'
        ],
        'System.EnergyPerformance': [
            'Energy performance',
            'Monatliche Kosten'
        ],
        'System.EnergyPrice': [
            'Energy price',
            'Energiepreis'
        ],
        'System.EnergySavingPotential': [
            'Energy saving potential',
            'Energiesparpotential'
        ],
        'System.EnergySavings': [
            'Energy savings',
            'Energieeinsparung'
        ],
        'System.EnPC': [
            'Contracting',
            ''
        ],
        'System.EnpcInfo.Title': [
            'Energy Performance Contracting',
            'Energy Performance Contracting (EnPC)'
        ],
        'System.ExpectedEnergyCost': [
            'Expected energy costs for 1 year',
            'Voraussichtliche Energiekosten für ein Jahr'
        ],
        'System.ExpectedEnergyCostForXYears': [
            'Expected energy costs for {{x}} years',
            'Voraussichtliche Energiekosten für {{x}} Jahre'
        ],
        'System.ExpectedEnergyCostSaving': [
            'Expected energy costs savings',
            'Erwartete jährliche Energiekostenersparnis'
        ],
        'System.ExpectedEnergyCostSavingIn1Year': [
            'Expected energy costs savings in 1 year',
            'Erwartete jährliche Energiekostenersparnis '
        ],
        'System.ExpectedEnergyCostSavingInXYears': [
            'Expected energy costs savings in {{x}} years',
            'Erwartete Energiekostenersparnis in {{x}} Jahren'
        ],
        'System.ExpectedEnergyDemand': [
            'Expected energy demand',
            'Voraussichtlicher Energiebedarf'
        ],
        'System.ExtendedView': [
            'Extended view',
            'Erweiterte Ansicht'
        ],
        'System.Fan': [
            'Fan',
            'Lüfter'
        ],
        'System.Fan.Density': [
            'Density',
            'Dichte'
        ],
        'System.Fan.FanType': [
            'Fan type',
            'Bezeichnung des Lüfters'
        ],
        'System.Fan.Medium': [
            'Medium',
            'Medium'
        ],
        'System.Fan.SpecificSpeed': [
            'Specific speed',
            'Spezifische Drehzahl'
        ],
        'System.Fan.Speed': [
            'Fan speed',
            'Drehzahl des Lüfters'
        ],
        'System.Fan.Stage': [
            'Fan stage',
            'Anzahl der Lüfterstufen'
        ],
        'System.Fan.StaticTotalPressureDifference': [
            'Static total pressure difference',
            'Statische Totaldruckdifferenz'
        ],
        'System.Fan.TotalPressureDifference': [
            'Total pressure difference',
            'Totaldruckdifferenz'
        ],
        'System.Fan.VolumeFlow': [
            'Volume flow',
            'Volumenstrom'
        ],
        'System.FanApplication': [
            'Fan',
            'Lüfter'
        ],
        'System.FanApplicationEdit': [
            'Fan application edit',
            'Editiere Lüfter'
        ],
        'System.FanCommercialExplanation': {
            '0': [
                'The ',
                'Die'
            ],
            '1': [
                'commercial view',
                ' Kommerzielle Sicht'
            ],
            '2': [
                ' compares the investment costs and, where appropriate, a funding of efficient drive systems for fans with the costs of the reference system. SinaSave calculates the',
                ' stellt die Investitionskosten und gegebenenfalls Förderungen von effizienten Lüfter-Antriebssystemen den Kosten des Referenzsystems gegenüber. SinaSave ermittelt aus Investitions- und Energiekosten '
            ],
            '3': [
                ' expected payback',
                'die zu erwartende Amortisationszeit'
            ],
            '4': [
                ' time from investment and energy costs.',
                '.'
            ]
        },
        'System.Fans': [
            'Fans',
            ''
        ],
        'System.FanSelection': [
            'Fan selection',
            'Auswahl Lüfter'
        ],
        'System.FanSpeedN': [
            'Fan speed n',
            'Lüfterdrehzahl n'
        ],
        'System.FanTechnicalExplanation': {
            '0': [
                'The',
                'Die '
            ],
            '1': [
                ' technical view',
                'Technische Sicht'
            ],
            '2': [
                ' offers the possibility to compare various basic configurations of efficient drive systems for fans with a reference system and to determine energy saving potentials and CO2 savings potentials. The power loss calculation representation shows the specific values ​​of the ',
                ' bietet die Möglichkeit, verschiedene Grundkonfigurationen von effizienten Lüfter-Antriebssystemen einem Referenzsystem gegenüber zu stellen und Einsparpotentiale für Energie und CO2 zu ermitteln. Der Darstellung Verlustleistungsberechnung können die konkreten Werte der '
            ],
            '3': [
                'system power losses according to IEC 61800-9-2 ',
                'Systemverlustleistungen nach IEC 61800-9-2'
            ],
            '4': [
                'of the drive systems at full load and part load.',
                ' der Antriebssysteme bei Volllast und Teillast entnommen werden.'
            ]
        },
        'System.FinancialSavingPotential': [
            'Financial saving potential',
            'Einsparpotential'
        ],
        'System.FinancingCosts': [
            'Financing costs',
            'Finanzierungskosten'
        ],
        'System.Flowrate': [
            'Flowrate',
            'Förderrate'
        ],
        'System.GovernmentFunding': [
            'Government Funding',
            'Staatliche Förderung'
        ],
        'System.GrantRate': [
            'Grant Rate',
            'Förderrate'
        ],
        'System.GrantSum': [
            'Grant Sum',
            'Fördersumme'
        ],
        'System.Ies.Choke': [
            'Choke',
            'Drossel'
        ],
        'System.Ies.DriveSystemSettings': [
            'Operating parameter of the drive systems',
            'Betriebsparameter der Antriebssysteme'
        ],
        'System.Ies.ModulationMode': [
            'Modulation mode',
            'Modulationsart'
        ],
        'System.Ies.ModulationMode.0AutomChangeoverSVMFLB': [
            'Changeover SVM/FLB',
            ''
        ],
        'System.Ies.ModulationMode.RZMFLB': [
            'RZM/FLB',
            ''
        ],
        'System.Ies.PulseFrequency': [
            'Pulse frequency',
            'Pulsfrequenz'
        ],
        'System.InfoCard.Case1A': [
            'The Alternative System is the more economical solution. The investment payback is within the planned operation time.',
            'Das Alternativsystem ist die wirtschaftlichere Lösung. Die Amortisation der Investition erfolgt innerhalb der geplanten Betriebszeit.'
        ],
        'System.InfoCard.Case1B': [
            'According to the set operation time of {{x}} years, the Reference System is more economical. For the Alternative System to be more economical an operation time of approximately {{paybacktime}} years is required.',
            'Entsprechend der gewählten Betriebszeit von {{x}} Jahren ist das Referenzsystem wirtschaftlicher. Es ist eine Betriebszeit von ungefähr {{paybacktime}} Jahren erforderlich bis das Alternativsystem wirtschaftlicher als das Referenzsystem wird.'
        ],
        'System.InfoCard.Case2': [
            'Comparing the Reference System and Alternative System, the Alternative System is from the first day the more economical solution. The required investment and the energy costs are  lower than those of the Reference System.',
            'Im Vergleich von Referenzsystem und Alternativsystem ist das Alternativsystem vom ersten Tag an die wirtschaftlichere Lösung. Die erforderliche Investition und die Energiekosten sind geringer als die des Referenzsystems.'
        ],
        'System.InfoCard.Case3': [
            'Comparing the Reference System and Alternative System, the Reference System is the more economical solution. The required investment and the energy costs are lower than those of the Alternative System.',
            'Im Vergleich von Referenzsystem und Alternativsystem ist das Referenzsystem die wirtschaftlichere Lösung. Die erforderliche Investition und die Energiekosten sind geringer als die des Alternativsystems.'
        ],
        'System.InfoCard.Case4A': [
            'The Reference System is the more economical solution. The investment payback is within the planned operation time.',
            'Das Referenzsystem ist die wirtschaftlichere Lösung. Die Amortisation der Investition erfolgt innerhalb der geplanten Betriebszeit.'
        ],
        'System.InfoCard.Case4B': [
            'Until an operation time of {{paybacktime}} years, the Alternative system is the more economical. For longer lifetimes the Reference System is in advantage because of being more efficient.',
            'Bis zu einer Betriebszeit von ungefähr {{paybacktime}} Jahren ist das Alternativsystem das wirtschaftlichere. Bei längeren Betriebszeiten ist das Referenzsystem im Vorteil, da es das effizientere System ist.'
        ],
        'System.Installation': [
            'Installation',
            'Installation'
        ],
        'System.Interest': [
            'Interest',
            'Zinssatz'
        ],
        'System.Investment': [
            'Required investment',
            'Erforderliche Investition'
        ],
        'System.InvestmentDifference': [
            'Investment difference',
            'Investitionsunterschied'
        ],
        'System.LifetimeCosts': [
            'Lifetime costs',
            'Lebenszykluskosten'
        ],
        'System.Line': [
            'Line supply',
            'Netzversorgung'
        ],
        'System.LineSupply': [
            'Line supply',
            'Netzversorgung'
        ],
        'System.ListPrice': [
            'List price',
            'Listenpreis'
        ],
        'System.ListPriceUnitEuro': [
            '€',
            ''
        ],
        'System.Medium': [
            'Medium',
            ''
        ],
        'System.MediumEdit': [
            'Medium edit',
            'Medium bearbeiten'
        ],
        'System.MostEconomical': [
            'Commercial system result',
            'Ergebnis des kommerziellen Systemvergleiches'
        ],
        'System.MostEfficientInfoTitle': [
            'System efficiency result',
            'Ergebnis Systemeffizienzvergleich'
        ],
        'System.Motor': [
            'Motor',
            'Motor '
        ],
        'System.Motor.AsynchronousTechnology': [
            'Asynchronous technology',
            'Asynchrontechnologie'
        ],
        'System.Motor.DirectOnlineMotorConverterCapable': [
            'Direct online motor - converter capable',
            'Netzmotor - Umrichterfähig'
        ],
        'System.Motor.Efficiency': [
            'Efficiency',
            'Wirkungsgrad'
        ],
        'System.Motor.Efficiency1': [
            'Efficiency 1',
            'Wirkungsgrad 1'
        ],
        'System.Motor.Efficiency2': [
            'Efficiency 2',
            'Wirkungsgrad 2'
        ],
        'System.Motor.EfficiencyAccEff': [
            'Efficiency acc EFF',
            'Wirkungsgrad gemäß EFF'
        ],
        'System.Motor.EfficiencyAccIec': [
            'Efficiency acc IEC',
            'Wirkungsgrad gemäß IEC'
        ],
        'System.Motor.EfficiencyClass': [
            'Efficiency class',
            'Wirkungsgradklasse'
        ],
        'System.Motor.ExistingMotorConverterCapable': [
            'Existing motor - converter capable',
            'Vorhandener Motor - Umrichterfähig'
        ],
        'System.Motor.ExistingPoleChangingMotor': [
            'Existing pole-changing motor',
            'Bestehender polumschaltbarer Motor'
        ],
        'System.Motor.OptimizedForConverterOperation': [
            'Optimized for converter operation',
            'Optimiert für Umrichterbetrieb'
        ],
        'System.Motor.RatedSpeed': [
            'Rated speed',
            'Bemessungsdrehzahl'
        ],
        'System.Motor.SiemensInverterDuty': [
            'SIMOTICS - IEC Inverter Duty Motor',
            'SIMOTICS - IEC Umrichtermotor'
        ],
        'System.Motor.SiemensStandard': [
            'SIMOTICS - IEC Standard Motor',
            'SIMOTICS - IEC Standard Motor '
        ],
        'System.Motor.SynchronousReluctanceAndAsynchronousTechnology': [
            'Synchronous-reluctance and asynchronous technology',
            'Synchron-Reluktanz- und Asynchrontechnologie'
        ],
        'System.Motor.Technology': [
            'Technology',
            'Technologie'
        ],
        'System.MotorCommercialExplanation': {
            '0': [
                'The ',
                'Die '
            ],
            '1': [
                'commercial view',
                'Kommerzielle Sicht'
            ],
            '2': [
                ' of the motor comparison contrasts the investment costs or repair costs of the reference motor with the investment costs of the alternative motor. The tool determines the ',
                ' des Motorenvergleiches stellt die Investitionskosten bzw. Instandsetzungskosten des Referenzmotors den Investitionskosten des Alternativmotors gegenüber. SinaSave ermittelt '
            ],
            '3': [
                'expected total cost savings and the payback period',
                'die zu erwartende Gesamtkosteneinsparung und Amortisationszeit'
            ],
            '4': [
                '.',
                '.'
            ]
        },
        'System.MotorGovernmentRate': [
            'Government funding rate',
            'Förderrate'
        ],
        'System.MotorGovernmentSum': [
            'Government funding sum',
            'Fördersumme'
        ],
        'System.Motors': [
            'Motors',
            ''
        ],
        'System.Motors.AlternativeMotor': [
            'Alternative Motor',
            'Alternativmotor'
        ],
        'System.Motors.Casting': [
            'Casting',
            'Gehäusematerial'
        ],
        'System.Motors.Castings.Aluminium': [
            'Aluminum',
            'Aluminium'
        ],
        'System.Motors.Castings.CastIron': [
            'Cast Iron',
            'Grauguss'
        ],
        'System.Motors.Castings.GPAluminium': [
            'GP - Aluminum',
            'GP - Aluminium'
        ],
        'System.Motors.Castings.GPCastIron': [
            'GP - Cast Iron',
            'GP - Grauguss'
        ],
        'System.Motors.Castings.SDCastIron': [
            'SD - Cast Iron',
            'SD - Grauguss'
        ],
        'System.Motors.Efficiency': [
            'Efficiency',
            'Wirkungsgrad'
        ],
        'System.Motors.EfficiencyClass': [
            'Efficiency class',
            'Wirkungsgradklasse'
        ],
        'System.Motors.EfficiencySign': {
            '0': [
                'η',
                ''
            ],
            '1': [
                'N',
                ''
            ]
        },
        'System.Motors.HoursPerYear': [
            'Operation-hours / year',
            'Betriebsstunden / Jahr'
        ],
        'System.Motors.IgnitionProtection': [
            'Ignition protection class',
            'Zündschutzklasse'
        ],
        'System.Motors.IgnitionProtections.Without': [
            'without',
            'ohne'
        ],
        'System.Motors.LockedRotorCurrent': [
            'Starting current ratio',
            'Anlaufstromverhältnis'
        ],
        'System.Motors.LockedRotorCurrentSign': {
            '0': [
                'I',
                ''
            ],
            '1': [
                'A',
                ''
            ],
            '2': [
                '/I',
                ''
            ],
            '3': [
                'N',
                ''
            ]
        },
        'System.Motors.MotorLoad': [
            'Motor load',
            'Motorlast'
        ],
        'System.Motors.MotorProfile': [
            'Motor Profile',
            'Motorprofil'
        ],
        'System.Motors.Nema': [
            'SIMOTICS - NEMA Motor',
            'SIMOTICS - NEMA Motor '
        ],
        'System.Motors.PoleChanging.Efficiency1': [
            'Efficiency 1',
            'Wirkungsgrad 1'
        ],
        'System.Motors.PoleChanging.Efficiency1Sign': {
            '0': [
                'η',
                ''
            ],
            '1': [
                'N1',
                ''
            ]
        },
        'System.Motors.PoleChanging.Efficiency2': [
            'Efficiency 2',
            'Wirkungsgrad 2'
        ],
        'System.Motors.PoleChanging.Efficiency2Sign': {
            '0': [
                'η',
                ''
            ],
            '1': [
                'N2',
                ''
            ]
        },
        'System.Motors.PoleChanging.EfficiencyUnit': [
            '%',
            ''
        ],
        'System.Motors.PoleChanging.Speed1': [
            'Rated speed 1',
            'Nenndrehzahl 1'
        ],
        'System.Motors.PoleChanging.Speed1Sign': {
            '0': [
                'n',
                ''
            ],
            '1': [
                '1',
                ''
            ]
        },
        'System.Motors.PoleChanging.Speed2': [
            'Rated speed 2',
            'Nenndrehzahl 2'
        ],
        'System.Motors.PoleChanging.Speed2Sign': {
            '0': [
                'n',
                ''
            ],
            '1': [
                '2',
                ''
            ]
        },
        'System.Motors.PoleNumber': [
            'Pole number',
            'Polzahl'
        ],
        'System.Motors.ReferenceMotor': [
            'Reference Motor',
            'Referenzmotor'
        ],
        'System.Motors.RequiredPower': [
            'Power output',
            'Abgegebene Leistung'
        ],
        'System.Motors.SiemensConverter': [
            'SIMOTICS - Converter Motor',
            'SIMOTICS - Umrichtermotor'
        ],
        'System.Motors.SiemensLine': [
            'SIMOTICS - Line Motor',
            'SIMOTICS - Netzmotor'
        ],
        'System.Motors.Speed': [
            'Rated speed',
            'Bemessungsdrehzahl'
        ],
        'System.Motors.SpeedSign': {
            '0': [
                'n',
                ''
            ],
            '1': [
                'N',
                ''
            ]
        },
        'System.Motors.SpeedUnit': [
            '1/min',
            ''
        ],
        'System.Motors.SynchronousReluctance': [
            'SIMOTICS - Synchronous-reluctance',
            'SIMOTICS - Synchron-Reluktanz'
        ],
        'System.Motors.Technology': [
            'Technology',
            'Technologie'
        ],
        'System.MotorTechnicalExplanation': {
            '0': [
                'The',
                'Die '
            ],
            '1': [
                ' technical view ',
                'Technische Sicht'
            ],
            '2': [
                'of the motor comparison offers a direct comparison between two or more ',
                ' des Motorenvergleiches ermöglicht den direkten Vergleich zwischen zwei oder mehreren '
            ],
            '3': [
                'direct online motors ',
                'Netzmotoren'
            ],
            '4': [
                'of different energy efficiency classes. Possible ',
                ' verschiedener Energieeffizienzklassen. Auf Basis der Betriebsprofiles werden '
            ],
            '5': [
                'energy savings and CO2 savings',
                'Energie- und CO2-Einsparpotentiale'
            ],
            '6': [
                ' are determined based on the operating profile.',
                ' ermittelt.'
            ]
        },
        'System.MySavedProjectsExplanation': [
            'Here you will find all your saved projects',
            'Hier finden Sie alle Ihre gespeicherten Projekte'
        ],
        'System.NegativeAmortization': [
            'The alternative system is more efficient than the reference in terms of investment and/or energy costs.',
            'Das Referenzsystem ist im Vergleich zum Alternativsystem im Bezug auf Investitions- und/oder Energiekosten wirtschaftlicher.'
        ],
        'System.NegativeAmortizationText': [
            'The {{alt}}  is more efficient than the {{ref}}  in terms of investment and/or energy costs.',
            'Das {{alt}}  ist im Vergleich zum {{ref}}  im Bezug auf Investitions- und/oder Energiekosten wirtschaftlicher.'
        ],
        'System.NettoInvestment': [
            'Netto Investment',
            'Netto Investition'
        ],
        'System.NotAvailable': [
            'n/a',
            ''
        ],
        'System.Number': [
            'No.',
            'Nr.'
        ],
        'System.NumberShort': [
            'Nr.',
            ''
        ],
        'System.Open': [
            'Open',
            'Öffnen'
        ],
        'System.OperatingHoursPerYear': [
            'Operating hours / year',
            'Betriebsstunden / Jahr'
        ],
        'System.OperationHours': [
            'Operating hours',
            'Betriebsstunden'
        ],
        'System.OperationProfile': [
            'Operation Profile',
            'Betriebsprofil'
        ],
        'System.Or': [
            'or',
            'oder'
        ],
        'System.Originator': [
            'Originator',
            'Bearbeiter'
        ],
        'System.Payback': [
            'Amortization',
            'Amortisation'
        ],
        'System.PDS.Label': [
            'PDS IEC 61800-9-2',
            ''
        ],
        'System.PointOfInterest': [
            'Point of interest',
            'Frei gewählter Lastpunkt'
        ],
        'System.Power': [
            'Power',
            'Leistung'
        ],
        'System.PowerDemand': [
            'Power demand',
            'Leistungsaufnahme'
        ],
        'System.PowerLoss': [
            'Power Loss',
            'Verlustleistung'
        ],
        'System.PowerLossAtRequiredShaftPower': [
            'Show power loss at required shaft power',
            'Verlustleistung bei erforderlicher Wellenleistung'
        ],
        'System.PowerLossAtRequiredShaftPowerHeader': [
            'System power loss according IEC 61800-9-2',
            'Systemverlustleistung nach IEC 61800-9-2'
        ],
        'System.PowerLossCalculation': [
            'Power loss calculation',
            'Verlustleistungsberechnung'
        ],
        'System.PowerLossDifference': [
            'Difference',
            'Differenz'
        ],
        'System.PowerLosses': [
            'Power losses',
            'Verlustleistung'
        ],
        'System.PowerLossNotAvailable': [
            'not applicable to IEC 61800-9-2',
            'nicht anwendbar nach IEC 61800-9-2'
        ],
        'System.PowerLossStandard': [
            'System power loss according IEC 61800-9-2',
            'Systemverlustleistung nach IEC 61800-9-2'
        ],
        'System.PowerSign': {
            '0': [
                'P',
                ''
            ],
            '1': [
                'N',
                ''
            ]
        },
        'System.Price': [
            'Expenditure for repairs',
            'Instandsetzungskosten'
        ],
        'System.ProductPrice': [
            'Product price',
            'Produktpreis'
        ],
        'System.Project': [
            'Project',
            'Projekt'
        ],
        'System.ProjectExplanation': {
            '0': [
                'The',
                'Die '
            ],
            '1': [
                ' project view ',
                'Projektsicht'
            ],
            '2': [
                'enables the combined display of several motor and/or system comparisons in an overall evaluation. In this way, ',
                ' ermöglicht die '
            ],
            '3': [
                'the payback period',
                'zusammengefasste Darstellung'
            ],
            '4': [
                ' as well as ',
                ' mehrerer Motoren- und / oder Systemvergleiche zu einer '
            ],
            '5': [
                'the energy savings and cost savings potential',
                'Gesamtauswertung'
            ],
            '6': [
                ' of an ',
                '. So kann die Amortisationszeit sowie das Energie- und Kosteneinsparpotential eines Energieeffizienzprojektes abgeschätzt werden.'
            ],
            '7': [
                'energy efficiency project',
                ''
            ],
            '8': [
                ' can be estimated.',
                ''
            ]
        },
        'System.Pump': [
            'Pump',
            'Pumpe'
        ],
        'System.PumpCommercialExplanation': {
            '0': [
                'The',
                'Die '
            ],
            '1': [
                ' commercial view',
                'Kommerzielle Sicht'
            ],
            '2': [
                ' compares the investment costs and, where appropriate, a funding of efficient drive systems for pumps with the costs of the reference system. SinaSave calculates the ',
                ' stellt die Investitionskosten und gegebenenfalls Förderungen von effizienten Pumpen-Antriebssystemen den Kosten des Referenzsystems gegenüber. SinaSave ermittelt aus Investitions- und Energiekosten '
            ],
            '3': [
                'expected payback',
                'die zu erwartende Amortisationszeit.'
            ],
            '4': [
                ' time from investment and energy costs.',
                ''
            ]
        },
        'System.PumpHeadH': [
            'Pump head H',
            'Förderhöhe H'
        ],
        'System.PumpProfileEdit': [
            'Pump profile edit',
            'Pumpen Profil bearbeiten'
        ],
        'System.Pumps': [
            'Pumps',
            ''
        ],
        'System.Pumps.BlueAngel': [
            'Blue Angel',
            'Blauer Engel'
        ],
        'System.Pumps.DaysPerYear': [
            'Operation-days / year',
            'Betriebstage / Jahr'
        ],
        'System.Pumps.Density': [
            'Density',
            'Dichte'
        ],
        'System.Pumps.Efficiency': [
            'Efficiency',
            'Wirkungsgrad'
        ],
        'System.Pumps.Flowrate': [
            'Flowrate',
            'Förderrate'
        ],
        'System.Pumps.HoursPerDay': [
            'Operation-hours / day',
            'Betriebsstunden / Tag'
        ],
        'System.Pumps.Medium': [
            'Medium',
            'Medium'
        ],
        'System.Pumps.PumpDensity': [
            'Density',
            'Dichte'
        ],
        'System.Pumps.PumpFlow': [
            'Rated flow',
            'Nennförderstrom'
        ],
        'System.Pumps.PumpHead': [
            'Pump head',
            'Förderhöhe'
        ],
        'System.Pumps.PumpMedium': [
            'Medium',
            ''
        ],
        'System.Pumps.PumpStatHead': [
            'Static head',
            'Statische Förderhöhe '
        ],
        'System.Pumps.PumpType': [
            'Designation centrifugal pump',
            'Bezeichnung der Kreiselpumpe'
        ],
        'System.Pumps.ShaftPower': [
            'Required shaft power',
            'Benötigte Wellenleistung'
        ],
        'System.Pumps.SpecificSpeed': [
            'Specific speed',
            'Spezifische Drehzahl'
        ],
        'System.Pumps.Speed': [
            'Pump speed',
            'Drehzahl der Pumpe'
        ],
        'System.Pumps.Stage': [
            'Pump stage',
            'Anzahl der Pumpenstufen'
        ],
        'System.Pumps.WorkingHours': [
            'Operating hours',
            'Betriebsstunden'
        ],
        'System.PumpSelection': [
            'Pump selection',
            'Pumpenauswahl'
        ],
        'System.PumpSpeedN': [
            'Pump speed n',
            'Drehzahl der Pumpe n'
        ],
        'System.PumpTechnicalExplanation': {
            '0': [
                'The ',
                'Die '
            ],
            '1': [
                'technical view',
                'Technische Sicht'
            ],
            '2': [
                ' offers the possibility to compare various basic configurations of efficient drive systems for pumps with a reference system and to determine energy saving potentials and CO2 savings potentials. The power loss calculation representation shows the specific values ​​of the',
                ' bietet die Möglichkeit, verschiedene Grundkonfigurationen von effizienten Pumpen-Antriebssystemen einem Referenzsystem gegenüber zu stellen und Einsparpotentiale für Energie und CO2 zu ermitteln. Der Darstellung Verlustleistungsberechnung können die konkreten Werte der '
            ],
            '3': [
                ' system power losses according to IEC 61800-9-2',
                'Systemverlustleistungen nach IEC 61800-9-2 '
            ],
            '4': [
                ' of the drive systems at full load and part load.',
                'der Antriebssysteme bei Volllast und Teillast entnommen werden.'
            ]
        },
        'System.Quantity': [
            'Quantity',
            'Anzahl'
        ],
        'System.QuantityShort': [
            'Qty.',
            'Anz.'
        ],
        'System.RatedCurrent': [
            'Rated current',
            'Bemessungsstrom'
        ],
        'System.RatedCurrentIn': {
            '0': [
                'Rated current I',
                'Bessungsstrom IN'
            ],
            '1': [
                'N',
                ''
            ]
        },
        'System.RatedCurrentSign': {
            '0': [
                'I',
                ''
            ],
            '1': [
                'N',
                ''
            ]
        },
        'System.RatedCurrentUnit': [
            'A',
            ''
        ],
        'System.RatedFlowQ': [
            'Rated flow Q',
            'Nennförderstrom Q'
        ],
        'System.RatedPowerPn': {
            '0': [
                'Rated Power P',
                'Bemessungsleistung P'
            ],
            '1': [
                'N',
                'N'
            ]
        },
        'System.RatedSpeed': [
            'Rated speed 1',
            'Nenndrehzahl 1'
        ],
        'System.RatedSpeed2': [
            'Rated speed 2',
            'Nenndrehzahl 2'
        ],
        'System.ReferenceMoreEfficientInfoText': [
            'The Reference System is more efficient than the Alternative System. Please check the Commercial View to see which is the more economical system.',
            'Das Referenzsystem ist effizienter als das Alternativsystem. Bitte überprüfen Sie die Kommerzielle Sicht, um zu sehen, welches System das wirtschaftlichere ist.'
        ],
        'System.ReferencePDS': [
            'Reference PDS acc IEC 61800-9-2',
            'Referenz PDS nach IEC 61800-9-2'
        ],
        'System.ReferenceSystem': [
            'Reference System',
            'Referenzsystem'
        ],
        'System.RequiredShaftPowerP': [
            'Required shaft power P',
            'Erforderliche Wellenleistung P'
        ],
        'System.ResetFanToDefault': [
            'Reset Fan to default values',
            'Lüfter auf Ausgangswerte zurücksetzen'
        ],
        'System.ResetPumpToDefault': [
            'Reset Pump to default values',
            'Pumpe auf Ausgangswerte zurücksetzen'
        ],
        'System.Responsibles': [
            'Siemens Aktiengesellschaft: Chairman of the Supervisory Board: Jim Hagemann Snabe; Managing Board: Roland Busch, Chairman, President and Chief Executive Officer; Cedrik Neike, Matthias Rebellius, Ralf P. Thomas, Judith Wiese; Registered offices: Berlin and Munich, Germany; Commercial registries: Berlin-Charlottenburg, HRB 12300, Munich, HRB 6684; WEEE-Reg.-No. DE 23691322',
            'Siemens Aktiengesellschaft: Vorsitzender des Aufsichtsrats: Jim Hagemann Snabe; Vorstand: Joe Kaeser, Vorsitzender; Roland Busch, Lisa Davis, Klaus Helmrich, Janina Kugel, Cedrik Neike, Michael Sen, Ralf P. Thomas; Sitz der Gesellschaft: Berlin und München, Deutschland; Registergericht: Berlin Charlottenburg, HRB 12300, München, HRB 6684; WEEE-Reg.-Nr. DE 23691322'
        ],
        'System.Results': [
            'Results',
            'Ergebnisse'
        ],
        'System.RunningCosts': [
            'Running costs',
            'Laufende Kosten'
        ],
        'System.SaveAs': [
            'Save As',
            'Speichern unter'
        ],
        'System.SaveFirst': [
            'Would you like to save before leaving or discard your changes?',
            'Möchten Sie ihre Änderungen zuerst speichern oder die Seite verlassen und die Änderungen verwerfen?'
        ],
        'System.SaveRequiredText': {
            '0': [
                'To continue with the requested operation, the project has to be saved first.',
                'Um fortfahren zu können müssen Sie erst speichern.'
            ]
        },
        'System.SaveRequiredTitle': [
            'Your project is not saved yet',
            'Ihr Projekt ist noch nicht gespeichert.'
        ],
        'System.Savings': [
            'Savings',
            'Einsparungen'
        ],
        'System.Selection': [
            'Selection',
            'Auswahl'
        ],
        'System.SelectPredefinedProfile': [
            'Select a predefined profile',
            'Wählen Sie eines der vordefinierten Betriebsprofile'
        ],
        'System.Speed': [
            'Speed',
            'Drehzahl'
        ],
        'System.StaticHeadHstat': [
            'Static head Hstat',
            'Staischer Anteil der Förderhöhe Hstat'
        ],
        'System.StaticTotalPressureDifferenceDP': [
            'Static total pressure difference Δp',
            'Statischer Anteil der Totaldruckdifferenz Δp'
        ],
        'System.SumAdditionalInvestment': [
            'Sum Additional Investment',
            'Summe zusätzlicher Investitionen'
        ],
        'System.Switch': [
            'Switchgear',
            'Schaltgerät'
        ],
        'System.Switchgear': [
            'Switchgear',
            'Schaltgerät'
        ],
        'System.Switchgear.BasicType': [
            'Type',
            'Typ'
        ],
        'System.Switchgear.BasicTypes.DirectStarter': [
            'Motor Starter',
            'Motorstarter'
        ],
        'System.Switchgear.BasicTypes.SoftStarter': [
            'Soft Starter',
            'Sanftstarter'
        ],
        'System.Switchgear.BasicTypes.Triangle': [
            'Star-Delta Combination',
            'Stern-Dreieck-Kombination'
        ],
        'System.Switchgear.PoleChanging.Powerlosses1': [
            'Power losses 1',
            'Verlustleistung 1'
        ],
        'System.Switchgear.PoleChanging.Powerlosses2': [
            'Power losses 2',
            'Verlustleistung 2'
        ],
        'System.Switchgear.Powerlosses': [
            'Power losses',
            'Verlustleistung'
        ],
        'System.Switchgear.PowerLosses1': [
            'Power losses 1',
            'Verlustleistung 1'
        ],
        'System.Switchgear.PowerLosses2': [
            'Power losses 2',
            'Verlustleistung 2'
        ],
        'System.Switchgear.PowerlossesUnit': [
            'W',
            ''
        ],
        'System.Switchgear.RatedPower': [
            'Rated power',
            'Typleistung'
        ],
        'System.Switchgear.Siemens': [
            'SIRIUS',
            ''
        ],
        'System.Switchgear.ThirdParty': [
            'Third party switchgear',
            'Fremdschaltgerät'
        ],
        'System.Torque': [
            'Torque',
            'Drehmoment'
        ],
        'System.TotalCostSavings': [
            'Total cost savings',
            'Gesamtkosteneinsparung'
        ],
        'System.TotalCostsOfOwnership': [
            'Total costs of Ownership (TCO)',
            'Gesamtbetriebskosten (TCO)'
        ],
        'System.TotalCostsWithinXYearsYInterest': [
            'Total costs within {{years}} at {{interest}}% interest',
            'Gesamtkosten in {{years}} bei einem Zinssatz von {{interest}} %'
        ],
        'System.TotalInvestment': [
            'Total Investment',
            'Summe'
        ],
        'System.TotalPressureDifferenceDP': [
            'Total pressure difference Δp',
            'Totaldruckdifferenz Δp'
        ],
        'System.UnequalRatedPower': [
            'Rated power of both drive systems are in this case different',
            'Die Bemessungsleistungen beider Antriebssysteme ist in diesem Fall unterschiedlich!'
        ],
        'System.Units.Ampere': [
            'A',
            ''
        ],
        'System.Units.Annual': [
            'a',
            ''
        ],
        'System.Units.Cubicmetersperhours': {
            '0': [
                'm',
                ''
            ],
            '1': [
                '3',
                ''
            ],
            '2': [
                '/h',
                ''
            ]
        },
        'System.Units.Cubicmetersperseconds': {
            '0': [
                'm',
                ''
            ],
            '1': [
                '3',
                ''
            ],
            '2': [
                '/s',
                ''
            ]
        },
        'System.Units.Daysperannual': [
            'd/a',
            ''
        ],
        'System.Units.Gigawatt': [
            'GW',
            ''
        ],
        'System.Units.Gigawatthour': [
            'GWh',
            ''
        ],
        'System.Units.Gigawatthourperannual': [
            'GWh/a',
            ''
        ],
        'System.Units.Gramsperkilowatthour': [
            'g/kWh',
            ''
        ],
        'System.Units.Hertz': [
            'Hz',
            ''
        ],
        'System.Units.Hours': [
            'h',
            ''
        ],
        'System.Units.Hoursperannual': [
            'h/a',
            ''
        ],
        'System.Units.Hoursperday': [
            'h/d',
            ''
        ],
        'System.Units.HP': [
            'hp',
            ''
        ],
        'System.Units.Kilogramsperannual': [
            'kg/a',
            ''
        ],
        'System.Units.Kilogramspercubicmeter': {
            '0': [
                'kg/m',
                ''
            ],
            '1': [
                '3',
                ''
            ]
        },
        'System.Units.KiloHertz': [
            'kHz',
            ''
        ],
        'System.Units.Kilovolt': [
            'kV',
            ''
        ],
        'System.Units.Kilowatt': [
            'kW',
            ''
        ],
        'System.Units.Kilowatthour': [
            'kWh',
            ''
        ],
        'System.Units.Kilowatthourperannual': [
            'kWh/a',
            ''
        ],
        'System.Units.kWPerHp': {
            '0': [
                'kW ',
                ''
            ],
            '1': [
                'hp\n',
                ''
            ]
        },
        'System.Units.Mbar': [
            'mbar',
            ''
        ],
        'System.Units.Megawatt': [
            'MW',
            ''
        ],
        'System.Units.Megawatthour': [
            'MWh',
            ''
        ],
        'System.Units.Megawatthourperannual': [
            'MWh/a',
            ''
        ],
        'System.Units.Month': [
            'mo.',
            'M.'
        ],
        'System.Units.Oneperminutes': [
            '1/min',
            ''
        ],
        'System.Units.Percentage': [
            '%',
            ''
        ],
        'System.Units.Tonnsperannual': [
            't/a',
            ''
        ],
        'System.Units.Volt': [
            'V',
            ''
        ],
        'System.Units.Watt': [
            'W',
            ''
        ],
        'System.UnsavedChanges': [
            'Unsaved changes',
            'Nicht gespeicherte Eingaben'
        ],
        'System.UnsavedComparison': [
            'unsavedComparison',
            'ungespeicherterVergleich'
        ],
        'System.UnsavedProject': [
            'unsavedProject',
            'ungespeichertesProjekt'
        ],
        'System.UserDefined': [
            'User-defined',
            'Benutzerdefiniert'
        ],
        'System.Year': [
            'year',
            'Jahr'
        ],
        'System.Years': [
            'years',
            'Jahre'
        ],
        'test.co2': {
            '0': [
                'co2',
                ''
            ],
            '1': [
                'Test ',
                ''
            ]
        },
        'Test.Import.Test': [
            'Import test',
            ''
        ],
        'Tooltip.AddToFavorites': [
            'Add to favorites',
            'Zu Favoriten hinzufügen'
        ],
        'Tooltip.ComparisonMore': [
            'Open, Save As and Reset',
            'Öffnen, speichern unter und zurücksetzen'
        ],
        'Tooltip.Contact': [
            'Contact',
            'Kontakt'
        ],
        'Tooltip.Download': [
            'Download',
            ''
        ],
        'Tooltip.Downloads': [
            'Downloads',
            ''
        ],
        'Tooltip.Investment': [
            'Custom Additional Investment',
            'Zusätzliche Investitionskosten bearbeiten'
        ],
        'Tooltip.Language': [
            'Language',
            'Sprache'
        ],
        'Tooltip.Login': [
            'Login',
            ''
        ],
        'Tooltip.MyDashboard': [
            'My Dashboard',
            'Mein Dashboard'
        ],
        'Tooltip.MySavedMore': [
            'Save As and Delete',
            'Speichern unter und Löschen'
        ],
        'Tooltip.Open': [
            'Open',
            'Öffnen'
        ],
        'Tooltip.Profile': [
            'Profile',
            'Profil'
        ],
        'Tooltip.ProjectComparisonMore': [
            'Delete, Share Results and Download comparison',
            'Löschen, Ergebnis teilen und Vergleich herunterladen'
        ],
        'Tooltip.ProjectMore': [
            'Save As',
            'Speichern unter'
        ],
        'Tooltip.RemoveFromFavorites': [
            'Remove from favorites',
            'Von Favoriten entfernen'
        ],
        'Tooltip.Reset': [
            'Reset',
            'Zurücksetzen'
        ],
        'Tooltip.Save': [
            'Save',
            'Speichern'
        ],
        'Tooltip.Settings': [
            'Settings',
            'Einstellungen'
        ],
        'Tooltip.Share': [
            'Share',
            'Teilen'
        ],
        'Unit.Annual': [
            'a',
            ''
        ],
        'Unit.Day': [
            'd',
            ''
        ],
        'Unit.DaysperYear': [
            'd/y',
            ''
        ],
        'Unit.Gigawatthour': [
            'GWh',
            ''
        ],
        'Unit.GramsPerKiloWattHour': [
            'g / kWh',
            ''
        ],
        'Unit.Hertz': [
            'Hz',
            ''
        ],
        'Unit.Hour': [
            'h',
            ''
        ],
        'Unit.HoursperAnnual': [
            'h/a',
            ''
        ],
        'Unit.HoursperDay': [
            'h/d',
            ''
        ],
        'Unit.HP': [
            'hp',
            ''
        ],
        'Unit.Kilogram': [
            'kg',
            ''
        ],
        'Unit.KiloHertz': [
            'kHz',
            ''
        ],
        'Unit.Kilovolt': [
            'kV',
            ''
        ],
        'Unit.Kilowatthour': [
            'kWh',
            ''
        ],
        'Unit.KW': [
            'kW',
            ''
        ],
        'Unit.Megawatthour': [
            'MWh',
            ''
        ],
        'Unit.Month': [
            'mo.',
            'M.'
        ],
        'Unit.Months': [
            'months',
            'Monate'
        ],
        'Unit.Percentage': [
            '%',
            ''
        ],
        'Unit.Terawatthour': [
            'TWh',
            ''
        ],
        'Unit.Ton': [
            't',
            ''
        ],
        'Unit.Volt': [
            'V',
            ''
        ],
        'Unit.Year': [
            'year',
            'Jahr'
        ],
        'Unit.Years': [
            'years',
            'Jahre'
        ],
        'UpdateInformation.Attribute': [
            'Attribute',
            'Eigenschaft'
        ],
        'UpdateInformation.Continue': [
            'Continue',
            'Fortfahren'
        ],
        'UpdateInformation.PleaseVerify': [
            'Please check the comparison before updating!',
            'Bitte überprüfen sie den Vergleich nach der Aktualisierung!'
        ],
        'UpdateInformation.PreviousProduct': [
            'Previous product',
            'Bisheriges Produkt'
        ],
        'UpdateInformation.ProjectWarning': {
            '0': [
                'The project contains comparison with obsolete products, marked with a warning sign. ',
                'Dieses Projekt enthält Vergleiche mit veralteten Produkten. Diese sind durch das Warndreieck '
            ],
            '1': [
                " This comparison will not be considered in the project results, until it's updated.",
                ' gekennzeichnet. '
            ],
            '2': [
                'It is possible to store the current state (including the obsolete products) of the project as PDF.',
                ' Diese Vergleiche werden nicht in den Projektergebnissen berücksichtigt. Sie müssen erst aktuallisert werden.'
            ],
            '3': [
                'To update the affected comparison, please open ',
                'Es ist möglich den aktuellen Stand des Projektes  (inklusive veralteter Produkte) als PDF abzuspeichern.'
            ],
            '4': [
                ' it, press "Update" and save ',
                'Um die betroffenen Vergleiche zu aktualisieren, öffnen Sie bitte den Vergleich mit  '
            ],
            '5': [
                ' the comparison.',
                ', und klicken auf "Aktualisieren". Anschließend speichern '
            ],
            '6': [
                '',
                'Sie den Vergleich ab.'
            ]
        },
        'UpdateInformation.StoreAsPdf': [
            'Store as PDF',
            'Als PDF speichern'
        ],
        'UpdateInformation.Successor': [
            'Successor',
            'Nachfolgeprodukt'
        ],
        'UpdateInformation.Title': [
            'Update information',
            'Hinweis auf Aktualisierung'
        ],
        'UpdateInformation.Update': [
            'Update',
            'Aktualisieren'
        ],
        'UpdateInformation.Warning': {
            '0': [
                'The comparison ',
                'Der Vergleich '
            ],
            '1': [
                '{{ComparisonName}}',
                '{{ComparisonName}}'
            ],
            '2': [
                ' contains the following component(s) that will be replaced by a successor product. Please click ',
                ' enthält folgende Komponente(n), die durch ein Nachfolgeprodukt ersetzt werden. Bitte klicken Sie '
            ],
            '3': [
                'Store as PDF',
                'Als PDF speichern'
            ],
            '4': [
                ' to locally save the comparison and its results.   ',
                ' um den bisherigen vergleich und dessen Ergebnisse lokal zu speichern.   '
            ],
            '5': [
                'Update ',
                'Aktualisieren '
            ],
            '6': [
                ' replaces the previous component(s) by the appropriate successor product.',
                ' ersetzt die bisherige Komponente(n) durch das entsprechende Nachfolgeprodukt.'
            ]
        },
        'Warning.OutOfRegion': [
            'The shared project contains local product',
            'Das geteilte Projekt enthält lokale Produkte.'
        ],
        'Warning.OutOfRegion.Comparison': [
            'The comparison contains local product',
            'Der Vergleich enthält lokale Produkte.'
        ],
        'Warning.OutOfRegion.Comparison.WithCountryInfo': [
            'The comparison contains local product from {{country}}',
            'Der Vergleich enthält lokale Produkte für {{country}}'
        ],
        'Warning.OutOfRegion.Project': [
            'The project contains local product',
            'Das Projekt enthält lokale Produkte'
        ],
        'Warning.OutOfRegion.Project.WithCountryInfo': [
            'The project contains local product from {{country}}',
            'Das Projekt enthält lokale Produkte von {{country}}.'
        ],
        'Warning.OutOfRegion.WithCountryInfo': [
            'The shared project contains local product from {{country}}',
            'Das geteilte Projekt enthält lokale Produkte für {{country}}'
        ]
    },
    'cookie': {
        'notice': {
            '1': [
                'Status: December 2021',
                'Status: December 2021'
            ],
            '2': [
                'This Cookie Notice applies to the websites accessible under “siemens.com” (the “website”).',
                'This Cookie Notice applies to the websites accessible under “siemens.com” (the “website”).'
            ],
            '3': [
                'This website uses cookies. Cookies are small files containing certain information that are stored on your device. Cookies are served either by Siemens AG or Siemens Industry Software, Inc. (collectively “Siemens”) – so-called “first party cookies” – or by third parties providing services to Siemens; such cookies are referred to as “third party cookies”.',
                'This website uses cookies. Cookies are small files containing certain information that are stored on your device. Cookies are served either by Siemens AG or Siemens Industry Software, Inc. (collectively “Siemens”) – so-called “first party cookies” – or by third parties providing services to Siemens; such cookies are referred to as “third party cookies”.'
            ],
            '4': [
                'Cookies are necessary to provide certain functionalities on a website, e.g. to maintain a language choice made by a user. Moreover, cookies enable the respective issuer (Siemens or a third party) to recognize that the website was previously called up by a certain end device, allowing the identification of returning visitors (or rather their end devices) and gathering information about their usage of the website and their presumed interests.',
                'Cookies are necessary to provide certain functionalities on a website, e.g. to maintain a language choice made by a user. Moreover, cookies enable the respective issuer (Siemens or a third party) to recognize that the website was previously called up by a certain end device, allowing the identification of returning visitors (or rather their end devices) and gathering information about their usage of the website and their presumed interests.'
            ],
            '5': [
                'This information is used to improve the website and to deliver relevant advertisement for Siemens products and services on the website as well as on third party sites.',
                'This information is used to improve the website and to deliver relevant advertisement for Siemens products and services on the website as well as on third party sites.'
            ],
            '6': [
                'Cookies can be assigned to four categories, depending on their function and intended purpose: strictly necessary cookies, performance cookies, functionality cookies, and marketing cookies.',
                'Cookies can be assigned to four categories, depending on their function and intended purpose: strictly necessary cookies, performance cookies, functionality cookies, and marketing cookies.'
            ]

        },
        'necessaryTitle': [
            'Strictly Necessary Cookies',
            'Strictly Necessary Cookies'
        ],
        'necessaryDescription': {
            '1': [
                'These cookies are essential in order to enable you to move around the website and use its features, such as setting your privacy preferences, logging in or filling in forms. Without these cookies, services requested through usage of our website cannot be properly provided.',
                'These cookies are essential in order to enable you to move around the website and use its features, such as setting your privacy preferences, logging in or filling in forms. Without these cookies, services requested through usage of our website cannot be properly provided.'
            ],
            '2': [
                'Strictly necessary cookies do not require consent from the user under applicable law. You may configure your web browser to block strictly necessary cookies, but you might then not be able to use the website’s functionalities as intended.',
                'Strictly necessary cookies do not require consent from the user under applicable law. You may configure your web browser to block strictly necessary cookies, but you might then not be able to use the website’s functionalities as intended.'
            ],
            '3': [
                'To the extent that information processed in connection with strictly necessary cookies should qualify as personal data in a given case, the legal ground for that processing is Siemens’ legitimate interest to operate the website.',
                'To the extent that information processed in connection with strictly necessary cookies should qualify as personal data in a given case, the legal ground for that processing is Siemens’ legitimate interest to operate the website.'
            ]
        },
        'performanceTitle': [
          'Performance Cookies',  
          'Performance Cookies'  
        ],
        'performanceDescription': {
            '1':[
                'These cookies collect information about how visitors use a website, for instance which pages visitors go to most often, and how visitors move around the site. They help us to better understand a user’s behavior on our webpage. This is necessary to improve the user friendliness of a website and therefore enhance the user’s experience.',
                'These cookies collect information about how visitors use a website, for instance which pages visitors go to most often, and how visitors move around the site. They help us to better understand a user’s behavior on our webpage. This is necessary to improve the user friendliness of a website and therefore enhance the user’s experience.'
            ],
            '2':[
                'Further information collected by performance cookies may include e.g.: internet browser and operating system used, the domain name of the website which you previously visited, the number of visits, average duration of visit, and pages called up.',
                'Further information collected by performance cookies may include e.g.: internet browser and operating system used, the domain name of the website which you previously visited, the number of visits, average duration of visit, and pages called up.'
            ],
            '3':[
                'In general, the information collected by these cookies is aggregated and can normally not be linked to a specific natural person. However, in certain cases the collected information can be linked to you directly (provided you disclosed your identity to Siemens, e.g., when creating an account or filing a request with us). To the extent that information processed in connection with performance cookies can, in a given case, be directly linked to you or otherwise qualifies as personal data, the legal ground for that processing is your user consent.',
                'In general, the information collected by these cookies is aggregated and can normally not be linked to a specific natural person. However, in certain cases the collected information can be linked to you directly (provided you disclosed your identity to Siemens, e.g., when creating an account or filing a request with us). To the extent that information processed in connection with performance cookies can, in a given case, be directly linked to you or otherwise qualifies as personal data, the legal ground for that processing is your user consent.'
            ]
        },
        'functionalityTitle': [
            'Functionality Cookies',
            'Functionality Cookies'
        ],
        'functionalityDescription': {
            '1': [
                'These cookies allow the website to remember choices you make or information you enter (such as your username, language or the region you are in) and provide enhanced, more personal features. They are also used to enable requested functions such as playing videos.',
                'These cookies allow the website to remember choices you make or information you enter (such as your username, language or the region you are in) and provide enhanced, more personal features. They are also used to enable requested functions such as playing videos.'
            ],
            '2': [
                'To the extent that information processed in connection with functionality cookies should, in a given case, qualify as personal data, the legal ground for that processing is the user’s consent.',
                'To the extent that information processed in connection with functionality cookies should, in a given case, qualify as personal data, the legal ground for that processing is the user’s consent.'
            ]
        },
        'marketingTitle': [
            'Marketing Cookies',
            'Marketing Cookies'
        ],
        'marketingDescription': {
            '1': [
                'Marketing cookies (also referred to as targeting or advertising cookies) are used to deliver adverts on third party websites more relevant to you and your interests. They are also used to limit the number of times you see an advertisement as well as help measure the effectiveness of an advertising campaign.',
                'Marketing cookies (also referred to as targeting or advertising cookies) are used to deliver adverts on third party websites more relevant to you and your interests. They are also used to limit the number of times you see an advertisement as well as help measure the effectiveness of an advertising campaign.'
            ],
            '2': [
                'Legal basis for the processing of personal data in connection with marketing cookies (if any) is the user’s consent.',
                'Legal basis for the processing of personal data in connection with marketing cookies (if any) is the user’s consent.'
            ]
        },
        'noticeFinal': {
            '1': [
                'Siemens AG and Siemens Industry Software, Inc. are jointly responsible for any personal data collected by cookies as described in this Cookie Notice and have entered into an agreement setting out their respective data protection responsibilities. A summary of the content of that agreement is made available upon request.',
                'Siemens AG and Siemens Industry Software, Inc. are jointly responsible for any personal data collected by cookies as described in this Cookie Notice and have entered into an agreement setting out their respective data protection responsibilities. A summary of the content of that agreement is made available upon request.'
            ],
            '2': [
                'In case of any request in connection with this Cookie Notice, please contact the Data Privacy Organization at dataprotection@siemens.com',
                'In case of any request in connection with this Cookie Notice, please contact the Data Privacy Organization at dataprotection@siemens.com'
            ]
        }
    },
    'main': {
        'compareCard': [
            'SinaSave compares different configurations of drive systems for pump or fan applications, as well as Direct-Online (DOL) and Variable-Speed-Drive (VSD) systems for both Greenfield and Brownfield installations.',
            'SinaSave compares different configurations of drive systems for pump or fan applications, as well as Direct-Online (DOL) and Variable-Speed-Drive (VSD) systems for both Greenfield and Brownfield installations.'
        ],
        'comparisonCard': [
            'SinaSave allows for a direct comparison between your existing motors and SIMOTICS motors of different energy efficiency classes - for new installations and retrofits.',
            'SinaSave allows for a direct comparison between your existing motors and SIMOTICS motors of different energy efficiency classes - for new installations and retrofits.'
        ],
        'consolidationCard': [
            'SinaSave allows for the consolidation of multiple items into a project, enabling the representation of entire systems and the generation of their potential savings.',
            'SinaSave allows for the consolidation of multiple items into a project, enabling the representation of entire systems and the generation of their potential savings.'
        ],
        'energyConsumptionCard': [
            'SinaSave determines the expected energy consumption based on your individual energy prices, operating hours, and loads, as well as the resulting energy, CO2, and energy cost savings.',
            'SinaSave determines the expected energy consumption based on your individual energy prices, operating hours, and loads, as well as the resulting energy, CO2, and energy cost savings.'
        ],
        'fanSystemCardDescription': [
            'Calculate your energy and CO2 saving potential for fan drive systems.',
            'Calculate your energy and CO2 saving potential for fan drive systems.'
        ],
        'home': [
            'Home',
            'Home'
        ],
        'marketingSectionListItems': {
            'item1': [
                'Identification of potential savings in energy, energy costs, and CO2',
                'Identification of potential savings in energy, energy costs, and CO2'
            ],
            'item2': [
                'Estimation of expected payback period and Total Cost of Ownership (TCO)',
                'Estimation of expected payback period and Total Cost of Ownership (TCO)'
            ],
            'item3': [
                'Output of system losses according to IEC61800-9-2 for motor-inverter systems',
                'Output of system losses according to IEC61800-9-2 for motor-inverter systems'
            ],
            'item4': [
                'Identification of possible financing options, such as Energy Performance Contracting (EnPC)',
                'Identification of possible financing options, such as Energy Performance Contracting (EnPC)'
            ],
            'item5': [
                'Consideration of government subsidies',
                'Consideration of government subsidies'
            ],
            'item6': [
                'Easy-to-use, intuitive design',
                'Easy-to-use, intuitive design'
            ],
            'item7': [
                'Graphical representation of results',
                'Graphical representation of results'
            ],
            'item8': [
                'Save and load functionality, export of handouts for customers or decision-makers',
                'Save and load functionality, export of handouts for customers or decision-makers'
            ],
            'item9': [
                'Support for eight languages, 14 currencies, IEC and NEMA standards',
                'Support for eight languages, 14 currencies, IEC and NEMA standards'
            ],
            'item10': [
                'Seamless integration with subsequent processes, such as product configuration.',
                'Seamless integration with subsequent processes, such as product configuration.'
            ]
        },
        'marketingSectionTitle': [
            'SinaSave has a lot to offer',
            'SinaSave has a lot to offer'
        ],
        'pumpSystemCardDescription': [
            'Calculate your energy and CO2 saving potential for pump drive systems.',
            'Calculate your energy and CO2 saving potential for pump drive systems.'
        ],
        'region': [
            'Region',
            'Region'
        ],
        'sinaSaveDescription': [
            'SinaSave is an intuitively usable web tool that answers important questions for you before making an investment decision: Does the use of more efficient technology pay off? What benefits do energy efficiency classes have compared to standard models? When will my investment be amortized? Test SinaSave now with your individual application.',
            'SinaSave is an intuitively usable web tool that answers important questions for you before making an investment decision: Does the use of more efficient technology pay off? What benefits do energy efficiency classes have compared to standard models? When will my investment be amortized? Test SinaSave now with your individual application.'
        ],
        'startComparison': [
            'Start Comparison',
            'Start Comparison'
        ],
        'visualisation': [
            'Visualisation',
            'Visualisation'
        ]
    },   
    'footer': {
        'contactUs': [
            'Contact us',
            'Kontakt'
        ],
        'contactUsLink': [
            'http://new.siemens.com/global/en/general/contact.html',
            'https://new.siemens.com/global/de/general/kontakt.html'
        ],
        'cookieNotice': [
            'Cookie Notice',
            'Cookie-Hinweise'
        ],
        'cookieNoticeLink': [
            'https://new.siemens.com/global/en/general/cookie-notice.html',
            'https://new.siemens.com/global/de/general/cookie-richtlinien.html'
        ],
        'corporateInformation': [
            'Corporate Information',
            'Impressum'
        ],
        'corporateInformationLink': [
            'https://new.siemens.com/global/en/general/legal.html',
            'https://new.siemens.com/global/de/general/legal.html'
        ],
        'digitalId': [
            'Digital ID',
            'Digitales Zertifikat'
        ],
        'digitalIdLink': [
            'https://new.siemens.com/global/en/general/digital-id.html',
            'https://new.siemens.com/global/de/general/digitales-zertifikat.html'
        ],
        'siemensWebsite': [
            'siemens.com Global Website',
            'siemens.com Germany'
        ],
        'siemensWebsiteLink': [
            'https://new.siemens.com/global/en.html',
            'https://new.siemens.com/global/de.html'
        ],
        'privacyNotice': [
            'Privacy Notice',
            'Datenschutz'
        ],
        'privacyNoticeLink': [
            'https://new.siemens.com/global/en/general/privacy-notice.html',
            'https://new.siemens.com/global/de/general/datenschutz.html'
        ],
        'siemens': [
            '© Siemens, 1996 - {date}',
            '© Siemens, 1996 - {date}'
        ],
        'termsOfUse': [
            'Terms of use',
            'Nutzungsbedingungen'
        ],
        'termsOfUseLink': [
            'https://new.siemens.com/global/en/general/terms-of-use.html',
            'https://new.siemens.com/global/de/general/nutzungsbedingungen.html'
        ]
    },
    'header': {
        'language': [
            'Language',
            'Sprache'
        ],
        'languages': {
            'en': [
                'English',
                'English'
            ],
            'de': [
                'Deutsch',
                'Deutsch'
            ]
        },
        'skipToMainContent': [
            'Skip to main content',
            'Skip to main content'
        ]
    },
    'error': {
        'error': [
            'Error',
            'Error'
        ],
        'api': {
            'documentDownload': [
                'Unable to download exported document',
                'Unable to download exported document'
            ],
            'documentExport': [
                'Unable to export document',
                'Unable to export document'
            ],
            'getSystems': [
                'Unable to fetch systems',
                'Unable to fetch systems'
            ],
            'getSystem': [
                'Unable to fetch selected system',
                'Unable to fetch selected systems'
            ],
            'getDefaultPump': [
                'Unable to fetch default pump.',
                'Unable to fetch default pump.'

            ],
            'getDefaultFan': [
                'Unable to fetch default fan.',
                'Unable to fetch default fan.'
            ],
            'getPump': [
                'Unable to fetch pump.',
                'Unable to fetch pump.'
            ],
            'getFan': [
                'Unable to fetch fan.',
                'Unable to fetch fan.'
            ],
            'getChartData': [
                'Unable to fetch chart data.',
                'Unable to fetch chart data.'

            ],
            'getRegionSettings': [
                'Unable to fetch region settings.',
                'Unable to fetch region settings.'
            ]
        }

    }
});
