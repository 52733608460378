import React, { SVGProps } from 'react';
import { Props } from 'recharts/types/shape/Dot';

export interface ChartLineDotProps extends Props {
    active?: boolean;
    payload?: any;
    systemType: 'reference' | 'alternative';
}

const ChartLineDot = ({ systemType, active, cx = 0, cy = 0, payload, fill, stroke, ...rest }: ChartLineDotProps) => {
    const size = 5;
    const diamond = <polygon points={`${cx},${cy - size} ${cx + size},${cy} ${cx},${cy + size} ${cx - size},${cy}`} stroke={active ? stroke : 'none'} fill={fill} {...rest as SVGProps<SVGPolygonElement>} />;
    const triangle = <polygon points={`${cx},${cy - size} ${cx - size},${cy + size} ${cx + size},${cy + size}`} stroke={active ? stroke : 'none'} fill={fill} {...rest as SVGProps<SVGPolygonElement>} />; 

    return payload && payload.intersection ? (
        <svg className='intersection-point'>
            <circle cx={cx} cy={cy} r={3} {...rest as SVGProps<SVGCircleElement>} />
        </svg>
    ) : (
            <svg>
                {systemType == 'reference' ? diamond : triangle}
        </svg>
    );
};

export default ChartLineDot;
