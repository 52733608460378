import React, { CSSProperties, useMemo, useState } from 'react';
import { InputAttributes, NumericFormatProps } from 'react-number-format';
import { AdditionalInvestmentInputData } from '../../../models';
import { IconType } from '../../common/Icon';
import Flex from '../../common/Flex';
import IconButton from '../../common/IconButton';
import TextInput from '../../common/TextInput';

export interface AdditionalInvestmentInputProps {
    index: number;
    inputData: AdditionalInvestmentInputData;
    numberFormatOptions: NumericFormatProps<InputAttributes>;
    onDeleteItem: (index: number) => void;
    onUpdateItemLabel: (index: number, label: string) => void;
    onUpdateItemValue: (index: number, values: string) => void;
    showDeleteButton: boolean;
    dark?: boolean;
    style?: CSSProperties;
}

const AdditionalInvestmentInput = ({
    index,
    inputData,
    numberFormatOptions,
    showDeleteButton,
    dark,
    style,
    onDeleteItem,
    onUpdateItemLabel,
    onUpdateItemValue
}: AdditionalInvestmentInputProps) => {
    const [enableLabelEdit, setEnableLabelEdit] = useState(false);
    const inputDataLabel = useMemo(() => enableLabelEdit ? inputData.value : inputData.label, [enableLabelEdit, inputData.label, inputData.value]);
    const priceNumberFormatOptions = useMemo(() =>
        !enableLabelEdit && { ...numberFormatOptions },
    [enableLabelEdit, numberFormatOptions]);

    return (
        <Flex gap={16} style={style}>
            <TextInput
                onBlur={() => setEnableLabelEdit(false)}
                onSubmit={() => setEnableLabelEdit(false)}
                style={{ width: '100%' }}
                dark={dark}
                label={inputDataLabel}
                numberFormatOptions={priceNumberFormatOptions}
                name={''}
                value={enableLabelEdit ? inputData.label : inputData.value}
                onChange={
                    enableLabelEdit
                        ? label => onUpdateItemLabel(index, label)
                        : value => onUpdateItemValue(index, value)
                }
            />
            <Flex gap={8}>
                <IconButton iconType={IconType.Edit} size={24} layer={2} dark={dark} onClick={() => setEnableLabelEdit(!enableLabelEdit)} />
                {showDeleteButton &&
                    <IconButton iconType={IconType.Close} size={24} layer={2} dark={dark} onClick={() => onDeleteItem(index)} />
                }
            </Flex>
        </Flex>
    );
};

export default AdditionalInvestmentInput;
