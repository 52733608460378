import React, { useCallback, useEffect, useMemo } from 'react';
import { InputAttributes, NumericFormatProps } from 'react-number-format';
import { TRANSLATIONS } from '../../../constants';
import { GovernmentFundingData } from '../../../models/system/GovernmentFundingData';
import { useGovernmentFunding } from '../../../hooks/application/GovernmentFunding';
import { useAppSelector, useTranslate } from '../../../hooks/common';
import Box from '../../common/Box';
import Button, { ButtonType } from '../../common/Button';
import Flex from '../../common/Flex';
import Icon, { IconType } from '../../common/Icon';
import IconButton from '../../common/IconButton';
import Modal from '../../common/Modal';
import Text from '../../common/Text';
import TextInput from '../../common/TextInput';
import CommercialViewGovernmentFundingInfo from './ModalInfo/CommercialViewGovernmentFundingInfo';
import { useCurrency } from '../../../hooks';

export interface GovernmentFundingProps {
    color: 'reference' | 'alternative';
    governmentFundingData: GovernmentFundingData;
    dark?: boolean;
    totalInvestment: number;
    exchangedTotalInvestment: number;
    onGovernmentFundingDataChange: (value: Partial<GovernmentFundingData>) => void;
    onNettoInvestmentChange: (value: number) => void;
}

const numberFormatOptions: NumericFormatProps<InputAttributes> = {
    thousandSeparator: ',',
    allowNegative: false,
    decimalSeparator: '.',
    decimalScale: 2,
    allowLeadingZeros: false
};

const modalContent = (
    <>
        <div>Additional investment</div>
        <div>Modal description</div>
    </>
);

const GovernmentFunding = ({
    color,
    dark,
    governmentFundingData,
    onGovernmentFundingDataChange,
    onNettoInvestmentChange,
    totalInvestment,
    exchangedTotalInvestment
}: GovernmentFundingProps) => {
    const {
        grantRate,
        grantSum,
        closeGovernmentFunding,
        handleEnableEditChange,
        handleGrantRateChange,
        handleGrantSumChange,
        handleModalOpenChange,
        exchangedNettoInvestment,
        nettoInvestment
    } = useGovernmentFunding({ governmentFundingData, onGovernmentFundingDataChange, totalInvestment, exchangedTotalInvestment });

    useEffect(() => {
        onNettoInvestmentChange(nettoInvestment);
    }, [nettoInvestment, onNettoInvestmentChange]);
    const { translate } = useTranslate();
    const handleOpenModalClick = useCallback(() => handleModalOpenChange(true), [handleModalOpenChange]);
    const handleCloseModalClick = useCallback(() => handleModalOpenChange(false), [handleModalOpenChange]);
    const handleEnableEditClick = useCallback(() => handleEnableEditChange(true), [handleEnableEditChange]);
    const { currencyFormat } = useCurrency();

    return (
        <>
            {!governmentFundingData.enableEdit && (
                <Flex direction='column'>
                    <Button
                        dark={dark}
                        roundedBorder
                        style={{ padding: '10px 16px' }}
                        type={ButtonType.Secondary}
                        onClick={handleEnableEditClick}
                    >
                        <Icon type={IconType.AddWorkorder} />
                        {translate(TRANSLATIONS.generated['System.GovernmentFunding'])}
                    </Button>
                </Flex>
            )}
            {governmentFundingData.enableEdit && (
                <Box edgeColor={color} dark={dark} style={{ padding: 16 }}>
                    <Flex justification='space-between' gap={16} style={{ paddingBottom: 8 }}>
                        <Text color={color} dark={dark} type='subtitle-small'>
                            {translate(TRANSLATIONS.generated['System.GovernmentFunding'])}
                        </Text>
                        <Flex gap={8}>
                            <IconButton dark={dark} iconType={IconType.Information} size={16} layer={2} onClick={handleOpenModalClick} />
                            <IconButton dark={dark} iconType={IconType.Close} size={24} layer={2} onClick={closeGovernmentFunding} />
                        </Flex>
                    </Flex>
                    <div style={{ paddingTop: 16 }}>
                        <TextInput
                            onChange={handleGrantRateChange}
                            numberFormatOptions={{ ...numberFormatOptions, suffix: '%' }}
                            dark={dark}
                            label={translate(TRANSLATIONS.generated['System.GrantRate'])}
                            name='grantRate'
                            value={grantRate}
                        />
                        <TextInput
                            onChange={handleGrantSumChange}
                            numberFormatOptions={currencyFormat}
                            style={{ paddingTop: 16 }}
                            dark={dark}
                            label={translate(TRANSLATIONS.generated['System.GrantSum'])}
                            name='grantSum'
                            value={grantSum}
                        />
                    </div>

                    <div>
                        <TextInput
                            style={{ paddingTop: 24 }}
                            label={translate(TRANSLATIONS.generated['System.NettoInvestment'])}
                            readOnly
                            numberFormatOptions={currencyFormat}
                            withFullBorder
                            dark={dark}
                            value={exchangedNettoInvestment?.toString() ?? '0'}
                            name='nettoInvestment'
                        />
                    </div>
                    {modalContent && (
                        <Modal
                            open={governmentFundingData.isModalOpen}
                            allowClickAway
                            movable
                            onClose={handleCloseModalClick}
                            dark={dark}
                            key={'info-modal'}
                        >
                            <CommercialViewGovernmentFundingInfo />
                        </Modal>
                    )}
                </Box>
            )}
        </>
    );
};

export default GovernmentFunding;
