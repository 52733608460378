import { useCallback, useState } from 'react';
import { TEXT_INPUT_DEBOUNCE_TIME, TRANSLATIONS } from '../../constants';
import { InputType } from '../../enums';
import {
    setPump,
    setFan,
    addSnackbar,
    setFanHasMepsWarning,
    setPumpHasMepsWarning,
    setMepsWarningModalOpen
} from '../../store';
import { Api, apiCall, getApplicationActions, priceExchanger } from '../../services';
import {
    AllocationProfile,
    Application,
    ApplicationParameters,
    ApplicationRequest,
    ApplicationResponse,
    InputItemList,
    OperatingHour,
    SystemConfiguration,
    SystemInputParameter,
    SystemRequest,
    SystemsRequest
} from '../../models';
import { ApplicationType } from '../../types';
import { useAppDispatch, useAppSelector, useTranslate } from '../common';
import { useDebounceCallback } from '../common/DebounceCallback';

export interface SystemsLoadProps {
    applicationType: ApplicationType;
}

export const useSystemsLoad = ({ applicationType }: SystemsLoadProps) => {
    const dispatch = useAppDispatch();
    const isPumpSystem = applicationType === 'pump';
    const [defaultsLoading, setDefaultsLoading] = useState(false);
    const { regionSettings, defaultCurrency } = useAppSelector(state => state.regionSettings);
    const energySavingsChartTabParams = useAppSelector(state => isPumpSystem ? state.pumpSystemChart.energySavingsChartTabParams : state.fanSystemChart.energySavingsChartTabParams);
    const systemState = useAppSelector(state => isPumpSystem ? state.pumpSystem : state.fanSystem);
    const {
        applicationModeHandler,
        applicationRequestHandler,
        allocationProfileHandler,
        alternativeSystemCommercialDataHandler,
        alternativeSystemHandler,
        alternativeSystemInvestmentCostHandler,
        alternativeSystemRequestParameterHandler,
        operatingHourHandler,
        referenceSystemCommercialDataHandler,
        referenceSystemHandler,
        referenceSystemInvestmentCostHandler,
        referenceSystemRequestParameterHandler,
        shaftPowerHandler,
        speedHandler,
        supplyTypeHandler,
        supplyTypesHandler,
        systemsLoadingHandler,
        energySavingsParamsHandler,
        defaultApplicationInputsHandler
    } = getApplicationActions(applicationType);
    const { translate, dynamicTranslate } = useTranslate();

    const setSystem = useCallback(async (systemConfiguration: SystemConfiguration, systemType: 'reference' | 'alternative') => {
        const motorCustomerPrice =
            priceExchanger(systemConfiguration.motorComponent.selectedMotor.listPrice?.listPrice ?? 0, systemConfiguration.motorComponent.selectedMotor.listPrice?.currency, defaultCurrency);
        const controlComponentCustomerPrice =
            priceExchanger(systemConfiguration.controlUnitComponent.selectedControlUnit.listPrice?.listPrice ?? 0, systemConfiguration.controlUnitComponent.selectedControlUnit.listPrice?.currency, defaultCurrency);
        const setSystem = systemType === 'reference' ? referenceSystemHandler : alternativeSystemHandler;
        const setCommercialData = systemType === 'reference' ? referenceSystemCommercialDataHandler : alternativeSystemCommercialDataHandler;
        const setSystemInvestmentCosts = systemType === 'reference' ? referenceSystemInvestmentCostHandler : alternativeSystemInvestmentCostHandler;
        const setSystemRequestParameters = systemType === 'reference' ? referenceSystemRequestParameterHandler : alternativeSystemRequestParameterHandler;

        dispatch(setSystem(systemConfiguration));
        dispatch(setCommercialData({
            motorCustomerPrice: motorCustomerPrice?.toString() ?? '0',
            controlComponentCustomerPrice: controlComponentCustomerPrice?.toString() ?? '0',
            motorExpeditureForRepairs: '0',
            controlComponentExpeditureForRepairs: '0'
        }));
        dispatch(setSystemInvestmentCosts((motorCustomerPrice ?? 0) + (controlComponentCustomerPrice ?? 0)));
        dispatch(setSystemRequestParameters([
            ...mapInputsToParameters(systemConfiguration.controlUnitComponent.inputs),
            ...mapInputsToParameters(systemConfiguration.motorComponent.inputs),
            ...mapInputsToParameters(systemConfiguration.controlModeComponent.inputs)
        ]));
    }, [
        alternativeSystemCommercialDataHandler,
        alternativeSystemHandler,
        alternativeSystemInvestmentCostHandler,
        alternativeSystemRequestParameterHandler,
        defaultCurrency,
        dispatch,
        referenceSystemCommercialDataHandler,
        referenceSystemHandler,
        referenceSystemInvestmentCostHandler,
        referenceSystemRequestParameterHandler
    ]);

    const showOperationProfile = useCallback(() => {
        if (!energySavingsChartTabParams.showOperationProfile) {
            dispatch(energySavingsParamsHandler({ ...energySavingsChartTabParams, showOperationProfile: true }));
        }
    }, [dispatch, energySavingsChartTabParams, energySavingsParamsHandler]);

    const handleGetSystems = useCallback(async (systemsRequest: SystemsRequest) => {
        return apiCall(
            Api.getSystems(systemsRequest),
            async x => {
                const hasMepsWarningReference = x.data.referenceSystemConfiguration.motorComponent.selectedMotor.hasMepsWarning;
                const hasMepsWarningAlternative = x.data.alternativeSystemConfiguration.motorComponent.selectedMotor.hasMepsWarning;

                dispatch(setMepsWarningModalOpen(hasMepsWarningReference || hasMepsWarningAlternative));
                dispatch(isPumpSystem
                    ? setPumpHasMepsWarning( { hasMepsWarning: hasMepsWarningReference, systemType: 'reference' } )
                    : setFanHasMepsWarning( { hasMepsWarning: hasMepsWarningReference, systemType: 'reference' } ));
                setSystem(x.data.referenceSystemConfiguration, 'reference');
                dispatch(isPumpSystem
                    ? setPumpHasMepsWarning({ hasMepsWarning: hasMepsWarningAlternative, systemType: 'alternative' } )
                    : setFanHasMepsWarning({ hasMepsWarning: hasMepsWarningAlternative, systemType: 'alternative' } ));
                setSystem(x.data.alternativeSystemConfiguration, 'alternative');
            },
            async () => {
                dispatch(addSnackbar({ title: translate(TRANSLATIONS.error.error), description: translate(TRANSLATIONS.error.api.getSystems), type: 'error' }));
            }
        );
    }, [dispatch, setSystem, translate]);

    const handleGetSystem = useCallback(async (systemRequest: SystemRequest, systemType: 'reference' | 'alternative') => {
        return apiCall(
            Api.getSystem(systemRequest),
            async x => {
                const hasMepsWarning = x.data.systemConfiguration.motorComponent.selectedMotor.hasMepsWarning;

                dispatch(setMepsWarningModalOpen(hasMepsWarning));
                dispatch(isPumpSystem ?
                    setPumpHasMepsWarning({
                        hasMepsWarning: hasMepsWarning,
                        systemType: systemType
                    }) :
                    setFanHasMepsWarning({
                        hasMepsWarning: hasMepsWarning,
                        systemType: systemType
                }));
                setSystem(x.data.systemConfiguration, systemType);
            },
            async () => {
                dispatch(addSnackbar({ title: translate(TRANSLATIONS.error.error), description: translate(TRANSLATIONS.error.api.getSystem), type: 'error' }));
            }
        );
    }, [dispatch, setSystem, translate]);

    const handleGetDefaultSystems = useCallback(async (applicationParameters: ApplicationParameters, countryCode: string) => {
        const systemsRequest: SystemsRequest = {
            countryCode,
            applicationParameters
        };
        
        return handleGetSystems(systemsRequest);
    }, [handleGetSystems]);

    const validateDefaultApplicationInputs = useCallback((shaftPower: string | undefined, speed: string | undefined) => {
        if (shaftPower === systemState.defaultApplicationInputs?.shaftPower
            && speed === systemState.defaultApplicationInputs?.speed) {
            dispatch(applicationModeHandler('Default'));
        } else {
            dispatch(applicationModeHandler('Custom'));
        }
    }, [systemState, dispatch, applicationModeHandler]);

    const setSystemStates = useCallback(async <T extends ApplicationResponse>(response: T, applicationParameters: ApplicationParameters) => {
        dispatch(isPumpSystem ? setPump(response.application) : setFan(response.application));
        dispatch(supplyTypeHandler(response.supplyType));
        dispatch(supplyTypesHandler(response.supplyTypes));
        dispatch(applicationRequestHandler(applicationParameters));
    }, [applicationRequestHandler, dispatch, isPumpSystem, supplyTypeHandler, supplyTypesHandler]);

    const handleGetDefaults = useCallback(async (countryCode: string) => {
        dispatch(systemsLoadingHandler(true));
        setDefaultsLoading(true);

        await apiCall(
            isPumpSystem ? Api.getDefaultPump(countryCode) : Api.getDefaultFan(countryCode),
            async x => {
                const applicationRequestParameters: ApplicationParameters = {
                    current: x.data.supplyType.currentType,
                    frequency: x.data.supplyType.frequency,
                    voltage: x.data.supplyType.voltage,
                    shaftPower: x.data.application.shaftPower,
                    speed: x.data.application.speed
                };

                dispatch(defaultApplicationInputsHandler({ shaftPower: x.data.application.shaftPower.toString(), speed: x.data.application.speed.toString() }));
                setSystemStates(x.data, applicationRequestParameters);

                await handleGetDefaultSystems(applicationRequestParameters, countryCode);
            },
            async () => {
                dispatch(addSnackbar({
                    title: translate(TRANSLATIONS.error.error),
                    description: translate(isPumpSystem ?
                        TRANSLATIONS.error.api.getDefaultPump : TRANSLATIONS.error.api.getDefaultFan), type: 'error'
                }));
            }
       );

        setDefaultsLoading(false);
        dispatch(systemsLoadingHandler(false));
    }, [dispatch, handleGetDefaultSystems, isPumpSystem, setSystemStates, systemsLoadingHandler, translate]);

    const handleGetConfiguration = useCallback(async <T extends ApplicationRequest>(applicationRequest: T) => {
        dispatch(systemsLoadingHandler(true));

        await apiCall(
            isPumpSystem ? Api.getPump(applicationRequest) : Api.getFan(applicationRequest),
            async x => {
                const applicationParameters: ApplicationParameters = {
                    current: x.data.supplyType.currentType,
                    frequency: x.data.supplyType.frequency,
                    voltage: x.data.supplyType.voltage,
                    shaftPower: x.data.application.shaftPower,
                    speed: x.data.application.speed
                };
                setSystemStates(x.data, applicationParameters);
                await handleGetSystems({ applicationParameters, countryCode: applicationRequest.countryCode });
            },
            async () => {
                dispatch(addSnackbar({
                    title: translate(TRANSLATIONS.error.error),
                    description: translate(isPumpSystem ?
                        TRANSLATIONS.error.api.getPump : TRANSLATIONS.error.api.getFan), type: 'error'
                }));
            }
        );

        dispatch(systemsLoadingHandler(false));
    }, [dispatch, systemsLoadingHandler, isPumpSystem, setSystemStates, handleGetSystems, translate]);

    const handleApplicationInputChange = useCallback(async (parameter: Partial<Application>) => {
        if (systemState.application && systemState.supplyType) {
            const applicationRequest: ApplicationRequest = {
                countryCode: regionSettings?.countryCode ?? '--',
                application: { ...systemState.application, ...parameter },
                supplyType: systemState.supplyType
            };

            handleGetConfiguration(applicationRequest);
        }
    }, [systemState, regionSettings?.countryCode, handleGetConfiguration]);

    const debouncedHandleApplicationInputChange = useDebounceCallback(handleApplicationInputChange, TEXT_INPUT_DEBOUNCE_TIME);

    const getOperatingHourValue = useCallback((value: OperatingHour) => `${value.workingHoursPerYear} (${value.workingHoursPerDay} ${translate(TRANSLATIONS.generated['Unit.Hour'])} * ${value.workingDaysPerYear} ${translate(TRANSLATIONS.generated['Unit.Day'])})`, [translate]);

    const handleOperatingHourChange = useCallback((value: OperatingHour) => {
        showOperationProfile();
        dispatch(operatingHourHandler(value));
    }, [dispatch, operatingHourHandler, showOperationProfile]);

    const getAllocationProfileName = useCallback((value: AllocationProfile) => {
        const key = value.name
            ? value.name.replace('System.AllocationProfile.', '').replace('_', '')
            : 'Default';

        return dynamicTranslate(TRANSLATIONS.generated, `${key === 'Default' ? 'Common' : 'Allocation'}.${key}`);
    }, [dynamicTranslate]);

    const handleAllocationChange = useCallback((value: AllocationProfile) => {
        showOperationProfile();
        dispatch(allocationProfileHandler(value));
    }, [allocationProfileHandler, showOperationProfile, dispatch]);

    const handleShaftPowerChange = useCallback((value: string) => {
        if (value.length) {
            validateDefaultApplicationInputs(value, systemState.application?.speed.toString());
            dispatch(shaftPowerHandler(parseFloat(value)));
            debouncedHandleApplicationInputChange({ shaftPower: parseFloat(value) });
        }
    }, [debouncedHandleApplicationInputChange, dispatch, shaftPowerHandler, systemState, validateDefaultApplicationInputs]);

    const handleSpeedChange = useCallback((value: string) => {
        if (value.length) {
            validateDefaultApplicationInputs(systemState.application?.shaftPower.toString(), value);

            dispatch(speedHandler(parseInt(value)));
            debouncedHandleApplicationInputChange({ speed: parseInt(value) });
        }
    }, [debouncedHandleApplicationInputChange, dispatch, speedHandler, systemState, validateDefaultApplicationInputs]);

    const mapInputsToParameters = (inputs: InputItemList): (SystemInputParameter)[] => inputs.length ? inputs.map(x => {
        switch (x.inputType) {
            case InputType.ImageDropdown:
                return {
                    inputId: x.id,
                    value: x.selectedValue?.componentType.toString(),
                    dataType: x.dataType
                } as SystemInputParameter;
            case InputType.Number:
                return {
                    inputId: x.id,
                    value: x.value.toString(),
                    dataType: x.dataType
                } as SystemInputParameter;
            case InputType.Select:
                return {
                    inputId: x.id,
                    value: x.current.value,
                    dataType: x.dataType
                } as SystemInputParameter;
            case InputType.Text:
                return {
                    inputId: x.id,
                    value: x.value,
                    dataType: x.dataType
                } as SystemInputParameter;
        }
    }) : [];

    return {
        handleGetConfiguration,
        handleApplicationInputChange,
        getOperatingHourValue,
        handleOperatingHourChange,
        getAllocationProfileName,
        handleAllocationChange,
        handleShaftPowerChange,
        handleSpeedChange,
        handleGetDefaults,
        handleGetSystem,
        handleGetSystems,
        defaultsLoading,
        systemsLoadingHandler
    };
};
