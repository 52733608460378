import { useCallback, useMemo } from 'react';
import { InputType } from '../../enums';
import { AdditionalInvestmentData, GovernmentFundingData, ImageDropdownItem } from '../../models';
import { useAppDispatch, useAppSelector } from '../common';
import { getSystemActions, priceExchanger } from '../../services';
import { ApplicationType, SystemType } from '../../types';

export interface CommercialViewParameters {
    systemType: SystemType
    applicationType: ApplicationType
}

export const useCommercialView = ({ systemType, applicationType }: CommercialViewParameters) => {
    const dispatch = useAppDispatch();
    const { regionSettings, defaultCurrency } = useAppSelector(x => x.regionSettings);
    const { referenceSystem, alternativeSystem } = useAppSelector(state => applicationType == 'pump' ? state.pumpSystem : state.fanSystem);
    const { referenceSystemCommercialData, alternativeSystemCommercialData } = useAppSelector(state => applicationType == 'pump' ? state.pumpSystemsCommercialData : state.fanSystemsCommercialData);
    const { systemInvestmentCostHandler, commercialDataHandler, additionalInvestmentHandler, governmentFundingDataHandler } = getSystemActions(applicationType, systemType);
    const isReferenceSystem = systemType == 'reference';

    const systemState = useMemo(() => isReferenceSystem
        ? referenceSystem
        : alternativeSystem,
        [alternativeSystem, isReferenceSystem, referenceSystem]);

    const commercialData = useMemo(() => isReferenceSystem
        ? referenceSystemCommercialData
        : alternativeSystemCommercialData,
        [alternativeSystemCommercialData, isReferenceSystem, referenceSystemCommercialData]);

    const controlMode = useMemo(() =>
        systemState?.controlModeComponent?.inputs?.find(x => x.inputType == InputType.ImageDropdown) as ImageDropdownItem,
        [systemState?.controlModeComponent]);

    const motor = useMemo(() =>
        systemState?.motorComponent.inputs.find(x => x.inputType == InputType.ImageDropdown) as ImageDropdownItem,
        [systemState?.motorComponent.inputs]);

    const controlComponent = useMemo(() =>
        systemState?.controlUnitComponent.inputs.find(x => x.inputType == InputType.ImageDropdown) as ImageDropdownItem,
        [systemState?.controlUnitComponent.inputs]);

    const additionalInevestments = useMemo(() =>
        commercialData.additionalInvestments.additionalInvestmentItems.filter(item => !isNaN(Number(item.value))).reduce((sum, current) => sum + Number(current.value), 0),
        [commercialData.additionalInvestments.additionalInvestmentItems]);

    const exchangedAdditionalInvestments = priceExchanger(additionalInevestments, defaultCurrency, regionSettings?.currency);

    const exchangedMotorListPrice = useMemo(() =>
        priceExchanger(systemState?.motorComponent.selectedMotor.listPrice?.listPrice ?? 0, systemState?.motorComponent.selectedMotor.listPrice?.currency, regionSettings?.currency),
        [regionSettings?.currency, systemState?.motorComponent.selectedMotor.listPrice]);

    const exchangedControlComponentListPrice = useMemo(() =>
        priceExchanger(systemState?.controlUnitComponent.selectedControlUnit.listPrice?.listPrice ?? 0, systemState?.controlUnitComponent.selectedControlUnit.listPrice?.currency, regionSettings?.currency),
        [regionSettings?.currency, systemState?.controlUnitComponent.selectedControlUnit.listPrice]);

    const exchangedMotorCustomerPrice = useMemo(() =>
        priceExchanger(Number(commercialData.motorCustomerPrice) ?? 0, defaultCurrency, regionSettings?.currency),
        [commercialData.motorCustomerPrice, defaultCurrency, regionSettings?.currency]);

    const exchangedControlComponentCustomerPrice = useMemo(() =>
        priceExchanger(Number(commercialData.controlComponentCustomerPrice) ?? 0, defaultCurrency, regionSettings?.currency),
        [commercialData.controlComponentCustomerPrice, defaultCurrency, regionSettings?.currency]);

    const exchangedMotorExpenditureForRepairs = useMemo(() =>
        priceExchanger(Number(commercialData.motorExpeditureForRepairs) ?? 0, defaultCurrency, regionSettings?.currency),
        [commercialData.motorExpeditureForRepairs, defaultCurrency, regionSettings?.currency]);

    const exchangedControlComponentExpenditureForRepairs = useMemo(() =>
        priceExchanger(Number(commercialData.controlComponentExpeditureForRepairs) ?? 0, defaultCurrency, regionSettings?.currency),
        [commercialData.controlComponentExpeditureForRepairs, defaultCurrency, regionSettings?.currency]);

    const totalInvestment = useMemo(() => additionalInevestments
        + Number(commercialData.motorCustomerPrice) + Number(commercialData.motorExpeditureForRepairs)
        + Number(commercialData.controlComponentCustomerPrice) + Number(commercialData.controlComponentExpeditureForRepairs),
        [additionalInevestments, commercialData.controlComponentCustomerPrice, commercialData.controlComponentExpeditureForRepairs, commercialData.motorCustomerPrice, commercialData.motorExpeditureForRepairs]);

    const exchangedTotalInvestment = priceExchanger(totalInvestment, defaultCurrency, regionSettings?.currency);

    const handleNettoInvestmentChange = useCallback((value: number) => dispatch(systemInvestmentCostHandler(value)), [dispatch, systemInvestmentCostHandler]);

    const handleMotorCustomerPriceChange = useCallback((value: string) => {
        dispatch(commercialDataHandler({
            motorDiscount:
                ((Number(systemState?.motorComponent.selectedMotor.listPrice?.listPrice) - Number(value))
                    / Number(systemState?.motorComponent.selectedMotor.listPrice?.listPrice)
                    * 100).toString()
        }));
        dispatch(commercialDataHandler({ motorCustomerPrice: priceExchanger(Number(value) ?? 0, regionSettings?.currency, defaultCurrency)?.toString() ?? '0' }));
    }, [dispatch, commercialDataHandler, systemState?.motorComponent.selectedMotor.listPrice?.listPrice, regionSettings?.currency, defaultCurrency]);

    const handleMotorDiscountChange = useCallback((value: string) => {
        dispatch(commercialDataHandler({ motorDiscount: value }));
        dispatch(commercialDataHandler({
            motorCustomerPrice:
                (Number(systemState?.motorComponent.selectedMotor.listPrice?.listPrice) * (1 - Number(value) / 100)).toString()
        }));
    }, [dispatch, commercialDataHandler, systemState?.motorComponent.selectedMotor.listPrice?.listPrice]);

    const handleMotorExpenditureForRepairsChange = useCallback((value: string) =>
        dispatch(commercialDataHandler({
            motorExpeditureForRepairs: priceExchanger(Number(value) ?? 0, regionSettings?.currency, defaultCurrency)?.toString() ?? '0' 
        })), [commercialDataHandler, defaultCurrency, dispatch, regionSettings?.currency]);

    const handleControlcomponentCustomerPriceChange = useCallback((value: string) => {
        dispatch(commercialDataHandler({
            controlComponentDiscount:
                ((Number(systemState?.controlUnitComponent.selectedControlUnit.listPrice?.listPrice) - Number(value))
                    / Number(systemState?.controlUnitComponent.selectedControlUnit.listPrice?.listPrice)
                    * 100).toString()
        }));
        dispatch(commercialDataHandler({ controlComponentCustomerPrice: priceExchanger(Number(value), regionSettings?.currency, defaultCurrency)?.toString() ?? '0' }));
    }, [dispatch, commercialDataHandler, systemState?.controlUnitComponent.selectedControlUnit.listPrice?.listPrice, regionSettings?.currency, defaultCurrency]);

    const handleControlComponentDiscountChange = useCallback((value: string) => {
        dispatch(commercialDataHandler({ controlComponentDiscount: value }));
        dispatch(commercialDataHandler({
            controlComponentCustomerPrice: (Number(systemState?.controlUnitComponent.selectedControlUnit.listPrice?.listPrice) * (1 - Number(value) / 100)).toString()
        }));
    }, [dispatch, commercialDataHandler, systemState?.controlUnitComponent.selectedControlUnit.listPrice?.listPrice]);

    const handleControlComponentExpenditureForRepairsChange = useCallback((value: string) =>
        dispatch(commercialDataHandler({
            controlComponentExpeditureForRepairs: priceExchanger(Number(value) ?? 0, regionSettings?.currency, defaultCurrency)?.toString() ?? '0' 
        })), [commercialDataHandler, defaultCurrency, dispatch, regionSettings?.currency]);

    const handleSystemGovernmentFundingDataChange = useCallback((value: Partial<GovernmentFundingData>) => dispatch(governmentFundingDataHandler(value)), [dispatch, governmentFundingDataHandler]);

    const handleAdditionalInvestmentDataChange = useCallback((value: Partial<AdditionalInvestmentData>) => dispatch(additionalInvestmentHandler(value)), [additionalInvestmentHandler, dispatch]);

    return {
        commercialData,
        controlComponent,
        controlMode,
        exchangedAdditionalInvestments,
        exchangedControlComponentCustomerPrice,
        exchangedControlComponentListPrice,
        exchangedMotorCustomerPrice,
        exchangedMotorListPrice,
        exchangedTotalInvestment,
        exchangedMotorExpenditureForRepairs,
        exchangedControlComponentExpenditureForRepairs,
        handleAdditionalInvestmentDataChange,
        handleControlcomponentCustomerPriceChange,
        handleControlComponentDiscountChange,
        handleControlComponentExpenditureForRepairsChange,
        handleMotorCustomerPriceChange,
        handleMotorDiscountChange,
        handleMotorExpenditureForRepairsChange,
        handleNettoInvestmentChange,
        handleSystemGovernmentFundingDataChange,
        motor,
        systemState,
        totalInvestment
    };
};
