import React, { useCallback } from 'react';
import { KeyValuePair, SelectInputItem } from '../../../models';
import { TRANSLATIONS } from '../../../constants';
import { DataType } from '../../../enums';
import { useTranslate } from '../../../hooks/common';
import Select from '../../common/Select';

export interface SelectInputElementProps {
    input: SelectInputItem;
    dark: boolean;
    handleSystemInputChange: (inputId: string, value: string, dataType?: DataType) => void; 
}

const SelectInputElement = ({ input, dark, handleSystemInputChange }: SelectInputElementProps) => {
    const { dynamicTranslate } = useTranslate();

    const handleSelectInputElementChange = useCallback(
        (value: KeyValuePair<string, string>) => handleSystemInputChange(input.id, value.value, input.dataType),
        [handleSystemInputChange, input.dataType, input.id]
    );

    const getValue = useCallback((value: KeyValuePair<string, string>) => {
        const valueToReturn = (() => {
            switch (input.id) {
                case '121':
                    return dynamicTranslate(TRANSLATIONS.generated, `Common.Technology.${value.value}`);
                case '220':
                case '320':
                    return dynamicTranslate(TRANSLATIONS.generated, value.value);
                default:
                    return value.value;
            }
        })();

        const unit = input.unit ? ` ${dynamicTranslate(TRANSLATIONS.generated, input.unit)}` : '';

        return `${valueToReturn}${unit}`;
    }, [dynamicTranslate, input.id, input.unit]);

    return <Select
        label={dynamicTranslate(TRANSLATIONS.generated, input.name)}
        value={input.current}
        values={input.values}
        dark={dark}
        key={input.id}
        disabled={input.isDisabled}
        mapToString={getValue} 
        onSelect={handleSelectInputElementChange}
    />;
};

export default SelectInputElement;
