import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector, useTranslate } from '../common';
import {
    ChartData,
    ChartRequest,
    EnergyPerformanceChartText,
    EnergySavingsChartText,
    LifetimeCostsChartText,
    PowerlossCalculationChartText
} from '../../models';
import { Api, apiCall, getApplicationActions, priceExchanger } from '../../services';
import { useDebounceCallback } from '../common/DebounceCallback';
import { ApplicationType } from '../../types';
import { addSnackbar } from '../../store';
import { TRANSLATIONS } from '../../constants';
import { useCurrency, useHook } from '..';
import { numericFormatter } from 'react-number-format';

export interface ComparisonCardParameters {
    applicationType: ApplicationType;
    systemsLoading: boolean;
}

export const useComparisonCard = ({ applicationType, systemsLoading }: ComparisonCardParameters) => {
    const dispatch = useAppDispatch();
    const isPumpSystem = applicationType === 'pump';
    const systemState = useAppSelector(state => isPumpSystem ? state.pumpSystem : state.fanSystem);
    const { translate } = useTranslate();
    const { regionSettings, defaultCurrency } = useAppSelector(x => x.regionSettings);
    const { currencyFormat, currencySymbol } = useCurrency();
    const [chartData, setChartData] = useState<ChartData>();
    const { chartOptionParameters: energyPerformanceChartOptionParameters } = useHook(x => x.energyPerformance({ applicationType }));
    const { chartOptionParameters: energySavingsChartOptionParameters } = useHook(x => x.energySavings({ applicationType }));
    const { chartOptionParameters: lifetimeCostsChartOptionParameters } = useHook(x => x.lifetimeCosts({ applicationType }));
    const { chartOptionParameters: powerlossCalculationChartOptionParameters } = useHook(x => x.powerlossCalculation({ applicationType }));

    const energyPerformanceChartTabText: EnergyPerformanceChartText = useMemo(() => ({
        timeInMonths: `${translate(TRANSLATIONS.generated['Common.Time'])} [${translate(TRANSLATIONS.generated['Common.Months'])}]`,
        today: translate(TRANSLATIONS.generated['Common.Today']),
        totalCosts: `${translate(TRANSLATIONS.generated['System.EnergyCosts'])} [${currencySymbol}]`,
        energyCosts: translate(TRANSLATIONS.generated['System.EnergyCosts']),
        contractRate: translate(TRANSLATIONS.generated['System.ContractRate'])
    }), [currencySymbol, translate]);

    const energySavingsChartTabText: EnergySavingsChartText = useMemo(() => ({
        flowrate: `${translate(TRANSLATIONS.generated['System.Flowrate'])} [%]`,
        powerDemand: `${translate(TRANSLATIONS.generated['System.PowerDemand'])} [${translate(TRANSLATIONS.generated['Unit.KW'])}]`,
        operatingHours: `${translate(TRANSLATIONS.generated['Common.OperatingHours'])} [${translate(TRANSLATIONS.generated['Unit.Hour'])}]`
    }), [translate]);

    const lifetimeCostsChartTabText: LifetimeCostsChartText = useMemo(() => ({
        operationTime: `${translate(TRANSLATIONS.generated['Chart.OperationTime'])} [${translate(TRANSLATIONS.generated['Unit.Months'])}]`,
        totalCosts: `${translate(TRANSLATIONS.generated['Chart.TotalCosts'])} [${numericFormatter('1000', currencyFormat)}]`
    }), [currencyFormat, translate]);

    const powerlossCalculationChartTabText: PowerlossCalculationChartText = useMemo(() => ({
        speed: `${translate(TRANSLATIONS.generated['System.Speed'])} [%]`,
        torque: `${translate(TRANSLATIONS.generated['System.Torque'])} [%]`
    }), [translate]);

    const {
        energyPerformanceChartDataHandler,
        energyPerformanceChartTabDataHandler,
        energySavingsChartDataHandler,
        energySavingsChartTabDataHandler,
        lifetimeCostsChartDataHandler,
        lifetimeCostsChartTabDataHandler,
        powerlossCalculationChartDataHandler,
        chartDataLoadingHandling,
        selectedComparisonCardTabIdHandler
    } = getApplicationActions(applicationType);

    const { chartDataLoading, powerlossCalculationChartTabParams, energyPerformanceChartTabParams, lifetimeCostsChartTabParams, selectedComparisonCardTabId } =
        useAppSelector(state => isPumpSystem ? state.pumpSystemChart : state.fanSystemChart);

    const exchangedAlternativeInvestmentCost = useMemo(() =>
        priceExchanger(systemState.alternativeSystemInvestmentCosts ?? 0, defaultCurrency, regionSettings?.currency),
        [defaultCurrency, regionSettings?.currency, systemState.alternativeSystemInvestmentCosts]);

    const exchangedReferenceInvetmentCost = useMemo(() =>
        priceExchanger(systemState.referenceSystemInvestmentCosts ?? 0, defaultCurrency, regionSettings?.currency),
        [defaultCurrency, regionSettings?.currency, systemState.referenceSystemInvestmentCosts]);

    const exchangedEnergyPrice = priceExchanger(regionSettings?.energyPrice ?? 0, defaultCurrency, regionSettings?.currency);

    const firstRun = useRef(true);

    const handleChartDataGet = useCallback(async (data: ChartRequest) => {
        return await apiCall(
            Api.getChartData(data),
            async x => {
                dispatch(energyPerformanceChartDataHandler(x.data.energyPerformanceChartData));
                dispatch(energyPerformanceChartTabDataHandler(x.data.energyPerformanceChartTabData));
                dispatch(energySavingsChartDataHandler(x.data.energySavingsChartData));
                dispatch(energySavingsChartTabDataHandler(x.data.energySavingsChartTabData));
                dispatch(lifetimeCostsChartDataHandler(x.data.lifetimeCostsChartData));
                dispatch(lifetimeCostsChartTabDataHandler(x.data.lifetimeCostsChartTabData));
                dispatch(powerlossCalculationChartDataHandler(x.data.powerlossCalculationChartData));
                setChartData({
                    ...x.data,
                    energyPerformanceChartTabParams: energyPerformanceChartOptionParameters,
                    energyPerformanceChartText: energyPerformanceChartTabText,
                    energySavingsChartTabParams: energySavingsChartOptionParameters,
                    energySavingsChartText: energySavingsChartTabText,
                    lifetimeCostsChartTabParams: lifetimeCostsChartOptionParameters,
                    lifetimeCostsChartText: lifetimeCostsChartTabText,
                    powerlossCalculationChartTabParams: powerlossCalculationChartOptionParameters,
                    powerlossCalculationChartText: powerlossCalculationChartTabText
                });
            },
            async () => {
                dispatch(addSnackbar({
                    title: translate(TRANSLATIONS.error.error),
                    description: translate(TRANSLATIONS.error.api.getChartData), type: 'error'
                }));
            }
        );
    }, [
        dispatch,
        energyPerformanceChartOptionParameters,
        energySavingsChartOptionParameters,
        lifetimeCostsChartOptionParameters,
        powerlossCalculationChartOptionParameters
    ]);

    const chartRequest: ChartRequest = useMemo(() => ({
        alternativeSystem: {
            investmentCost: exchangedAlternativeInvestmentCost ?? 0,
            powerDemand: systemState.alternativeSystem?.powerDemand ?? [],
            IesSystem: systemState.alternativeSystem?.iesSystemData
        },
        referenceSystem: {
            investmentCost: exchangedReferenceInvetmentCost ?? 0,
            powerDemand: systemState.referenceSystem?.powerDemand ?? [],
            IesSystem: systemState.referenceSystem?.iesSystemData
        },
        applicationPowerDemand: systemState.application?.applicationPowerDemand ?? [],
        co2factor: regionSettings?.co2Factor ?? 0,
        contractPeriod: energyPerformanceChartTabParams.contractPeriod,
        energyPrice: exchangedEnergyPrice ?? 0,
        financingInterest: energyPerformanceChartTabParams.financingInterest,
        lifetimeNumberOfYears: lifetimeCostsChartTabParams.lifetimeNumberOfYears,
        scaleOperationTime: !lifetimeCostsChartTabParams.lifetimeNumberOfYearsUserInput,
        operationProfile: systemState.application?.operationProfile,
        powerLossAtRequiredShaftPower: powerlossCalculationChartTabParams.powerLossAtRequiredShaftPower,
        shaftPower: systemState.application?.shaftPower ?? 0,
        referenceIesPowerLoss: systemState.fullScreenChart && powerlossCalculationChartTabParams.showReferenceIesPowerLoss && systemState.application?.iesReferenceData.powerLosses
            ? systemState.application?.iesReferenceData.powerLosses
            : undefined
    }), [
        exchangedAlternativeInvestmentCost,
        systemState.alternativeSystem?.powerDemand,
        systemState.alternativeSystem?.iesSystemData,
        systemState.referenceSystem?.powerDemand,
        systemState.referenceSystem?.iesSystemData,
        systemState.application?.applicationPowerDemand,
        systemState.application?.operationProfile,
        systemState.application?.shaftPower,
        systemState.application?.iesReferenceData.powerLosses,
        systemState.fullScreenChart,
        exchangedReferenceInvetmentCost,
        regionSettings?.co2Factor,
        energyPerformanceChartTabParams.contractPeriod,
        energyPerformanceChartTabParams.financingInterest,
        exchangedEnergyPrice,
        lifetimeCostsChartTabParams.lifetimeNumberOfYears,
        powerlossCalculationChartTabParams
    ]);

    const updateChartData = useCallback(async () => {
        dispatch(chartDataLoadingHandling(true));

        await handleChartDataGet(chartRequest);

        dispatch(chartDataLoadingHandling(false));
    }, [chartRequest, dispatch, handleChartDataGet]);

    const debouncedUpdateChartData = useDebounceCallback(updateChartData);

    useEffect(() => {
        if (firstRun.current && !systemsLoading) {
            updateChartData();
            firstRun.current = false;
        } else {
            debouncedUpdateChartData();
        }
    }, [chartRequest, debouncedUpdateChartData, dispatch, handleChartDataGet, regionSettings, systemsLoading, updateChartData]);

    return {
        chartDataLoading,
        chartRequest,
        chartData,
        selectedComparisonCardTabId,
        selectedComparisonCardTabIdHandler,
        systemState
    };
};
