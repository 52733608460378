import React, { useMemo } from 'react';
import { numericFormatter } from 'react-number-format';
import { TRANSLATIONS } from '../../../constants';
import { useCurrency, useLifetimeCosts } from '../../../hooks';
import { useAppSelector, useTranslate } from '../../../hooks/common';
import Checkbox from '../../common/Checkbox';
import ComparisonCardTab, { Feedback } from './ComparisonCardTab';
import LifetimeCostsChart from '../Charts/LifetimeCostsChart/LifetimeCostsChart';
import TextInput from '../../common/TextInput';
import { ApplicationType } from '../../../types';
import ComparisonCardTotalCostsOfOwnershipInfo from './ModalInfo/ComparisonCardTotalCostsOfOwnershipInfo';
import { LifetimeCostsChartTabData, LifetimeCostsChartData } from '../../../models';

export interface LifetimeCostsTabProps {
    applicationType: ApplicationType;
}

const LifetimeCostsTab = ({ applicationType }: LifetimeCostsTabProps) => {
    const { translate } = useTranslate();
    const dark = useAppSelector(x => x.layout.darkMode);
    const { numberFormatOptions } = useAppSelector(x => x.regionSettings);
    const systemState = useAppSelector(state => isPumpSystem ? state.pumpSystem : state.fanSystem);
    const isPumpSystem = applicationType === 'pump';
    const {
        handleChangeNumberOfYears,
        lifetimeCostsChartData,
        lifetimeCostsChartTabData,
        handleShowSavings,
        chartOptionParameters,
        commercialFeedback,
        isCommercialFeedbackPositive
    } = useLifetimeCosts({ applicationType });
    const { currencySymbol } = useCurrency();

    const feedback: Feedback = useMemo(() => ({
        feedbackTitle: translate(TRANSLATIONS.generated['System.MostEconomical']),
        positiveFeedback: isCommercialFeedbackPositive,
        feedbackMessage: commercialFeedback
    }), [translate, commercialFeedback, isCommercialFeedbackPositive]);

    const getYearTranslation = (years: string, translationForOneYear: string, translationForXYears: string): string =>
        years === '1'
            ? translationForOneYear
            : translationForXYears.replace('{{x}}', years);

    const showAmortizationTime = lifetimeCostsChartData ? lifetimeCostsChartData.intersectionPoints.length !== 0 : false;

    const getAlternativeSystemAttributes = (charData: LifetimeCostsChartTabData | undefined, lifetimeCostsChartData: LifetimeCostsChartData) => {
        if (charData && charData?.alternativeEnergyCostLifetime < charData?.referenceEnergyCostLifetime) {
            return [
                {
                    label: getYearTranslation(
                        chartOptionParameters.lifetimeNumberOfYearsString,
                        translate(TRANSLATIONS.generated['System.ExpectedEnergyCost']),
                        translate(TRANSLATIONS.generated['System.ExpectedEnergyCostForXYears'])
                    ),
                    value: numericFormatter(charData?.alternativeEnergyCostLifetime.toString() ?? '', numberFormatOptions),
                    unit: currencySymbol
                },
                {
                    label: translate(TRANSLATIONS.generated['Common.RequiredInvestmentCosts']),
                    value: numericFormatter((charData?.alternativeInvestmentCost ?? 0).toString(), numberFormatOptions),
                    unit: currencySymbol
                },
                {
                    label: getYearTranslation(
                        chartOptionParameters.lifetimeNumberOfYearsString,
                        translate(TRANSLATIONS.generated['System.ExpectedEnergyCostSavingIn1Year']),
                        translate(TRANSLATIONS.generated['System.ExpectedEnergyCostSavingInXYears'])
                    ),
                    value: numericFormatter(((charData?.referenceEnergyCostLifetime ?? 0) - (charData?.alternativeEnergyCostLifetime ?? 0)).toString(), numberFormatOptions),
                    unit: currencySymbol
                },
                {
                    label: translate(TRANSLATIONS.generated['System.InvestmentDifference']),
                    value: numericFormatter(((systemState.alternativeSystemInvestmentCosts ?? 0) - (systemState.referenceSystemInvestmentCosts ?? 0)).toString(), numberFormatOptions),
                    unit: currencySymbol
                },
                ...showAmortizationTime ? [{
                    label: translate(TRANSLATIONS.generated['System.AmortizationTime']),
                    value: numericFormatter(lifetimeCostsChartData?.intersectionPoints[0] ? lifetimeCostsChartData?.intersectionPoints[0].x.toFixed(1) : 'Na', numberFormatOptions),
                    unit: translate(TRANSLATIONS.generated['System.Units.Month'])
                }] : [],
                {
                    label: getYearTranslation(
                        chartOptionParameters.lifetimeNumberOfYearsString,
                        translate(TRANSLATIONS.generated['Project.TotalSavingsIn1Year']),
                        translate(TRANSLATIONS.generated['Project.TotalSavingsIn'])
                    ),
                    value: numericFormatter(lifetimeCostsChartData?.barChartData[1].savings.toString(), numberFormatOptions),
                    unit: currencySymbol
                }
            ];
        } else {
            return [
                {
                    label: getYearTranslation(
                        chartOptionParameters.lifetimeNumberOfYearsString,
                        translate(TRANSLATIONS.generated['System.ExpectedEnergyCost']),
                        translate(TRANSLATIONS.generated['System.ExpectedEnergyCostForXYears'])
                    ),
                    value: numericFormatter(charData?.alternativeEnergyCostLifetime.toString() ?? '', numberFormatOptions),
                    unit: currencySymbol
                },
                {
                    label: translate(TRANSLATIONS.generated['Common.RequiredInvestmentCosts']),
                    value: numericFormatter((charData?.alternativeInvestmentCost ?? 0).toString(), numberFormatOptions),
                    unit: currencySymbol
                }
            ];
        }
    };

    const getReferenceSystemAttributes = (chartData: LifetimeCostsChartTabData | undefined, lifetimeCostsChartData: LifetimeCostsChartData) => {
        if (chartData && chartData?.referenceEnergyCostLifetime < chartData?.alternativeEnergyCostLifetime) {
            return [
                {
                    label: getYearTranslation(
                        chartOptionParameters.lifetimeNumberOfYearsString,
                        translate(TRANSLATIONS.generated['System.ExpectedEnergyCost']),
                        translate(TRANSLATIONS.generated['System.ExpectedEnergyCostForXYears'])
                    ),
                    value: numericFormatter(chartData?.referenceEnergyCostLifetime.toString() ?? '', numberFormatOptions),
                    unit: currencySymbol
                },
                {
                    label: translate(TRANSLATIONS.generated['Common.RequiredInvestmentCosts']),
                    value: numericFormatter((chartData?.referenceInvestmentCost ?? 0).toString() ?? '', numberFormatOptions),
                    unit: currencySymbol
                },
                {
                    label: getYearTranslation(
                        chartOptionParameters.lifetimeNumberOfYearsString,
                        translate(TRANSLATIONS.generated['System.ExpectedEnergyCostSavingIn1Year']),
                        translate(TRANSLATIONS.generated['System.ExpectedEnergyCostSavingInXYears'])
                    ),
                    value: numericFormatter(Math.abs((chartData?.referenceEnergyCostLifetime ?? 0) - (chartData?.alternativeEnergyCostLifetime ?? 0)).toString(), numberFormatOptions),
                    unit: currencySymbol
                },
                {
                    label: translate(TRANSLATIONS.generated['System.InvestmentDifference']),
                    value: numericFormatter(Math.abs((chartData?.alternativeInvestmentCost ?? 0) - (chartData?.referenceInvestmentCost ?? 0)).toString(), numberFormatOptions),
                    unit: currencySymbol
                }
            ];
        } else {
            return [
                {
                    label: getYearTranslation(
                        chartOptionParameters.lifetimeNumberOfYearsString,
                        translate(TRANSLATIONS.generated['System.ExpectedEnergyCost']),
                        translate(TRANSLATIONS.generated['System.ExpectedEnergyCostForXYears'])
                    ),
                    value: numericFormatter(chartData?.referenceEnergyCostLifetime.toString() ?? '', numberFormatOptions),
                    unit: currencySymbol
                },
                {
                    label: translate(TRANSLATIONS.generated['Common.RequiredInvestmentCosts']),
                    value: numericFormatter((chartData?.referenceInvestmentCost ?? 0).toString() ?? '', numberFormatOptions),
                    unit: currencySymbol
                }
            ];
        }
    };

    return lifetimeCostsChartData ?
        <ComparisonCardTab
            applicationType={applicationType}
            chart={
                <LifetimeCostsChart
                    data={lifetimeCostsChartData}
                    showFinancialSavingPotential={chartOptionParameters.showSavings}
                />
            }
            checkboxes={[
                <Checkbox
                    key='financial-savings'
                    label={translate(TRANSLATIONS.generated['System.FinancialSavingPotential'])}
                    checked={chartOptionParameters.showSavings}
                    onChange={handleShowSavings}
                    dark={dark}
                />
            ]}
            inputs={[
                <TextInput
                    key='years-of-operation'
                    label={translate(TRANSLATIONS.generated['Common.Lifetime'])}
                    name='yearsOfOperation'
                    value={chartOptionParameters.lifetimeNumberOfYearsString}
                    onChange={x => handleChangeNumberOfYears(x)}
                    dark={dark}
                    numberFormatOptions={{
                        suffix: chartOptionParameters.lifetimeNumberOfYearsString === '1'
                            ? ` ${translate(TRANSLATIONS.generated['System.Year'])}`
                            : ` ${translate(TRANSLATIONS.generated['System.Years'])}`
                    }}
                />
            ]}
            alternativeSystemAttributes={getAlternativeSystemAttributes(lifetimeCostsChartTabData, lifetimeCostsChartData)}
            referenceSystemAttributes={getReferenceSystemAttributes(lifetimeCostsChartTabData, lifetimeCostsChartData)}
            modalContent={<ComparisonCardTotalCostsOfOwnershipInfo />}
            feedback={feedback}
        />
        : <></>;
};

export default LifetimeCostsTab;
