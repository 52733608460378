import { useCallback } from 'react';
import { Application, DocumentExportRequest, RegionSettings, SupplyType, SystemConfiguration, UiText, Warning } from '../../models';
import { Api, apiCall, getApplicationActions } from '../../services';
import { ApplicationType } from '../../types';
import { useAppSelector, useTranslate } from '../common';
import { useComparisonCard } from './ComparisonCard';
import { Language } from '../../enums';
import { useDispatch } from 'react-redux';
import { addSnackbar } from '../../store';
import { TRANSLATIONS } from '../../constants';
import { useUiText } from './UiText';

export interface DocumentExportProps {
    applicationType: ApplicationType;
}

export const useDocumentExport = ({ applicationType }: DocumentExportProps) => {
    const dispatch = useDispatch();
    const { translate } = useTranslate();
    const isPumpSystem = applicationType === 'pump';
    const languageCode = Language[useAppSelector(state => state.translation.language)].toLowerCase();
    const systemState = useAppSelector(state => isPumpSystem ? state.pumpSystem : state.fanSystem);
    const commercialData = useAppSelector(state => isPumpSystem ? state.pumpSystemsCommercialData : state.fanSystemsCommercialData);
    const regionSettings = useAppSelector(state => state.regionSettings.regionSettings);
    const { chartData } = useComparisonCard({ applicationType, systemsLoading: systemState.systemsLoading });
    const { systemsLoadingHandler } = getApplicationActions(applicationType);
    const uiText = useUiText({ applicationType });

    const hasMepsWarning = systemState.alternativeHasMepsWarning || systemState.referenceHasMepsWarning;
    const hasUnequallyRatedIesSystems =
        systemState.alternativeSystem?.iesSystemData?.nominalPower !== undefined &&
        systemState.referenceSystem?.iesSystemData?.nominalPower !== undefined &&
        systemState.alternativeSystem.iesSystemData.nominalPower !== systemState.referenceSystem.iesSystemData.nominalPower;

    const warning: Warning = {
        hasMepsWarning,
        hasUnequallyRatedIesSystems,
        hasObsoleteComponents: false
    };

    const exportDownload = useCallback(async (documentType: string) => {
        const documentExportRequest: DocumentExportRequest = {
            documentExportData: {
                alternativeSystemCommercialData: commercialData.alternativeSystemCommercialData,
                alternativeSystemConfiguration: systemState.alternativeSystem ?? {} as SystemConfiguration,
                application: systemState.application ?? {} as Application,
                chartData: chartData,
                languageCode,
                documentType,
                regionSettings: regionSettings ?? {} as RegionSettings,
                referenceSystemCommercialData: commercialData.referenceSystemCommercialData,
                referenceSystemConfiguration: systemState.referenceSystem ?? {} as SystemConfiguration,
                warnings: warning ?? {} as Warning,
                supplyType: systemState.supplyType ?? {} as SupplyType,
                uiText: uiText ?? {} as UiText
            }
        };

        return apiCall(
            Api.exportDocument(documentExportRequest),
            async x => {
                apiCall(
                    Api.downloadDocument(x.data),
                    async x => {
                        const link = document.createElement('a');

                        link.href = `data:${x.data.contentType};base64,${x.data.fileContents}`;
                        link.download = x.data.fileDownloadName;
                        document.body.appendChild(link);

                        link.click();

                        document.body.removeChild(link);
                    },
                    async () => {
                        dispatch(addSnackbar({
                            title: translate(TRANSLATIONS.error.error),
                            description: translate(TRANSLATIONS.error.api.documentDownload), type: 'error'
                        }));
                    }
                );
            },
            async () => {
                dispatch(addSnackbar({
                    title: translate(TRANSLATIONS.error.error),
                    description: translate(TRANSLATIONS.error.api.documentExport), type: 'error'
                }));
            }
        );
    }, [
        chartData,
        commercialData.alternativeSystemCommercialData,
        commercialData.referenceSystemCommercialData,
        dispatch,
        languageCode,
        regionSettings,
        systemState.alternativeSystem,
        systemState.application,
        systemState.referenceSystem,
        translate
    ]);

    const handleExportDownload = async (documentType: string) => {
        dispatch(systemsLoadingHandler(true));

        await exportDownload(documentType);

        dispatch(systemsLoadingHandler(false));
    };

    return { handleExportDownload };
};
