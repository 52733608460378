import React, { useCallback, useMemo } from 'react';
import { NumberInputItem } from '../../../models';
import { DataType } from '../../../enums';
import TextInput from '../../common/TextInput';
import { InputAttributes, NumericFormatProps } from 'react-number-format';
import { useTranslate } from '../../../hooks/common';
import { TRANSLATIONS } from '../../../constants';

export interface NumberInputElementProps {
    input: NumberInputItem;
    dark: boolean;
    handleSystemInputChange: (inputId: string, value: string, dataType?: DataType) => void;
}

const NumberInputElement = ({ input, dark, handleSystemInputChange }: NumberInputElementProps) => {
    const handleNumberInputElementChange = useCallback((value: string) => handleSystemInputChange(input.id, value, input.dataType), [handleSystemInputChange, input.dataType, input.id]);
    const { dynamicTranslate } = useTranslate();
    const numberFormatOptions: NumericFormatProps<InputAttributes> = useMemo(() => ({
        thousandSeparator: ',',
        allowNegative: false,
        decimalSeparator: '.',
        decimalScale: 2,
        allowLeadingZeros: false,
        suffix: ` ${dynamicTranslate(TRANSLATIONS.generated, input.unit)}`
    }), [dynamicTranslate, input.unit]);
    
    return <TextInput
        label={dynamicTranslate(TRANSLATIONS.generated, input.name)}
        value={input.value.toString()}
        dark={dark}
        disabled={input.isDisabled}
        name={input.name}
        numberFormatOptions={numberFormatOptions}
        onChange={handleNumberInputElementChange}
    />;
};

export default NumberInputElement;
