import React from 'react';
import {
    EnergySavingsData,
    EnergySavingsChartDataPoint,
    EnergySavingsChartData,
    EnergyPerformanceChartData,
    EnergyPerformanceChartDataPoint,
    EnergyPerformanceData,
    IntersectionPoint,
    LifetimeCostsChartBarDataPoint,
    LifetimeCostsChartData,
    LifetimeCostsChartDataPoint,
    LifetimeCostsData,
    LineSegment,
    PowerLoss,
    PowerlossCalculationChartData,
    PowerlossCalculationData,
    ServiceDataResult,
    EnergyPerformanceChartTabData,
    OperationProfile,
    ChartRequest,
    ChartData,
    EfficiencyProfile,
    EnergySavingsChartTabData,
    LifetimeCostsChartTabData,
    AllocationProfile,
    AmortizationResult
} from '../models';
import { POWERLOSS_CALCULATION_BAR_SIZE_SCALE, POWER_LOSS_SPEEDS, POWER_LOSS_TORQUES } from '../constants';

// TODO: move the data gathering logic to backend
export class ChartMockApi {
    private static readonly numberOfTicks = 6;

    public static getChartData = (chartRequest: ChartRequest): Promise<ServiceDataResult<ChartData>> => {
        const energySavingsData: EnergySavingsData = {
            alternativePowerDemand: chartRequest.alternativeSystem.powerDemand,
            co2Factor: chartRequest.co2factor,
            referencePowerDemand: chartRequest.referenceSystem.powerDemand,
            applicationPowerDemand: chartRequest.applicationPowerDemand,
            operationProfile: chartRequest.operationProfile
        };

        const energySavingsChartData = this.getEnergySavingsChartData(energySavingsData);

        const energySavingsChartTabData = this.getEnergySavingsChartTabData(energySavingsData.alternativePowerDemand, energySavingsData.referencePowerDemand, chartRequest.co2factor, energySavingsData.operationProfile);

        const lifetimeCostsData: LifetimeCostsData = {
            alternativeEnergyConsumption: energySavingsChartTabData.alternativeEnergyConsumption,
            referenceEnergyConsumption: energySavingsChartTabData.referenceEnergyConsumption,
            energyPrice: chartRequest.energyPrice,
            numberOfYears: chartRequest.lifetimeNumberOfYears,
            alternativeInvestmentCost: chartRequest.alternativeSystem.investmentCost,
            referenceInvestmentCost: chartRequest.referenceSystem.investmentCost
        };

        const lifetimeCostsChartTabData = this.getLifetimeCostsChartTabData(
            lifetimeCostsData.referenceEnergyConsumption,
            lifetimeCostsData.alternativeEnergyConsumption,
            lifetimeCostsData.numberOfYears,
            lifetimeCostsData.alternativeInvestmentCost,
            lifetimeCostsData.referenceInvestmentCost,
            lifetimeCostsData.energyPrice
        );

        const adjustedNumberOfYears = Math.min(99, chartRequest.scaleOperationTime ? chartRequest.lifetimeNumberOfYears < lifetimeCostsChartTabData.amortizationResult.amortizationYears
            ? lifetimeCostsChartTabData.amortizationResult.amortizationYears * 2
            : chartRequest.lifetimeNumberOfYears : chartRequest.lifetimeNumberOfYears);

        const lifetimeCostsChartData = this.getLifetimeCostsChartData(lifetimeCostsData, lifetimeCostsChartTabData, adjustedNumberOfYears);

        const powerlossCalculationData: PowerlossCalculationData = {
            alternativePowerLoss: chartRequest.alternativeSystem.IesSystem?.powerLosses,
            referencePowerLoss: chartRequest.referenceSystem.IesSystem?.powerLosses,
            referenceIesPowerLoss: chartRequest.referenceIesPowerLoss
        };

        const energyPerformanceData: EnergyPerformanceData = {
            alternativeEnergyCost: lifetimeCostsChartTabData.alternativeEnergyCost,
            alternativeInvestmentCost: chartRequest.alternativeSystem.investmentCost,
            contractPeriod: chartRequest.contractPeriod,
            financingInterest: chartRequest.financingInterest,
            referenceEnergyCost: lifetimeCostsChartTabData.referenceEnergyCost
        };

        const energyPerformanceChartTabData = this.getEnergyPerformanceCalculatedData(energyPerformanceData);

        const alternativeShaftPowerMultiplyer = chartRequest.powerLossAtRequiredShaftPower ? chartRequest.shaftPower / (chartRequest?.alternativeSystem?.IesSystem?.nominalPower ?? chartRequest.shaftPower) : 1;
        const referenceShaftPowerMultiplyer = chartRequest.powerLossAtRequiredShaftPower ? chartRequest.shaftPower / (chartRequest?.referenceSystem?.IesSystem?.nominalPower ?? chartRequest.shaftPower) : 1;

        return Promise.resolve({
            hasError: false,
            errorMessage: '',
            errorCode: 200,
            data: {
                energyPerformanceChartData: this.getEnergyPerformanceChartData(energyPerformanceData, energyPerformanceChartTabData),
                energyPerformanceChartTabData,
                energySavingsChartData,
                lifetimeCostsChartData,
                lifetimeCostsChartTabData,
                powerlossCalculationChartData: this.getPowerlossCalculationChartData(powerlossCalculationData, alternativeShaftPowerMultiplyer, referenceShaftPowerMultiplyer),
                energySavingsChartTabData
            }
        });
    };

    public static getEfficiencyProfileValues = (efficiencyProfile: EfficiencyProfile | OperationProfile | undefined) => efficiencyProfile
        ? [
            efficiencyProfile.p10,
            efficiencyProfile.p20,
            efficiencyProfile.p30,
            efficiencyProfile.p40,
            efficiencyProfile.p50,
            efficiencyProfile.p60,
            efficiencyProfile.p70,
            efficiencyProfile.p80,
            efficiencyProfile.p90,
            efficiencyProfile.p100
        ]
        : [];

    public static getEnergyPerformanceChartData = (data: EnergyPerformanceData, chartTabData: EnergyPerformanceChartTabData): EnergyPerformanceChartData => {
        const months = this.getMonthsForEnergyPerformance(data.contractPeriod);
        const chartData = this.createEnergyPerformanceDataPoints(months, data.contractPeriod, chartTabData);

        return {
            chartData,
            months,
            differenceInContractPeriod: chartData.length ? ((chartTabData.referenceEnergyCosts - chartTabData.runningCosts) / chartTabData.referenceEnergyCosts) * 100 : 0,
            differenceOutsideContractPeriod: chartData.length ? ((chartTabData.referenceEnergyCosts - chartTabData.alternativeEnergyCosts) / chartTabData.referenceEnergyCosts) * 100 : 0,
            contractPeriod: data.contractPeriod
        };
    };

    public static getLifetimeCostsChartTabData = (
        referenceEnergyConsumption: number,
        alternativeEnergyConsumption: number,
        numberOfYears: number,
        alternativeInvestmentCost: number,
        referenceInvestmentCost: number,
        energyPrice: number
    ): LifetimeCostsChartTabData => {
        const referenceEnergyCost = referenceEnergyConsumption * energyPrice;
        const alternativeEnergyCost = alternativeEnergyConsumption * energyPrice;
        const amortizationResult = this.getAmortizationResult(referenceInvestmentCost, alternativeInvestmentCost, referenceEnergyCost, alternativeEnergyCost);

        return {
            amortizationResult,
            alternativeEnergyCost: alternativeEnergyCost,
            alternativeEnergyCostLifetime: alternativeEnergyCost * numberOfYears,
            referenceEnergyCost: referenceEnergyCost,
            referenceEnergyCostLifetime: referenceEnergyCost * numberOfYears,
            alternativeInvestmentCost: alternativeInvestmentCost,
            referenceInvestmentCost: referenceInvestmentCost
        };
    };

    public static getLifetimeCostsChartData = (chartData: LifetimeCostsData, chartTabData: LifetimeCostsChartTabData, numberOfYears: number): LifetimeCostsChartData => {
        const months = this.getMonths(numberOfYears);
        const lineChartData = this.createLifetimeCostsDataPoints(months, chartTabData.alternativeEnergyCost, chartTabData.referenceEnergyCost, chartData);
        const intersectionPoints = this.getLifetimeCostsIntersectionPoints(lineChartData);

        return {
            barChartData: this.createBarChartDataPoints(chartTabData.alternativeEnergyCost, chartTabData.referenceEnergyCost, numberOfYears, chartData),
            lineChartData,
            intersectionPoints,
            months
        };
    };

    public static getAmortizationResult = (refInvestment: number, altInvestment: number, refCost: number, altCost: number): AmortizationResult => {
        let amortizationYears = 0;

        const differenceInvestment = Math.abs(refInvestment - altInvestment);
        const savings = Math.abs(refCost - altCost);

        if (savings !== 0) {
            amortizationYears = differenceInvestment / savings;
            const shouldNotNegate = refInvestment > altInvestment ? altCost > refCost : altCost < refCost;
            amortizationYears = shouldNotNegate ? amortizationYears : -amortizationYears;
        }

        return {
            amortizationYears: Math.round(amortizationYears * 10) / 10,
            amortization: amortizationYears <= (23 / 12) ? amortizationYears * 12 : amortizationYears,
            unit: amortizationYears <= (23 / 12) ? 'System.Units.Month' : 'System.Units.Annual'
        };
    };

    public static getEnergySavingsChartTabData = (alternativePowerDemand: number[], referencePowerDemand: number[], co2Factor: number, operationProfile?: OperationProfile): EnergySavingsChartTabData => {
        const operatingHours = this.getOperatingHours(operationProfile?.allocationProfile, operationProfile?.operatingHour?.factor);
        const alternativeEnergyConsumption = this.thousandRounding(this.getEnergyConsumption(alternativePowerDemand, operationProfile?.operatingHour?.workingDaysPerYear, operatingHours));
        const referenceEnergyConsumption = this.thousandRounding(this.getEnergyConsumption(referencePowerDemand, operationProfile?.operatingHour?.workingDaysPerYear, operatingHours));
        const alternativeEnergySavings = referenceEnergyConsumption - alternativeEnergyConsumption;
        const alternativeCo2EmissionSavings = this.thousandRounding(alternativeEnergySavings * co2Factor) / 1000;

        return {
            alternativeCo2EmissionSavings,
            alternativeEnergyConsumption,
            alternativeEnergySavings,
            referenceEnergyConsumption
        };
    };

    public static getEnergySavingsChartData = (data: EnergySavingsData): EnergySavingsChartData => {
        const lineChartData = this.createEnergySavingsChartDataPoints(data);
        const intersectionPoints = this.getEnergySavingsIntersectionPoints(lineChartData);

        return {
            chartData: this.createEnergySavingsChartDataPoints(data),
            intersectionPoints
        };
    };

    private static getEnergyConsumption = (powerDemand: number[], workingDaysPerYear?: number, operationProfile?: number[]): number => {
        let consumption = 0;

        if (operationProfile && powerDemand.length == 10 && operationProfile) {
            for (let i = 0; i < 10; i++) {
                const current = powerDemand[i];
                consumption += current * operationProfile[i] * (workingDaysPerYear ?? 0);
            }
        } else if (operationProfile && powerDemand.length == 11 && operationProfile) // Pole Changing system
        {
            let j = 0;

            for (let i = 0; i < 11; i++) {
                if (i != 5) // skip 50% high part
                {
                    const current = powerDemand[i];
                    consumption += current * operationProfile[j++] * (workingDaysPerYear ?? 0);
                }
            }
        }

        return consumption;
    };

    private static getEnergyPerformanceCalculatedData = (data: EnergyPerformanceData): EnergyPerformanceChartTabData => {
        const rateVariable = data.financingInterest / 100 / 12;
        const contractRate = data.alternativeInvestmentCost * Math.pow(1 + rateVariable, data.contractPeriod * 12) * rateVariable / (Math.pow(1 + rateVariable, data.contractPeriod * 12) - 1);
        const financingCosts = (contractRate * 12 * data.contractPeriod) - data.alternativeInvestmentCost;
        const referenceEnergyCosts = data.referenceEnergyCost / 12;
        const alternativeEnergyCosts = data.alternativeEnergyCost / 12;
        const runningCosts = alternativeEnergyCosts + contractRate;
        const totalCosts = data.alternativeEnergyCost + financingCosts;
        const roundedReferenceEnergyCosts = this.thousandRounding(referenceEnergyCosts);
        const roundedAlternativeEnergyCosts = this.thousandRounding(alternativeEnergyCosts);

        return {
            contractRate,
            financingCosts,
            referenceEnergyCosts: roundedReferenceEnergyCosts,
            alternativeEnergyCosts: roundedAlternativeEnergyCosts,
            runningCosts,
            totalCosts
        };
    };

    private static createEnergyPerformanceDataPoints = (months: number[], contractPeriod: number, chartTabData: EnergyPerformanceChartTabData): EnergyPerformanceChartDataPoint[] => {
        let chartData: EnergyPerformanceChartDataPoint[] = [];

        if (chartTabData) {
            chartData = months.map(x => {
                const adjustedContractRate = x <= 0 ? 0 : x > (contractPeriod * 12) ? 0 : chartTabData.contractRate;
                const adjustedEnergyCosts = x <= 0 ? chartTabData.referenceEnergyCosts : chartTabData.alternativeEnergyCosts + adjustedContractRate;
                const adjustedEnergyCostsWithoutContract = x <= 0 ? chartTabData.referenceEnergyCosts : chartTabData.alternativeEnergyCosts;
                const adjustedAlternativeEnergyCosts = x <= 0 ? chartTabData.referenceEnergyCosts : chartTabData.alternativeEnergyCosts;

                return {
                    month: x,
                    alternativeEnergyCosts: chartTabData.alternativeEnergyCosts,
                    contractRate: adjustedContractRate,
                    contractRateRange: [chartTabData.alternativeEnergyCosts, chartTabData.alternativeEnergyCosts + adjustedContractRate],
                    energyCostsRange: [0, adjustedAlternativeEnergyCosts],
                    financingCosts: chartTabData.financingCosts,
                    referenceEnergyCosts: chartTabData.referenceEnergyCosts,
                    runningCosts: x <= 0 ? chartTabData.referenceEnergyCosts : x > (contractPeriod * 12) ? chartTabData.alternativeEnergyCosts : chartTabData.runningCosts,
                    runningCostsWithoutContract: x <= 0 ? chartTabData.referenceEnergyCosts : chartTabData.alternativeEnergyCosts,
                    savingsRange: [adjustedEnergyCosts, chartTabData.referenceEnergyCosts],
                    savingsRangeWithoutContract: [adjustedEnergyCostsWithoutContract, chartTabData.referenceEnergyCosts],
                    totalCosts: chartTabData.totalCosts
                };
            });
        }

        return chartData;
    };

    public static getPowerlossCalculationChartData = (data: PowerlossCalculationData, alternativeShaftPowerMultiplyer: number, referenceShaftPowerMultiplyer: number): PowerlossCalculationChartData => ({
        chartData: this.createCalculationChartData(data, alternativeShaftPowerMultiplyer, referenceShaftPowerMultiplyer)
    });

    private static getMonthsForEnergyPerformance = (numberOfYears: number): number[] => new Array(numberOfYears + 5).fill(0).map((x, i) => (i - 2) * 12);

    private static getMonths = (numberOfYears: number): number[] => new Array(this.numberOfTicks + 1).fill(0).map((x, i) => i * (numberOfYears * 12 / this.numberOfTicks));

    private static getDataForBar = (torque: number, speed: number, powerLosses: PowerLoss[], shaftPowerMultiplyer: number) => {
        const currentPowerLoss = powerLosses.find(x => x.torque == torque && x?.speed == speed)?.value;
        const scalingParameter = POWERLOSS_CALCULATION_BAR_SIZE_SCALE / Math.max(...powerLosses.flatMap(x => x.value));

        return currentPowerLoss ? parseFloat((currentPowerLoss * shaftPowerMultiplyer * scalingParameter).toFixed(2)) + torque : undefined;
    };

    private static getDataForDisplay = (torque: number, speed: number, shaftPowerMultiplier: number, powerLosses?: PowerLoss[]) => {
        const currentPowerLossValue = powerLosses?.find(x => x.torque == torque && x?.speed == speed)?.value;
        return currentPowerLossValue ? parseFloat((currentPowerLossValue * shaftPowerMultiplier).toFixed(2)) : undefined;
    };

    private static createBarChartDataPoints = (alternativeEnergyCost: number, referenceEnergyCost: number, numberOfYears: number, data?: LifetimeCostsData): LifetimeCostsChartBarDataPoint[] => {
        let barChartData: LifetimeCostsChartBarDataPoint[] = [];

        if (data) {
            const lifetimeAlternativeEnergyCost = this.hundredRounding(alternativeEnergyCost * numberOfYears);
            const lifetimeReferenceEnergyCost = this.hundredRounding(referenceEnergyCost * numberOfYears);
            const referenceSavings = lifetimeReferenceEnergyCost < lifetimeAlternativeEnergyCost ? (lifetimeAlternativeEnergyCost + data.alternativeInvestmentCost) - (lifetimeReferenceEnergyCost + data.referenceInvestmentCost) : 0;
            const alternativeSavings = lifetimeAlternativeEnergyCost < lifetimeReferenceEnergyCost ? (lifetimeReferenceEnergyCost + data.referenceInvestmentCost) - (lifetimeAlternativeEnergyCost + data.alternativeInvestmentCost) : 0;
            const referenceTotalCosts = Math.round(data.referenceInvestmentCost + lifetimeReferenceEnergyCost);
            const alternativeTotalCosts = Math.round(data.alternativeInvestmentCost + lifetimeAlternativeEnergyCost);
            const highestTotalCost = referenceTotalCosts > alternativeTotalCosts ? referenceTotalCosts : alternativeTotalCosts;

            barChartData = [
                {
                    systemType: 'reference',
                    energyCost: Math.round(lifetimeReferenceEnergyCost),
                    energyCostPercent: Math.round(lifetimeReferenceEnergyCost / highestTotalCost * 100),
                    investmentCost: Math.round(data.referenceInvestmentCost),
                    investmentCostPercent: Math.round(data.referenceInvestmentCost / highestTotalCost * 100),
                    savings: Math.round(referenceSavings),
                    savingsPercent: Math.round(referenceSavings / highestTotalCost * 100),
                    totalCosts: referenceTotalCosts
                },
                {
                    systemType: 'alternative',
                    energyCost: Math.round(lifetimeAlternativeEnergyCost),
                    energyCostPercent: Math.round(lifetimeAlternativeEnergyCost / highestTotalCost * 100),
                    investmentCost: Math.round(data.alternativeInvestmentCost),
                    investmentCostPercent: Math.round(data.alternativeInvestmentCost / highestTotalCost * 100),
                    savings: Math.round(alternativeSavings),
                    savingsPercent: Math.round(alternativeSavings / highestTotalCost * 100),
                    totalCosts: alternativeTotalCosts
                }
            ];
        }

        return barChartData;
    };

    private static createLifetimeCostsDataPoints = (months: number[], alternativeEnergyCost: number, referenceEnergyCost: number, data?: LifetimeCostsData): LifetimeCostsChartDataPoint[] => {
        let chartData: LifetimeCostsChartDataPoint[] = [];
        const alternativeMonthlyEnergyCost = alternativeEnergyCost / 12;
        const referenceMonthlyEnergyCost = referenceEnergyCost / 12;

        if (data) {
            chartData = months.map(x => {
                const alternativeCost = data.alternativeInvestmentCost + (x * alternativeMonthlyEnergyCost);
                const referenceCost = data.referenceInvestmentCost + (x * referenceMonthlyEnergyCost);

                return {
                    month: x,
                    alternativeCost: alternativeCost,
                    referenceCost: referenceCost,
                    range: [alternativeCost, referenceCost]
                };
            }) ?? [];
        }

        return chartData ?? [];
    };

    public static createEnergySavingsChartDataPoints = (data?: EnergySavingsData) => {
        let chartData: EnergySavingsChartDataPoint[] = [];
        const operatingHours = this.getOperatingHours(data?.operationProfile?.allocationProfile, data?.operationProfile?.operatingHour?.factor);

        if (data) {
            if (data.alternativePowerDemand.length == 10 && data.referencePowerDemand.length == 10) {
                chartData = data.alternativePowerDemand?.map((x, i) => ({
                    flowrate: (i + 1) * 10,
                    alternativePowerDemand: x,
                    referencePowerDemand: data.referencePowerDemand ? data.referencePowerDemand[i] : 0,
                    operationProfile: operatingHours[i],
                    applicationPowerDemand: data.applicationPowerDemand ? parseFloat(data.applicationPowerDemand[i].toFixed(2)) : 0,
                    range: [data.referencePowerDemand ? data.referencePowerDemand[i] : 0, data.alternativePowerDemand ? data.alternativePowerDemand[i] : 0]
                })) ?? [];
            } else if (data.alternativePowerDemand.length == 11 && data.referencePowerDemand.length == 10) {
                chartData = data.alternativePowerDemand?.reduce<EnergySavingsChartDataPoint[]>((dataPoints, x, i) => {
                    if (i > 9) return dataPoints;

                    if (i == 5) {
                        const dataPoint: EnergySavingsChartDataPoint = {
                            flowrate: 50,
                            alternativePowerDemand: x,
                            referencePowerDemand: data.referencePowerDemand[i - 1],
                            operationProfile: operatingHours[i] ? operatingHours[i] : 0,
                            applicationPowerDemand: data.applicationPowerDemand ? parseFloat(data.applicationPowerDemand[i - 1].toFixed(2)) : 0,
                            range: [data.referencePowerDemand[i - 1], x]
                        };
                        dataPoints.push(dataPoint);
                    }
                    const dataPoint: EnergySavingsChartDataPoint = {
                        flowrate: (i + 1) * 10,
                        alternativePowerDemand: i > 4 ? data.alternativePowerDemand[i + 1] : x,
                        referencePowerDemand: data.referencePowerDemand ? data.referencePowerDemand[i] : 0,
                        operationProfile: operatingHours[i],
                        applicationPowerDemand: data.applicationPowerDemand ? parseFloat(data.applicationPowerDemand[i].toFixed(2)) : 0,
                        range: [data.referencePowerDemand ? data.referencePowerDemand[i] : 0, data.alternativePowerDemand ? i > 4 ? data.alternativePowerDemand[i + 1] : data.alternativePowerDemand[i] : 0]
                    };
                    dataPoints.push(dataPoint);

                    return dataPoints;
                }, []) ?? [];
            } else if (data.referencePowerDemand.length == 11 && data.alternativePowerDemand.length == 10) {
                chartData = data.referencePowerDemand?.reduce<EnergySavingsChartDataPoint[]>((dataPoints, x, i) => {
                    if (i > 9) return dataPoints;

                    if (i == 5) {
                        const dataPoint: EnergySavingsChartDataPoint = {
                            flowrate: 50,
                            alternativePowerDemand: data.alternativePowerDemand[i - 1],
                            referencePowerDemand: x,
                            operationProfile: operatingHours[i] ? operatingHours[i] : 0,
                            applicationPowerDemand: data.applicationPowerDemand ? parseFloat(data.applicationPowerDemand[i - 1].toFixed(2)) : 0,
                            range: [x, data.alternativePowerDemand[i - 1]]
                        };
                        dataPoints.push(dataPoint);
                    }
                    const dataPoint: EnergySavingsChartDataPoint = {
                        flowrate: (i + 1) * 10,
                        alternativePowerDemand: data.alternativePowerDemand[i],
                        referencePowerDemand: i > 4 ? data.referencePowerDemand[i + 1] : data.referencePowerDemand[i],
                        operationProfile: operatingHours[i],
                        applicationPowerDemand: data.applicationPowerDemand ? parseFloat(data.applicationPowerDemand[i].toFixed(2)) : 0,
                        range: [data.referencePowerDemand ? i > 4 ? data.referencePowerDemand[i + 1] : data.referencePowerDemand[i] : 0, data.alternativePowerDemand ? data.alternativePowerDemand[i] : data.alternativePowerDemand[i + 1]]
                    };
                    dataPoints.push(dataPoint);

                    return dataPoints;
                }, []) ?? [];
            } else {
                chartData = data.alternativePowerDemand?.reduce<EnergySavingsChartDataPoint[]>((dataPoints, x, i) => {
                    if (i > 9) return dataPoints;

                    if (i == 5) {
                        const dataPoint: EnergySavingsChartDataPoint = {
                            flowrate: 50,
                            alternativePowerDemand: data.referencePowerDemand[i],
                            referencePowerDemand: x,
                            operationProfile: operatingHours[i] ? operatingHours[i] : 0,
                            applicationPowerDemand: data.applicationPowerDemand ? parseFloat(data.applicationPowerDemand[i - 1].toFixed(2)) : 0,
                            range: [x, data.referencePowerDemand[i]]
                        };
                        dataPoints.push(dataPoint);
                    }
                    const dataPoint: EnergySavingsChartDataPoint = {
                        flowrate: (i + 1) * 10,
                        alternativePowerDemand: i > 4 ? data.alternativePowerDemand[i + 1] : x,
                        referencePowerDemand: i > 4 ? data.referencePowerDemand[i + 1] : data.referencePowerDemand[i],
                        operationProfile: operatingHours[i],
                        applicationPowerDemand: data.applicationPowerDemand ? parseFloat(data.applicationPowerDemand[i].toFixed(2)) : 0,
                        range: [i > 4 ? data.referencePowerDemand[i + 1] : data.referencePowerDemand[i], i > 4 ? data.alternativePowerDemand[i + 1] : data.alternativePowerDemand[i]]
                    };
                    dataPoints.push(dataPoint);

                    return dataPoints;
                }, []) ?? [];
            }
        }

        return chartData ?? [];
    };

    private static getOperatingHours = (allocationProfile?: AllocationProfile, operatingHourFactor?: number) => {
        const allocationProfileValues = allocationProfile
            ? [
                allocationProfile.p10,
                allocationProfile.p20,
                allocationProfile.p30,
                allocationProfile.p40,
                allocationProfile.p50,
                allocationProfile.p60,
                allocationProfile.p70,
                allocationProfile.p80,
                allocationProfile.p90,
                allocationProfile.p100
            ]
            : [];

        return allocationProfileValues.map(x => this.calculateOperatingHour(x, operatingHourFactor));
    };

    private static calculateOperatingHour = (allocationData?: number, operatingHourFactor?: number) => (allocationData ?? 0) * (operatingHourFactor ?? 0);

    private static getLinearIntersection = (firstLine: LineSegment, secondLine: LineSegment): (IntersectionPoint | undefined) => {
        /* This function calculates the intersection of two line segments described by 4 points.
           The reference for the math can be found: https://en.wikipedia.org/wiki/Line%E2%80%93line_intersection#Given_two_points_on_each_line */

        // Check if none of the lines are of length 0
        if ((firstLine.startPoint.x === firstLine.endPoint.x && firstLine.startPoint.y === firstLine.endPoint.y) || (secondLine.startPoint.x === secondLine.endPoint.x && secondLine.startPoint.y === secondLine.endPoint.y)) {
            return undefined;
        }

        // determinant of the point matrix
        const determinant = (firstLine.startPoint.x - firstLine.endPoint.x) * (secondLine.startPoint.y - secondLine.endPoint.y)
            - (firstLine.startPoint.y - firstLine.endPoint.y) * (secondLine.startPoint.x - secondLine.endPoint.x);

        // when the determinant is 0 it means that the lines are parallel
        if (determinant === 0) {
            return undefined;
        }

        const t = ((firstLine.startPoint.x - secondLine.startPoint.x) * (secondLine.startPoint.y - secondLine.endPoint.y)
            - (firstLine.startPoint.y - secondLine.startPoint.y) * (secondLine.startPoint.x - secondLine.endPoint.x)) / determinant;
        const u = ((firstLine.startPoint.x - secondLine.startPoint.x) * (firstLine.startPoint.y - firstLine.endPoint.y)
            - (firstLine.startPoint.y - secondLine.startPoint.y) * (firstLine.startPoint.x - firstLine.endPoint.x)) / determinant;

        // this checks if the intersection falls inside the line segment
        if (t < 0 || t > 1 || u < 0 || u > 1) {
            return undefined;
        }

        // the x and y coordinates of the intersection
        const x = firstLine.startPoint.x + t * (firstLine.endPoint.x - firstLine.startPoint.x);
        const y = firstLine.startPoint.y + t * (firstLine.endPoint.y - firstLine.startPoint.y);

        const firstLineIsHigher = firstLine.startPoint.y > secondLine.startPoint.y;
        const firstLineIsHigherNext = firstLine.endPoint.y > secondLine.endPoint.y;

        return { x, y, firstLineIsHigher, firstLineIsHigherNext };
    };

    private static createCalculationChartData = (data: PowerlossCalculationData, alternativeShaftPowerMultiplyer: number, referenceShaftPowerMultiplyer: number): Record<string, number | undefined | [number, number | undefined]>[] => {
        const hasDataPoint = (torque: number, speed: number) =>
            data?.alternativePowerLoss?.find(x => x.torque == torque && x?.speed == speed) || data?.referencePowerLoss?.find(x => x.torque == torque && x?.speed == speed);

        return data
            ? POWER_LOSS_SPEEDS.map(speed => {
                const dataPoint: Record<string, number | undefined | [number, number | undefined]> = { name: speed };
                POWER_LOSS_TORQUES.forEach(torque => {
                    dataPoint[`iesReferencePowerLossRange${torque}Torque`] = data.referenceIesPowerLoss && [torque, data.referenceIesPowerLoss ? this.getDataForBar(torque, speed, data.referenceIesPowerLoss, 1) : undefined];
                    dataPoint[`iesReferencePowerLossRange${torque}TorqueDisplay`] = this.getDataForDisplay(torque, speed, 1, data.referenceIesPowerLoss);
                    dataPoint[`referencePowerLossRange${torque}Torque`] = data.referencePowerLoss && [torque, data.referencePowerLoss ? this.getDataForBar(torque, speed, data.referencePowerLoss, referenceShaftPowerMultiplyer) : undefined];
                    dataPoint[`referencePowerLossRange${torque}TorqueDisplay`] = this.getDataForDisplay(torque, speed, referenceShaftPowerMultiplyer, data.referencePowerLoss);
                    dataPoint[`alternativePowerLossRange${torque}Torque`] = data.alternativePowerLoss && [torque, data.alternativePowerLoss ? this.getDataForBar(torque, speed, data.alternativePowerLoss, alternativeShaftPowerMultiplyer) : undefined];
                    dataPoint[`alternativePowerLossRange${torque}TorqueDisplay`] = this.getDataForDisplay(torque, speed, alternativeShaftPowerMultiplyer, data.alternativePowerLoss);
                    dataPoint[`scatterPoint${torque}`] = hasDataPoint(torque, speed) ? torque : undefined;
                });
                return dataPoint;
            })
            : [];
    };

    private static getLifetimeCostsIntersectionPoints = (lineChartData: LifetimeCostsChartDataPoint[]): IntersectionPoint[] => lineChartData
        .flatMap((dataPoint, i) => {
            const intersection = this.getLinearIntersection(
                {
                    startPoint: { x: dataPoint.month, y: dataPoint.referenceCost },
                    endPoint: { x: lineChartData[i + 1]?.month, y: lineChartData[i + 1]?.referenceCost }
                },
                {
                    startPoint: { x: dataPoint.month, y: dataPoint.alternativeCost },
                    endPoint: { x: lineChartData[i + 1]?.month, y: lineChartData[i + 1]?.alternativeCost }
                }
            );
            return this.filteredIntersections(intersection && !isNaN(intersection.x) ? [intersection] : []);
        });

    private static getEnergySavingsIntersectionPoints = (lineChartData: EnergySavingsChartDataPoint[]): IntersectionPoint[] => lineChartData
        .flatMap((dataPoint, i) => {
            const intersection = this.getLinearIntersection(
                {
                    startPoint: { x: dataPoint.flowrate, y: dataPoint.referencePowerDemand },
                    endPoint: { x: lineChartData[i + 1]?.flowrate, y: lineChartData[i + 1]?.referencePowerDemand }
                },
                {
                    startPoint: { x: dataPoint.flowrate, y: dataPoint.alternativePowerDemand },
                    endPoint: { x: lineChartData[i + 1]?.flowrate, y: lineChartData[i + 1]?.alternativePowerDemand }
                }
            );

            return this.filteredIntersections(intersection && !isNaN(intersection.x) ? [intersection] : []);
        });

    private static filteredIntersections = (intersections: IntersectionPoint[]): IntersectionPoint[] => intersections.filter(
        (intersection, i) => i === intersections.length - 1 || intersection?.x !== intersections[i - 1]?.x
    );

    // These are here to be indpendent from ui if moved to backend

    private static rounding = (value: number, digitsToKeep: number, upperLimit: number): number => {
        const upperLimitPower = Math.log10(upperLimit);
        const loopLimit = upperLimitPower - digitsToKeep;
        const decimalShift = digitsToKeep - 1;

        if (value >= upperLimit) {
            return Math.round(value / Math.pow(10, loopLimit)) * Math.pow(10, loopLimit);
        }

        for (let i = 0; i <= loopLimit; i++) {
            if (value < Math.pow(10, i + decimalShift)) {
                const roundingHelperValue = Math.pow(10, i);

                return Math.round(value / roundingHelperValue) * roundingHelperValue;
            }
        }

        return value;
    };

    private static hundredRounding = (value: number): number => {
        return this.rounding(value, 4, 1000000000);
    };

    private static thousandRounding = (value: number): number => {
        return this.rounding(value, 3, 100000);
    };
}
