import { CommercialViewState } from '../models/CommercialViewState';
import { DEFAULT_ADDITIONAL_INVESTMENT } from './AdditionalInvestment';

export const COMMERCIAL_VIEW_INITIAL_STATE: CommercialViewState = {
    alternativeSystemCommercialData: {
        additionalInvestments: {
            additionalInvestmentItems: [DEFAULT_ADDITIONAL_INVESTMENT],
            enableEdit: false,
            isModalOpen: false
        },
        controlComponentCustomerPrice: '0',
        controlComponentDiscount: '0',
        controlComponentExpeditureForRepairs: '0',
        governmentFunding: {
            enableEdit: false,
            grantRate: '',
            grantSum: '',
            isModalOpen: false
        },
        motorCustomerPrice: '0',
        motorDiscount: '0',
        motorExpeditureForRepairs: '0'
    },
    referenceSystemCommercialData: {
        additionalInvestments: {
            additionalInvestmentItems: [DEFAULT_ADDITIONAL_INVESTMENT],
            enableEdit: false,
            isModalOpen: false
        },
        controlComponentCustomerPrice: '0',
        controlComponentDiscount: '0',
        controlComponentExpeditureForRepairs: '0',
        governmentFunding: {
            enableEdit: false,
            grantRate: '',
            grantSum: '',
            isModalOpen: false
        },
        motorCustomerPrice: '0',
        motorDiscount: '0',
        motorExpeditureForRepairs: '0'
    }
};
